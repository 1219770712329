import {
   SET_SNACKBAR
} from './index';


export const setSnackbar = (payload) => {
   const { snackbarOpen, snackbarType, snackbarMessage } = payload;
   return {
       type: SET_SNACKBAR,
       snackbarOpen, 
       snackbarType, 
       snackbarMessage
   }
}