import React from "react";
import {connect} from "react-redux";
import {closePopup} from "../../../actions/popup";
import closeIcon from "../../../img/ico/close.svg";
import pawIcon from "../../../img/ico/catsPaw.svg";
import "./shelterDetails.scss";
import instagramIcon from "../../../img/ico/instagramPink.svg";
import facebookIcon from "../../../img/ico/facebookPink.svg";
import {googleMapsApiKey} from '../../../service';
import MapWithMarker from '../../mapWithMarker/mapWithMarker';
import { baseURL } from '../../../service';

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => {
    return dispatch(closePopup());
  },
});

const ShelterDetails = (props) => {
  const {closePopup, info} = props;
  const shelter = info.shelter;

  return (
    <section>
      <div className="popup__closeBtn">
        <img src={closeIcon} onClick={props.close} alt="" />
      </div>
      <div className="shelterDetails">
        {shelter.image &&
          <div className="shelterDetails__imgWrapper">
            <img
              src={`${baseURL}uploads/users/${shelter.image}`}
              alt="shelter photo"
            />
          </div>
        }
        <div className="shelterDetails__nameWrapper">
          <div className="shelterDetails__name">{shelter.organizationName}
          <span className="secondaryText"><a href={shelter.social?.wb}>{shelter.social?.wb}</a></span></div>
          <div className="shelterDetails__socials">
            <div className="shelterDetails__social">
            <a href={`tel:${shelter.phone}`}>{shelter.phone}</a>
            </div>
            {shelter.social?.in &&
              <div className="shelterDetails__social">
                <a href="">
                  <img src={instagramIcon} alt="shelters instagram" />
                </a>
              </div>
            }
            {shelter.social?.fb &&
              <div className="shelterDetails__social">
                <a href="">
                  <img src={facebookIcon} alt="shelters facebook" />
                </a>
              </div>
            }
          </div>
        </div>

        <div className="shelterDetails__info">
          <div className="shelterDetails__label">About Us</div>
          <div>{shelter.description}</div>
        </div>

        <div className="shelterDetails__locationWrapper">
          <img src={pawIcon} alt="dig paw" />
          <div className="shelterDetails__location">
            <div className="shelterDetails__label">Location</div>
            <div className="">{shelter.city}, {shelter.address}</div>
          </div>
        </div>

        <div className="shelterDetails__mapWrapper">
          <MapWithMarker
            googleMapURL={'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=' + googleMapsApiKey}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{height: `300px`}} />}
            mapElement={<div style={{height: `100%`}} />}
            coords={{lat: shelter.location.coordinates[0], lng: shelter.location.coordinates[1]}}
          />
        </div>
      </div>
    </section>
  );
};


export default connect(null, mapDispatchToProps)(ShelterDetails);
