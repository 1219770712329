import { Suspense, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch, useHistory, } from "react-router-dom";
import routes from "./Routes";
import NotFoundPage from "./components/notFoundPage/notFoundPage";

const mapStateToProps = (state) => ({
  ...state.pages,
});

const ProtectedRoutes = (props) => {

  const { user, isNotFound } = props;
  const history = useHistory();
  const [allRoutes, setAllRoutes] = useState(routes);

  useEffect(() => {
    if (!user.organization) {
      const filteredRoutes = allRoutes.filter((x) => !x.onlyOrganization);
      setAllRoutes(filteredRoutes);
    }
  }, []);

  useEffect(() => {
    console.log('isNotFound', isNotFound);
    if (isNotFound) {
      history.push("/404");
    }
  }, [isNotFound]);

  return (
    <Suspense
      fallback={
        <div className="animatedLoaderLogoWrapper">
          <img className="animatedLoaderLogo" src="/images/logo.svg" />
        </div>
      }
    >
      <Switch>
        {allRoutes.map(({ component: Component, path, exact }) => {
          return (
            <Route path={`/${path}`} key={path} exact={exact}>
              <Component />
            </Route>
          );
        })}

        <Route path={`/404`} component={NotFoundPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
};

export default connect(mapStateToProps, null)(ProtectedRoutes);
