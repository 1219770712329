import React from 'react';
import './statusBadge.scss';

const StatusBadge = ({status}) => {

    const getBadgeClass = () => {
        if(status === 'in progress') {
            return 'statusBadge--progress'
        }

        if(status === 'booked') {
            return 'statusBadge--booked'
        }

        if(status === 'past') {
            return 'statusBadge--past'
        }

        if(status === 'canceled') {
            return 'statusBadge--canceled'
        }
    }

  return (
    <div className={getBadgeClass()}>{status}</div>
  )
}

export default StatusBadge