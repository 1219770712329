import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Timeline from '../timeline/timeline';

// styles 
import './timelineWrapper.scss';

//icons
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { deleteDate } from '../../actions/selectedDates';

const mapDispatchToProps = dispatch => ({
   deleteDate: (payload) => {
      return dispatch(deleteDate(payload));
   }
})

const TimelineWrapper = (props) => {

   const [isAvailable, setIsAvailable] = React.useState(props.isAvailable);
   const [isActive, setIsActive] = React.useState(props.isActive);

   useEffect(() => {

      setIsActive(props.isActive);
      setIsAvailable(props.isAvailable);

   }, [props.isAvailable, props.isActive])


   const setActive = () => {
      return (
         setIsActive(true)
      )
   }

   const deleteDate = () => {
      setIsActive(false);
      props.deleteDate({date: props.selectedDate});
   }


   return (
      <div className="timelineWrapper">
         {isAvailable && isActive ? <button className="timelineOverlayBtn" onClick={deleteDate}><span>Delete</span> <DeleteForeverIcon className="timelineOverlayIcon"/> </button> : null}
         { isAvailable && !isActive ? <div className="timelineOverlay" onClick={setActive}><div className="timelineOverlayText">click to select</div></div>  : null }
         {!isAvailable && <div className="timelineOverlayError"><div className="timelineOverlayTextError">This day is unavailable</div></div>}
         <Timeline 
            selectedDate={props.selectedDate} 
            isAvailable = {props.isAvailable}
            disabledHours = {props.disabledHours}
            rate={props.rate}/>
      </div>
   )
}

export default connect(
   null,
   mapDispatchToProps
)(TimelineWrapper);