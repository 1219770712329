import { MAP } from './index';

const {
    WALK_STARTED,
    WALK_FINISHED,
    NEW_COORDINATES,
    LOADED_COORDINATES
} = MAP;

export const walkStarted = (data) => {

    return {
        type: WALK_STARTED,
        ...data
    }
};

export const walkFinished = (data) => {
    
    return {
        type: WALK_FINISHED,
        ...data
    }
};

export const setNewCoordinates = (data) => {
    const {
        currentWalks
    } = data;

    return {
        type: NEW_COORDINATES,
        currentWalks
    }
};

export const setLoadedCoordinates = (data) => {
    const {
        currentWalks
    } = data;

    return {
        type: LOADED_COORDINATES,
        currentWalks
    }
};