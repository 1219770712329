import React, { useEffect } from 'react';
import moment from 'moment';

import './editWalk.scss';

import Timeline from '../../timeline/timeline';

import { getUserById } from '../../../service/index';

// icons
import closeIcon from '../../../img/ico/close.svg';
import calendarPink from '../../../img/ico/calendar_pink.svg';

// customize pickers
import red from '@mui/material/colors/red';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// for date picker
import 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const EditWalk = (props) => {

   const walk = props.info;
   const [selectedDate, setSelectedDate] = React.useState(new Date(props.info.startTime));

   const formattedDate = moment(selectedDate).format('DD-MM-YYYY');
   const [walker, setWalker] = React.useState()
   const handleDateChange = (date) => setSelectedDate(date);

   useEffect(  () => {

      const getWalker = async () => {
         try {
            const res = await getUserById(props.info.walkerId);
            setWalker(res.data.walker)
         }

         catch (error) {
            console.log(error)
         }
      }
      getWalker()

   }, [])

  

   const isSpecialHours = () => {
      if(walker.workHours){
         const specialWorkHours = walker.workHours.map(x => (moment(x.date).format('DD-MM-YYYY')))
         const isSpecialHours = specialWorkHours.find(x => x === formattedDate);
         return isSpecialHours;
      } else {
         return false
      }
   }


   const getWorkHours = () => {

      if (isSpecialHours()) {
         return walker.workHours.filter(x => moment(x.date).format('DD-MM-YYYY') === formattedDate)[0].time;
      } else {
         return walker.usualWorkhours.time;
      }
   }

   const getBreaks = () => {
      
      if (isSpecialHours()) {
         return walker.workHours.filter(x => moment(x.date).format('DD-MM-YYYY') === formattedDate)[0].breakTime;
      } else {
         return walker.usualWorkhours.breakTime;
      }
   }
   
   const getBookedWorkHours = () => {
      const arr = [];
      const bookedWorkHours = walker.bookedWorkHours.map(x => (moment(x.date).format('DD-MM-YYYY')))
      const isBookedWorkHours = bookedWorkHours.find(x => x === formattedDate);

      if (isBookedWorkHours) {
         return walker.bookedWorkHours.filter(x => moment(x.date).format('DD-MM-YYYY') === formattedDate)[0].time;
      } else {
         return arr;
      }
   }

   return (
      <section className="editWalk__wrapper">
      {walker &&
         <div className="editWalk">
            <div className="editWalk__closeBtn">
               <img src={closeIcon} onClick={props.close} alt="" />
            </div>
            <div className="editWalk__title">Edit Walk</div>

            <div className="editWalk__walkInfo">
               <div className="lastWalk__walkerImg">
                  <img src='https://images.pexels.com/photos/3680316/pexels-photo-3680316.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260' alt="last walker image" />
               </div>
               <div className="editWalk__desc">
                  <div className="lastWalk__walkerName">{walker.name}</div>
                  <div className="lastWalk__info">
                     <div className="lastWalk__infoBox">
                        <div className="lastWalk__label">time</div>
                        <div className="lastWalk__time">{moment.utc(walk.startTime).format("HH:mm")} - {moment.utc(walk.finishTime).format("HH:mm")}</div>
                     </div>
                  </div>
               </div>
            </div>

               <div className="editWalk__datePickerWrapper">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                     <ThemeProvider theme={defaultMaterialTheme}>
                        <DatePicker
                           className="profile__datePicker"
                           inputFormat="dd.MM.yyyy"
                           disablePast={true}
                           format="dd/MM/yyyy"
                           value={selectedDate}
                           maxDate={moment().add(1, 'months').calendar()}
                           slots={{
                              openPickerIcon: () => <img src={calendarPink} className="profile__datePickerIcon" />,
                           }}
                           onChange={handleDateChange}
                        />
                     </ThemeProvider>
                  </LocalizationProvider>
               </div>

            <div className="editWalk__timeline">
               <Timeline 
                  selectedDate={selectedDate} 
                  workHours={getWorkHours()} 
                  breaks={getBreaks()} 
                  bookedWorkHours={getBookedWorkHours()} 
                  rate="20"
                  />
            </div>

               <div className="editWalk__buttons">
                  <div className="editWalk__cancelBtn">Cancel</div>
                  <div className="editWalk__saveBtn">Save</div>
               </div>

            </div>
         }
      </section>
   )
}

export default EditWalk;


// for picker
const defaultMaterialTheme = createTheme({
   palette: {
      primary: {
         main: red.A100,
      }
   }
});