import React, { useEffect } from "react";
import {connect} from "react-redux";
import { useHistory } from 'react-router-dom';
import notFoundIllustration from "../../img/about_1.svg";
import { PlainButton } from "../buttons/buttons";
import './notFound.scss';

const NoFoundPage = (props) => {
  const history = useHistory();
  
  return (
    <div className="notFound">
      <div className="notFound__title">Sorry, page don’t found :(</div>
      <div className="notFound__text">
        Error code: <span className="">404</span>
      </div>
      <img
        className="notFound__img"
        src={notFoundIllustration}
        alt="page not found illustration"
      />
      <div className="notFound__btn">
        <PlainButton
          text="Back to Home"
          onClick={() => history.push("/")}
        />
      </div>
    </div>
  );
};

export default connect(null, null)(NoFoundPage);
