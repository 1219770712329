import {
   CURRENT_USER
} from '../actions';

export default ( state = {
   user: {},
   accessToken: "",
}, action) => {

   const {
      user,
      accessToken
   } = action;

   switch (action.type) {
      case CURRENT_USER:
         {
            return {
               ...state,
               user: user,
               accessToken: accessToken
            }
      }
      
      default:
         return state;
      }
}