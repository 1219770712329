import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import './buttons.scss';

export const PlainButton = (props) => {
   return (
      <ButtonBase onClick={props.onClick} className={props.disabled ?"btn btn--disabled"  :"btn plainBtn"}>{props.text}</ButtonBase>
   )
}


export const OutlinedButton = (props) => {
    return (
       <ButtonBase onClick={props.onClick} className={props.full? "btn outlinedBtn full" : "btn outlinedBtn"}>{props.text}</ButtonBase>
    )
 }

 export const TransparentBlueBtn = (props) => {
   return (
      <ButtonBase onClick={props.onClick} className={props.full? "btn transparentBlueBtn full" : "btn transparentBlueBtn"}>{props.text}</ButtonBase>
   )
}


 export const LightButton = (props) => {
   return (
      <ButtonBase onClick={props.onClick} className="btn lightButton">{props.text}</ButtonBase>
   )
}
