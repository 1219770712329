import React, { useState } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';

const AddressField = (props) => {
  const { value, addressError, setAddressError, onAddressSet } = props;

  const onAddressSelect = async (value, location, suggestions) => {

    if (suggestions == null) {
      return;
    }
    const results = await geocodeByAddress(value);

    if (value.match(/[0-9]/i) == null) {
      setAddressError('please select your address from suggestions"')

    } else {
      const latLng = await getLatLng(results[0]);

      let country = results[0].address_components.filter(x => x.types.find(y => y === 'country'))[0].long_name;
      let city = results[0].address_components.filter(x => x.types.find(y => y === 'locality'))[0].long_name;
      let lat = latLng.lat;
      let lng = latLng.lng;

      onAddressSet(true, { address: value, city: city, country: country, lat: lat, lng: lng })
      setAddressError("")

    }
  }

  return (
    <div className="auth__formField auth__formFieldAutocomplete">

      <PlacesAutocomplete
        value={value}
        onChange={(value) => onAddressSet(false, value)}
        onSelect={onAddressSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Shelter address',
                name: 'address',
                className: addressError ? 'auth__formInput auth__autocompleteInput auth__formInput--error ' : 'auth__autocompleteInput auth__formInput',
              })}
            />
            <div className={suggestions.length > 0 ? "autocomplete-suggestions--bordered" : "autocomplete-suggestions"}>
              {loading && <div style={{ fontSize: '14px', padding: "4px 20px", color: '#ff9085' }}>... Loading</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';

                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>

      {addressError && <div className='auth__error'>{addressError}</div>}
    </div>
  )
}

export default AddressField