import React from 'react';

// styles 
import './walks.scss';

// icons 
import closeIcon from '../../../img/ico/close.svg';
import walker from '../../../img/walker_img.jpg';
import archivalWalker from '../../../img/last_walker.png'

const Walks = (props) => {

   return (
      <section className="walks__wrapper">
         <div className="walks">
            <div className="closeBtn">
               <img src={closeIcon} alt="" onClick={props.close} />
            </div>
            <div className="walks__title">Last Walks</div>
            <section>
               <div className="walks__group">
                  <div className="walks__walkerImg">
                     <img src={archivalWalker} alt="last walker image" />
                  </div>
                  <div className="walks__desc">
                     <div className="walks__walkerName">Molly Molly</div>
                     <div className="walks__info">
                        <div className="walks__infoBox">
                           <div className="walks__label">date</div>
                           <div className="walks__date">12.05</div>
                        </div>
                        <div className="walks__infoBox">
                           <div className="walks__label">time</div>
                           <div className="walks__time">18:00-20:00</div>
                        </div>
                     </div>
                  </div>
                  <div className="walks__edit">Reorder</div>
               </div>

               <div className="walks__group">
                  <div className="walks__walkerImg">
                     <img src={archivalWalker} alt="last walker image" />
                  </div>
                  <div className="walks__desc">
                     <div className="walks__walkerName">Elly Walker</div>
                     <div className="walks__info">
                        <div className="walks__infoBox">
                           <div className="walks__label">date</div>
                           <div className="walks__date">12.05</div>
                        </div>
                        <div className="walks__infoBox">
                           <div className="walks__label">time</div>
                           <div className="walks__time">18:00-20:00</div>
                        </div>
                     </div>
                  </div>
                  <div className="walks__edit">Reorder</div>
               </div>
            </section>

         </div>
      </section>
   )
}

export default Walks