import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { closePopup } from "../../../actions/popup";
import removeDogIcon from "../../../img/ico/removeDogIcon.svg";
import "./confirmation.scss";
import { PlainButton, OutlinedButton } from "../../buttons/buttons";

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => {
    return dispatch(closePopup());
  },
});

const Confirmation = (props) => {
  const { closePopup, info } = props;

  const onConfirm = () => {
    closePopup();
    info.onConfirm();
  };

  return (
    <section
      className="my-10 mx-20 relative "
      style={{ margin: 30, display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <>
        {info.isDogDelete && <img src={removeDogIcon} className="m-auto" />}
        <p className="confirmation__title">{info.type === 'remove' ? `Are you sure you want to ${info.text}` : info.text}</p>
        <div className="confirmation__buttons">
          <OutlinedButton onClick={() => closePopup()} text="Cancel" />
          <PlainButton onClick={() => onConfirm()} text={info.type === 'remove' ? "Yes, delete"  : info.confirmationButtonText}/>
        </div>
      </>
    </section>
  );
};

export default connect(null, mapDispatchToProps)(Confirmation);
