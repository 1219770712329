import React, { useEffect } from 'react';

import { useElements, useStripe } from "@stripe/react-stripe-js";
import { CardNumberElement } from "@stripe/react-stripe-js";
import { CardExpiryElement } from "@stripe/react-stripe-js";
import { CardCvcElement } from "@stripe/react-stripe-js";


// styles 
import './addCard.scss';

//icons 
import closeIcon from '../../../img/ico/close.svg';

const AddCard = (props) => {

   
   const stripe = useStripe();
   const elements = useElements();

   const [name, setName] = React.useState("");

   const cardNumberElementOption = {
      style: {
         base: {
            fontSize: "16px",
            color: "#fff",
            fontWeight: 400,
            iconColor: " rgb(248, 237, 237)",
         },
         invalid: {
            color: "#e63946",
            iconColor: "#e63946"
         },
         focus: {
            color: "green",
         }
      },
      placeholder: "",
      showIcon: true,
      iconStyle: "solid"
   };

   const cardExpiryElement = {
      style: {
         base: {
            fontSize: "16px",
            color: "#fff",
            fontWeight: 400,
         },
         invalid: {
            color: "#e63946",
         }
      },
      placeholder: "",
   };

   const cardCvcElement = {
      style: {
         base: {
            fontSize: "16px",
            color: "#fff",
            fontWeight: 400,
         },
         invalid: {
            color: "#e63946"
         }
      },
      placeholder: "",
   }


   const onDateFocus = (e) => {
      console.log(elements)
      if (e.complete) {
         elements.getElement("cardExpiry").focus();

      }
   };

   const onCvvFocus = (e) => {
      if (e.complete) {
         elements.getElement("cardCvc").focus();
      }
   };

   
  // TIP: Stripe, amount is in the lowest denomination
  // $1 = 100 cents

  //   create a  payment intent on the server
  //   return client secret of payment intent

  // need reference to the cardElement
  // for payment method we need a ref to stripe.js object, which has func to create payment method
  // create a payment method

  // confirm the card payment
  // payment method id
  // client_secret 


   const onSaveCard = async (e) => {
   // We don't want to let default form submission happen here,
   // which would refresh the page.
   //e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

   // create a  payment intent on the server
   // return client secret of payment intent

    const result = await stripe.confirmCardSetup('{{CLIENT_SECRET}}', {
      payment_method: {
        card: elements.getElement("cardNumber"),
        billing_details: {
          name: name,
        },
      }
    });

    if (result.error) {
      // Display result.error.message in your UI.
    } else {
      // The setup has succeeded. Display a success message and send
      // result.setupIntent.payment_method to your server to save the
      // card to a Customer
    }
  };

   return (
      <section className="addCard__wrapper">
         <div className="addCard">
            <div className="addCard__closeBtn">
               <img src={closeIcon} onClick={props.close} alt="" />
            </div>
            <div className="addCard__title">Add new card</div>

            <div className="addCard__card">

               <div className="addCard__cardLabel">Name on card</div>
               <input className="addCard__cardInput" type="text" onChange = {(e) => setName(e.target.value)}/>


               <div className="addCard__cardLabel">Card Number</div>
               <CardNumberElement
                  className="addCard__cardInput"
                  options={cardNumberElementOption}
                  onChange={(e) => onDateFocus(e)}
               />

               <div className="addCard__innerWrapper">
                  <div className="addCard__expiryDateWrapper">
                     <div className="addCard__cardLabel">Expiry date</div>

                     <CardExpiryElement
                        id="date-element"
                        className="addCard__cardInput"
                        options={cardExpiryElement}
                        onChange={(e) => onCvvFocus(e)}
                     />
                  </div>
                  <div className="addCard__cvvWrapper">
                     <div className="addCard__cardLabel">CVV</div>
                     <CardCvcElement
                        className="addCard__cardInput"
                        options={cardCvcElement}
                     />
                  </div>
               </div>

            </div>

            <div className="addCard__saveBtn" onClick={onSaveCard}>Save</div>

         </div>
      </section>
   )
}

export default AddCard