import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { deleteDogReq, getDogs, baseURL } from '../../service/index';
import DogSize from '../dogSize/dogSize';
import Walks from '../walks/walks';
import TimeSettings from '../timeSettings/timeSettings';
import { useWindowWidth } from '@react-hook/window-size'
// styles 
import './dogsPage.scss';

// images 
import walker from '../../img/walker_img.jpg';

// icons 
import addDog from '../../img/ico/add_btn.svg';

import { openPopup } from '../../actions/popup';
import { deleteDog, setDogs } from '../../actions/dogs';
import { setSnackbar } from '../../actions/snackbar';


const mapStateToProps = state => ({
   ...state.currentUser,
   ...state.dogs
});

const mapDispatchToProps = dispatch => ({
   openPopup: (payload) => {
      return dispatch(openPopup(payload));
   },
   deleteDog: (payload) => {
      return dispatch(deleteDog(payload));
   },
   setSnackbar: (payload) => {
      return dispatch(setSnackbar(payload));
   },
   setDogsAction: (payload) => {
      return dispatch(setDogs(payload));
    },
})


const DogsPage = (props) => {
   const [dogs, setDogs] = React.useState(props.dogs);
   const [infoText, setInfoText] = React.useState('');
   const [currentDog, setCurrentDog] = React.useState();
   const [isWalksSettingsOpen, setIsWalksSettingsOpen] = React.useState(true);
   const windowWidth = useWindowWidth();


   const loadDogs = async () => {
      try {
        const res = await getDogs();
        props.setDogsAction({ dogs: res.data.dogs });
         setDogs(res.data.dogs)
         setCurrentDog(res.data.dogs[0])

         if(res.data.dogs.length < 1) {
         setInfoText('Please add your first dog! :)');
         }
      }

      catch (error) {
        console.log(error)
      }
    }

   useEffect(() => {
      loadDogs();
   }, []);


   const onDogAdd = (dog) => {
      setDogs([...dogs, dog]);
   }

   const onDogUpdate = (dog) => {
      let allDogs = dogs;
      let updatedDogs = dogs.find(x => x._id === dog._id);
      let updatedDogIndex = dogs.indexOf(updatedDogs);
      allDogs[updatedDogIndex] = dog;
      setDogs([...allDogs]);
   }


   const deleteDog = async dog => {
      // todo: not allow delete dog if future walk exist with this dog

      try {
         await deleteDogReq(dog._id)
         const updatedDogs = dogs.filter(x => x._id != dog._id);
         setDogs(updatedDogs);
         props.setSnackbar({snackbarOpen: true, snackbarType: "success", snackbarMessage: "Your dog was deleted !"})
      }
      catch (error) {
         console.log(error)
      }
      props.deleteDog({ dog: dog._id })
   }

   const getDogPhoto = (imagePath) => `${baseURL}uploads/dogs/${imagePath}`


   const calculateDogsAge = (dogDateB) => {
      const currentDate = new Date(dogDateB);
      const today = new Date();
      const diffInMonths = (today.getFullYear() - currentDate.getFullYear()) * 12 +
         (today.getMonth() - currentDate.getMonth());
      const years = Math.floor(diffInMonths / 12);
      const remainingMonths = Math.floor(diffInMonths % 12);
      const showRemainingMonths = remainingMonths >= 6;

      let timeInfo;

      if (years > 0 && showRemainingMonths) {
         timeInfo = `${years}.5 ${years === 1 ? 'year' : 'years'}`;
      } else if (years > 0) {
         timeInfo = `${years} ${years === 1 ? 'year' : 'years'}`;
      } else {
         timeInfo = `${remainingMonths} ${remainingMonths === 1 ? 'month' : 'months'}`;
      }
      return timeInfo;
   }

   const showDogInfo = () => {
      if (currentDog != undefined) {
         return (
            <div className="dog">

               <div className="dog__imgWrapper">
                  <img src={getDogPhoto(currentDog.imagePath)} alt="dogs photo" />
               </div>

               <div className="dog__info">

                  <div className="dog__mainInfo">

                     <div className="dog__infoText">age</div>
                     <div className="dog__infoValue">{calculateDogsAge(currentDog.dateB)}</div>

                     <div className="dog__infoLine"></div>

                     <div className="dog__infoText">size</div>
                     <div className="dog__infoValue">{currentDog.size === "s" ? "Small" : currentDog.size === "m" ? "Medium" : currentDog.size === 'l' ? "Large" : null}</div>

                     <div className="dog__infoLine"></div>

                     <div className="dog__infoText">sex</div>
                     <div className="dog__infoValue">{currentDog.sex}</div>

                     <div className="dog__infoLine"></div>

                  </div>

                  <div className="dog__aboutWrapper">
                     <div className="dog__name">{currentDog.name}</div>
                     <div className="dog__commentLabel">Comment</div>
                     <div className="dog__comment">{currentDog.comment}</div>
                  </div>
               </div>
            </div>
         )
      } else {
         return (
            <div>
               <div className="dog__empty">
                  <div className="dog__imgWrapper--empty"></div>
                  <div className="dog__info">
                     <div className="dog__mainInfo">

                        <div className="dog__infoText">age</div>
                        <div className="dog__infoValue">2</div>

                        <div className="dog__infoLine"></div>

                        <div className="dog__infoText">size</div>
                        <div className="dog__infoValue">small</div>

                        <div className="dog__infoLine"></div>

                        <div className="dog__infoText">sex</div>
                        <div className="dog__infoValue">female</div>
                     </div>
                     <div className="dog__aboutWrapper">
                        <div className="dog__name">Simon</div>
                        <div className="dog__commentLabel">Comment</div>
                        <div className="dog__comment">Hello I'm Simon, and I very playful and happy dog!</div>
                     </div>
                  </div>
               </div>
            </div>
         )
      }
   }


   return (
     <section className="dogsSection">
       <div className={dogs.length && dogs.length > 4 ? "dogs__wrapper--expanded" : "dogs__wrapper" }>
         {showDogInfo()}
         {dogs.length && (dogs.length > 4 || windowWidth <= 420) ? (
            <div className='myDogsListWrapper'>
            <div className='myDogsList__mainInfo'>
               <div className='myDogsList__title'>My dogs</div>
               <div
                 className="myDogsList__addBtn"
                 onClick={() => props.openPopup({currentPopup: "AddDog", info: {onDogAdd: onDogAdd}})}
               >
                 <img src={addDog} alt="" />
               </div>
            </div>
           <div className="myDogsList">
             {dogs.map((dog, i) => {
               return (
                  <Button variant="text" className="myDogsList__dogWrapper" onClick={() => setCurrentDog(dog)}>
                     <div className="myDogsList__dog">
                        <div className="myDogsList__dogImgWrapper">
                           <img src={getDogPhoto(dog.imagePath)} alt="dogs photo" />
                        </div>
                        <div className="myDogsList__dogInfoWrapper">
                        <div className="myDogsList__dogInfo">
                           {/* <div className="myDogsList__dogNameWrapper"> */}
                           <div className="myDogsList__dogName">{dog.name}</div>
                           
                           {/* </div> */}
                           <div className="myDogsList__dogSize">
                           <DogSize size={dog.size} />
                           {/* <div className="myDogsList__age">
                              Age: {Math.floor((new Date() - new Date(dog.dateB).getTime()) / 3.15576e10)}
                           </div> */}
                           </div>
                        </div>
                        <div className="myDogsList__buttons">
                           <div
                              className="myDogs__dogEditBtn"
                              onClick={() => props.openPopup({currentPopup: "AddDog", info: {dog: dog, onDogUpdate: onDogUpdate}})}
                           >
                              <EditIcon className="myDogs__dogIcon" />
                           </div>
                           <div
                              className="myDogs__dogRemoveBtn"
                              onClick={() =>
                                 props.openPopup({
                                 currentPopup: "Confirmation",
                                 info: {
                                    onConfirm: () => deleteDog(dog),
                                    title: "",
                                    type: 'remove',
                                    text: `remove the ${dog.name} from the list`,
                                    isDogDelete: true,
                                 },
                                 })
                              }
                           >
                              <HighlightOffRoundedIcon className="myDogs__dogIcon" />
                           </div>
                           </div>
                           </div>
                           
                     </div>
                  </Button>
               );
             })}
           </div>
             </div>
         ) : (
           <div className="myDogs">
             <div className="myDogs__innerWrapper">
               <div className="myDogs__dogWrapper myDogs__dog--1"></div>
               <div className="myDogs__dogWrapper myDogs__dog--2"></div>
               <div className="myDogs__dogWrapper myDogs__dog--3"></div>
               <div className="myDogs__dogWrapper myDogs__dog--4"></div>

               {dogs.map((dog, i) => {
                 return (
                   <div className={`myDogs__dogWrapper myDogs__dog--${i + 1}`}>
                     <Button className="myDogs__dog" variant="contained" onClick={() => setCurrentDog(dog)}>
                       <div className="myDogs__dogImgWrapper">
                         <img src={getDogPhoto(dog.imagePath)} alt="dogs photo" />
                       </div>
                     </Button>
                     <div className="myDogs__informText">{dog.name}</div>
                     <div className="myDogs__dogButtons">
                       <div
                         className="myDogs__dogEditBtn"
                         onClick={() =>
                           props.openPopup({currentPopup: "AddDog", info: {dog: dog, onDogUpdate: onDogUpdate}})
                         }
                       >
                         <EditIcon className="myDogs__dogIcon" />
                       </div>
                       {/* <div className="myDogs__dogRemoveBtn" onClick={() => deleteDog(dog)}><HighlightOffRoundedIcon className="myDogs__dogIcon" /></div> */}
                       <div
                         className="myDogs__dogRemoveBtn"
                         onClick={() =>
                           props.openPopup({
                             currentPopup: "Confirmation",
                             info: {
                               onConfirm: () => deleteDog(dog),
                               title: "",
                               type: 'remove',
                               text: `remove the ${dog.name} from the list`,
                             },
                           })
                         }
                       >
                         <HighlightOffRoundedIcon className="myDogs__dogIcon" />
                       </div>
                     </div>
                   </div>
                 );
               })}

               {/* {dogs.length < 1 ? <div className="myDogs__informAddDogText">Please add your first dog! :)</div> : null} */}

               {infoText && <div className="myDogs__informAddDogText">{infoText}</div>}
               <div
                 className="myDogs__addBtn"
                 onClick={() => props.openPopup({currentPopup: "AddDog", info: {onDogAdd: onDogAdd}})}
               >
                 <img src={addDog} alt="" />
               </div>

               {/* not allow to add more thant 4 dogs */}
               {/* {dogs.length < 4
                     ? <div className="myDogs__addBtn" onClick={() => props.openPopup({ currentPopup: 'AddDog',info: {onDogAdd: onDogAdd}})} >
                        <img src={addDog} alt="" />
                     </div>
                     : <div className="myDogs__informMuchDogs">no more than 4 dogs can be added</div>} */}
             </div>
           </div>
         )}
       </div>

       <div className="dogsSection__menu">
            <div className={isWalksSettingsOpen ? "dogsSection__menuItem--active" : "dogsSection__menuItem"} onClick={() => setIsWalksSettingsOpen(true)}>Time Settings</div>
            <div className={isWalksSettingsOpen ? "dogsSection__menuItem " : "dogsSection__menuItem--active"} onClick={() => setIsWalksSettingsOpen(false)}> Walks</div>
      </div>

      {isWalksSettingsOpen ? <TimeSettings user={props.user} dogs={dogs} onDogUpdate={onDogUpdate} getDogPhoto={getDogPhoto}/> : <Walks /> }

     </section>
   );
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(DogsPage);