import {
   SET_DOGS,
   ADD_DOG,
   UPDATE_DOG,
   DELETE_DOG
} from '../actions';

export default ( state = {
   dogs: [],
}, action) => {

   const {
      dogs,
      dog
   } = action;

   switch (action.type) {
      
      case SET_DOGS: {
         return {
            ...state,
            dogs: dogs
         }
      }

      case ADD_DOG: {
         return {
            ...state,
            dogs: [...state.dogs, dog]
         }

      }
      
      case UPDATE_DOG: {
         let updatedDog = state.dogs.find(x => x._id === dog._id);

         return {
            ...state, 
           dogs: [...state.dogs.filter(x => x !== updatedDog), dog]
         }
      }

      case DELETE_DOG: {

         let deletedDog = state.dogs.find(x => x._id === dog);
         return {
            ...state,
            dogs: [...state.dogs.filter(x => x !== deletedDog)]
         }

      }

      default:
            return state;
   }
}