import React from 'react';
import mapStyles from "../map/mapStyles";
import {withScriptjs, GoogleMap, withGoogleMap, Marker, Polyline, DirectionsRenderer} from "react-google-maps";
import pawIcon from "../../img/ico/catsPaw.svg";

const MapWithMarker = withScriptjs(withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={17}
      defaultOptions={{
        styles: mapStyles,
        scrollwheel: false,
      }}
      defaultCenter={props.coords}
    >
      <Marker
        position={props.coords}
        icon={{url: pawIcon, scaledSize: new window.google.maps.Size(40, 40)}}
      />
    </GoogleMap>
  )));
  export default MapWithMarker