import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {createUser} from '../../../service/index';
import GoogleAppleAuth from "../../googleAppleAuth/googleAppleAuth";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import AddressField from '../../addressField/addressField';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

// styles 
import '../../popups/logIn/signUpLogIn.scss';

// images 
import authImage from '../../../img/auth.svg';
import checkIcon from '../../../img/ico/check.svg';

// icons
import closeIcon from '../../../img/ico/close.svg';
import google from '../../../img/ico/google.svg';
import facebook from '../../../img/ico/facebook.svg';

import {
   openPopup
} from '../../../actions/popup';

import {
   closePopup,
} from '../../../actions/popup';

import {
   currentUser
} from '../../../actions/currentUser';

const mapStateToProps = state => ({
   ...state.currentUser,
   ...state.popups
});

const mapDispatchToProps = dispatch => ({
   closePopup: () => {
      return dispatch(closePopup());
   },
   currentUser: (payload) => {
      return dispatch(currentUser(payload));
   },
   openPopup: (payload) => {
      return dispatch(openPopup(payload));
   }
})

const SignUp = (props) => {

   const [user, setUser] = useState({
      name: '',
      lastName: '',
      email: '',
      password: '',
      website: '',
      description: '',
      organizationName: '',
      address: '',
      organization: false,
      country: '',
      city: '',
      lat: '',
      lng: ''
   });
   const [checkAgreement, setCheckAgreement] = useState(false)
   const [nameError, setNameError] = useState("");
   const [lastNameError, setLastNameError] = useState("");
   const [organizationNameError, setOrganizationNameError] = useState("");
   const [emailError, setEmailError] = useState("");
   const [passwordError, setPasswordError] = useState("");
   const [agreementError, setAgreementError] = useState("");
   const [addressError, setAddressError] = useState("");
   const [existUserMessage, setExistUserMessage] = useState("");
   const [isGoogleAuthLoading, setIsGoogleAuthLoading] = useState(false);
   const [activeStep, setActiveStep] = React.useState(0);
   const [showPassword, setShowPassword] = useState(false);
   const [isNotification, setIsNotification] = useState(false);

   const handleChange = (e) => {
      setUser({
         ...user,
         [e.target.name]: e.target.value
      })

      if(e.target.name === 'name' && nameError) {
         setNameError("");
      }
      if(e.target.name === 'lastName' && lastNameError) {
         setLastNameError("");
      }
      if(e.target.name === 'organizationName' && organizationNameError) {
        setOrganizationNameError("");
      }
      if(e.target.name === 'email' && emailError) {
         setEmailError("");
      }
      if(e.target.name === 'password' && passwordError) {
         setPasswordError("");
      }
   }

   // validation
   const validate = () => {
      let nameError = "";
      let organizationNameError = "";
      let lastNameError = "";
      let emailError = "";
      let passwordError = "";
      let agreementError = "";
      let addressError = "";

      if(user.organization) {

        if (!user.organizationName) {
          organizationNameError = "organization name can not be blank";
        }
        if (!user.email) {
          emailError = "invalid email";
       }
 
       if (user.email !== null) {
          let lastAtPos = user["email"].lastIndexOf('@');
          let lastDotPos = user["email"].lastIndexOf('.');
 
 
          if (!(lastAtPos < lastDotPos && lastAtPos > 0 && user["email"].indexOf('@@') == -1 && lastDotPos > 2 && (user["email"].length - lastDotPos) > 2)) {
             emailError = "invalid email";
          }
       }
 
       if (user.password.length < 5) {
          passwordError = "password is too short";
       }
 
       if(!checkAgreement) {
          agreementError = "please read and agree with Items & Conditions";
       }

       if(!user.city || !user.country || !user.lat || !user.lng) {
        addressError = "please select your address from suggestions"
       }

      } else {
        if (!user.name) {
          nameError = "name can not be blank";
        }
        if (!user.lastName) {
          lastNameError = "last name can not be blank";
        }
        if (!user.email) {
          emailError = "invalid email";
       }
 
       if (user.email !== null) {
          let lastAtPos = user["email"].lastIndexOf('@');
          let lastDotPos = user["email"].lastIndexOf('.');
 
 
          if (!(lastAtPos < lastDotPos && lastAtPos > 0 && user["email"].indexOf('@@') == -1 && lastDotPos > 2 && (user["email"].length - lastDotPos) > 2)) {
             emailError = "invalid email";
          }
       }
 
       if (user.password.length < 5) {
          passwordError = "password is too short";
       }
 
       if(!checkAgreement) {
          agreementError = "please read and agree with Items & Conditions";
       }
      }

      if (emailError || organizationNameError || passwordError) {
        setActiveStep(0)
      }


      if (emailError || nameError || lastNameError || passwordError ||  agreementError || addressError || organizationNameError) {
         setNameError(nameError);
         setLastNameError(lastNameError);
         setEmailError(emailError);
         setPasswordError(passwordError);
         setAgreementError(agreementError);
         setOrganizationNameError(organizationNameError);
         setAddressError(addressError);
         return false;
      }

      return true;
   }

   const signUp = async (e) => {
      e.preventDefault();
      const isValid = validate();

        const userData = {
          name: user.name,
          lastName: user.lastName,
          email: user.email,
          password: user.password,
          organization: user.organization
        }

        const organizationData = {
          organizationName: user.organizationName,
          email: user.email,
          password: user.password,
          website: user.website,
          description: user.description,
          organization: user.organization,
          address: user.address,
          country: user.country,
          city: user.city,
          lat: user.lat,
          lng: user.lng,
        }

      let res = {};

      if (isValid) {

         setNameError("");
         setLastNameError("");
         setEmailError("");
         setPasswordError("");
         setAgreementError("");

         try { 
            if(user.organization) {
              res = await createUser(organizationData);
            } else {
              res = await createUser(userData);
            }

            props.currentUser({user: res.data.user});
            localStorage.setItem("accessToken", res.data.token);
            
            props.closePopup();
         }
         catch(e) {
          console.log('e', e)

          if(e.response?.data.code === 409) {
            setIsNotification(e.response?.data.message)
            setTimeout(() => {
              setIsNotification("")
              setActiveStep(0)              
            }, 2000);
          }
         }
      }
   }

   // STEPPER
   const steps = [
      {
        label: 'General info',
       },
      {
        label: 'Information about shelter',
      },
    ];

  const handleNext = () => {
   setActiveStep((prevActiveStep) => prevActiveStep + 1);
 };

 const handleBack = () => {
   setActiveStep((prevActiveStep) => prevActiveStep - 1);
 };

  const onAddressSet = (isFullAddres, addressAutocomplete) => {

    if(isFullAddres) {
      const {address, city, country, lat, lng} = addressAutocomplete;
      setUser({...user, address, city, country, lat, lng })

    } else {
      setUser({...user, address: addressAutocomplete, city: '', country: '', lat: '', lng: ''})
    }

    if(addressError) {
            setAddressError("");
    }
  }
   return (
     <div className="auth__wrapper">
       <div className="auth">
         <div className="auth__formWrapper">
           <div className="auth__formClose">
             <img src={closeIcon} onClick={props.close} alt="" />
           </div>
           <div className="auth__formImg">
             <img src={authImage} alt="" />
           </div>
           <form action="" className="auth__form">
             <div className="auth__title">
               Create your account <div className="auth__error existUserMessage">{existUserMessage}</div>
             </div>

            <div className="search__switcher">
                     <input
                       type="radio"
                       name="authRoleSwitcher"
                       id="walker"
                       className="search__switcherInput search__switcherInput--dogs"
                       checked={!user.organization}
                       onChange={() => setUser({...user, organization: false })}
                     />
                     <label for="walker" className="search__switcherLabel">
                      Walker
                     </label>

                     <input
                       type="radio"
                       name="authRoleSwitcher"
                       id="shelter"
                       className="search__switcherInput search__switcherInput--shelters"
                       checked={user.organization}
                       onChange={() => setUser({...user, organization: true })}
                     />
                     <label for="shelter" className="search__switcherLabel">
                      Shelter
                     </label>

                     <span className="search__switcherToggle"></span>
                   </div>

                  <div style={{position: 'relative'}}>

               {isNotification && <div className="auth__notificationWrapper">
                 <div className="auth__notification">{isNotification}.<br /> Please check your email</div></div>}

               {user.organization && (
                 <Stepper activeStep={activeStep} style={{ marginTop: '10px' }}>
                   {steps.map((step, index) => (
                     <Step key={step.label}>
                       <StepLabel>{step.label}</StepLabel>
                       <StepContent></StepContent>
                     </Step>
                   ))}
                 </Stepper>
               )}

               <div
                 className={
                   !user.organization || activeStep === 0 ? "auth__generalInfoWrapper--active" : "auth__generalInfoWrapper"
                 }
               >


              {!user.organization &&
              <>
                <div className="auth__formField">
                  <input
                    className={nameError ? "auth__formInput auth__formInput--error" : "auth__formInput"}
                    type="text"
                    id="name"
                    placeholder="Name"
                    name="name"
                    value={user["name"]}
                    onChange={(e) => handleChange(e)}
                  />
                  <label className="auth__formLabel" for="name">
                    Name
                  </label>
                </div>

                <div className="auth__formField">
                  <input
                    className={lastNameError ? "auth__formInput auth__formInput--error" : "auth__formInput"}
                    type="text"
                    id="lastName"
                    placeholder="Last Name"
                    name="lastName"
                    value={user["lastName"]}
                    onChange={(e) => handleChange(e)}
                  />
                  <label className="auth__formLabel" for="lastName">
                    Last name
                  </label>
                </div>
              </>
              }

              {user.organization && 
                <div className="auth__formField">
                  <input
                    className={organizationNameError ? "auth__formInput auth__formInput--error" : "auth__formInput"}
                    type="text"
                    id="organizationName"
                    placeholder="Organization Name"
                    name="organizationName"
                    value={user["organizationName"]}
                    onChange={(e) => handleChange(e)}
                  />
                  <label className="auth__formLabel" for="organizationName">
                    Organization Name
                  </label>
                </div>
              }

               <div className="auth__formField">
                 <input
                   className={emailError ? "auth__formInput auth__formInput--error" : "auth__formInput"}
                   type="email"
                   id="email"
                   placeholder="E-mail"
                   name="email"
                   value={user["email"]}
                   onChange={(e) => handleChange(e)}
                 />
                 <label className="auth__formLabel" for="email">
                  E-mail
                 </label>
               </div>

               <div className="auth__formField">
                 <input
                   className={passwordError ? "auth__formInput auth__formInput--error" : "auth__formInput"}
                   type={showPassword ? "text" : "password"}
                   id="password"
                   placeholder="Password"
                   name="password"
                   value={user["password"]}
                   onChange={(e) => handleChange(e)}
                 />
                 <label className="auth__formLabel" for="password">
                   Password
                 </label>
                 <IconButton
                   aria-label="toggle password visibility"
                   onClick={() => setShowPassword(!showPassword)}
                   style={{width: 'min-content', position: 'absolute', right: '10px', top: '15px'}}
                 >
                   {showPassword ? <VisibilityOffIcon className="text-numberGray" /> : <VisibilityIcon className="text-numberGray" />}
                 </IconButton>
               </div>

             </div>

             <div
               className={
                 user.organization && activeStep === 1 ? "auth__shelterInfoWrapper--active" : "auth__shelterInfoWrapper"
               }
             >
               <p className="auth__tip">
                 Please add a website and information about your organization / shelter, this will help other people to
                 know more about you
               </p>
               <textarea
                 rows="4"
                 className="auth__formTextarea auth__formInput"
                 id="description"
                 name="description"
                 value={user.description}
                 placeholder="About your organization"
                 onChange={(e) => setUser({...user, description: e.target.value})}
               />

               <div className="auth__formField">
                 <input
                   className="auth__formInput"
                   type="website"
                   id="website"
                   placeholder="Website"
                   name="website"
                   value={user["website"]}
                   onChange={(e) => handleChange(e)}
                 />
                 <label className="auth__formLabel" for="password">
                   Website
                 </label>
               </div>

               <AddressField value={user.address} onAddressSet={onAddressSet} setAddressError={setAddressError} addressError={addressError}/>
              
             </div>

             <div className="auth__formAgreement">
               <input
                 className="auth__checkbox"
                 type="checkbox"
                 id="agreement"
                 name="agreement"
                 onChange={() => {
                   setCheckAgreement(!checkAgreement);
                   setAgreementError("");
                 }}
               ></input>
               <div className="auth__checkboxCustom"></div>
               <label for="agreement" className="auth__formAgreementLabel">
                 By Signing Up i agree with <a href="#">Items & Conditions</a>
               </label>
             </div>
             <div className="auth__error">{agreementError}</div>

             <div className="auth__buttonsWrapper">
               <div className="auth__buttons">
                 {user.organization && (
                   <div className="auth__btn auth__btn--plain" onClick={activeStep === 0 ? handleNext : handleBack}>
                     {user.organization && activeStep === 0 ? <span>Next step <ArrowForwardIosRoundedIcon /> </span> : user.organization && activeStep === 1 && <span><ArrowBackIosRoundedIcon/> Prev step</span>}
                   </div>
                 )}

                {(!user.organization || (user.organization && activeStep === 1)) &&
                 <div className="auth__btn auth__btn--pink" onClick={signUp}>
                   sign up
                 </div>
                }
                 {/* <div className="auth__btn auth__btn--plain" onClick={props.close} onClick={() => props.openPopup({ currentPopup: 'LogIn' })}>
                           log in
                     </div> */}
               </div>
               <div className="auth__socialText">Or you can</div>
               <GoogleAppleAuth setIsGoogleAuthLoading={(isLoading) => setIsGoogleAuthLoading(isLoading)} />
             </div>
             </div>
           </form>
         </div>
       </div>
     </div>
   );
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
   )(SignUp);