import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withScriptjs, GoogleMap,  withGoogleMap,  Marker, Polyline, DirectionsRenderer } from 'react-google-maps';


import { getWalkCoordinates, getWalksByStatus } from '../../service/index';
import mapStyles from './mapStyles';
import sockets from '../../service/socket.js';
import CurrentWalkDialog from '../currentWalkDialog/currentWalkDialog';


// icons 
import catsPaw from '../../img/ico/catsPaw.svg';

import catsPawPurple from '../../img/ico/catsPawPurple.svg';
import catsPawYellow from '../../img/ico/catsPawYellow.svg';
import catsPawGreen from '../../img/ico/catsPawGreen.svg';
import catsPawBlue from '../../img/ico/catsPawBlue.svg';


import startPointPurple from '../../img/ico/startPointPurple.svg';
import startPointYellow from '../../img/ico/startPointYellow.svg';
import startPointGreen from '../../img/ico/startPointGreen.svg';
import startPointBlue from '../../img/ico/startPointBlue.svg';

const mapStateToProps = state => ({
   ...state.map
});


const Map = (props) => {
   const {
      currentWalks,
      // coordinates
   } = props;

   const colors = ['#8da7f7', '#ffc054', '#76c427', '#2bc3ff' ];
   const startPointsImg = [startPointPurple, startPointYellow, startPointGreen, startPointBlue];
   const endPointsImg = [catsPawPurple, catsPawYellow, catsPawGreen, catsPawBlue];

   return (
      <section>
         {currentWalks?.length > 0 &&
            <GoogleMap
               defaultZoom={17}
               // defaultCenter= {{lat: 24.0192, lng: 49.8483}}
               defaultCenter={currentWalks[currentWalks.length - 1].coordinates[currentWalks[currentWalks.length - 1].coordinates.length - 1]}
               defaultOptions={{
                  styles: mapStyles,
                  scrollwheel: false
               }}
            >

               {currentWalks.map((x, index) => {
                  // console.log(x, 'coordinates');
                  return (
                     <>
                        <Polyline path={x.coordinates} options={{ strokeColor: colors[index], strokeWeight: 5 }} />
                        <Marker position={x.coordinates[x.coordinates.length - 1]} icon={{ url: endPointsImg[index], scaledSize: new window.google.maps.Size(40, 40) }} />
                        <Marker position={x.coordinates[0]} icon={{ url: startPointsImg[index], scaledSize: new window.google.maps.Size(20, 20), anchor: { x: 11, y: 11 } }} />
                     </>
                  );
               })}

            </GoogleMap>
            }

         {/* {currentWalks.length === 0 &&
            <GoogleMap
               defaultZoom={17}
               center={coordinatesSearchPlace && coordinatesSearchPlace.lat ? coordinatesSearchPlace : { lat: 49.823554, lng: 23.994844 }}
               defaultOptions={{
                  styles: mapStyles,
                  scrollwheel: false
               }}
            >

               <Marker position={coordinatesSearchPlace && coordinatesSearchPlace.lat ? coordinatesSearchPlace : { lat: 49.823554, lng: 23.994844 }} icon={{ url: catsPaw, scaledSize: new window.google.maps.Size(40, 40) }} />

            </GoogleMap>
         } */}


      </section>
   )
}

const WrappedMap = withScriptjs(withGoogleMap(connect(
   mapStateToProps,
   null
)(Map)))


const MapWrapper = (props) => {
   const googleMapsApiKey = 'AIzaSyCvGn6HoxNba_F_u7D-U4sB7iNuM8-qqOo';
   
   return (
      <WrappedMap
         googleMapURL={'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=' + googleMapsApiKey}
         loadingElement={<div style={{ height: `100%` }} />}
         containerElement={<div style={{ height: "100%" }}></div>}
         mapElement={<div style={{ height: "100%" }}></div>}
      />
   )
}

export default MapWrapper;