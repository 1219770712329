import {
   CURRENT_USER
} from './index';


export const currentUser = (payload) => {
   const { user, accessToken } = payload;
   return {
       type: CURRENT_USER,
       user,
       accessToken
   }
}