import React, {useEffect} from 'react';
import './currentWalkDialog.scss';
import moment from 'moment';
import defaultDogAvatar from '../../img/ico/defaultDogAvatar.svg';
import { getAllWalks, getWalkById, getUserById, baseURL} from '../../service/index';

const Walk = ({walk, color}) => {
   const [walker, setWalker] = React.useState();

   useEffect(() => {
      const getWalk = async () => {
         try {
            const resWalker = await getUserById(walk.walkerId);
            setWalker(resWalker.data.walker)

         } catch (error) {
            console.log(error)
         }
      }
      getWalk()

   }, [])


   const getDogsPhoto = (imagePath) => {
      if (imagePath) {
        if (imagePath.includes("http") || imagePath.includes("https")) {
          return imagePath;
        } else {
          return `${baseURL}uploads/dogs/${imagePath}`;
        }
      } else {
        return defaultDogAvatar;
      }
    };

   return (
      <div>

         {walker &&
            <div className="futureWalksDialog__futureWalk">
               <div className="futureWalksDialog__mainInfoWrapper">
                  <div className="futureWalksDialog__imgWrapper">
                     <img src={getDogsPhoto(walk.dog[0].imagePath)} alt="dogs default icon"/>
                  </div>
                  <div className="futureWalksDialog__walkerInfo">
                     <div className="futureWalksDialog__walkerName">{walk.dog[0].name}</div>
                     <div className="futureWalksDialog__walkerPosition">Dog</div>
                  </div>
                  
                  <div className="futureWalksDialog__walkerInfo">
                     <div className="futureWalksDialog__walkerName">{walker.name}</div>
                     <div className="futureWalksDialog__walkerPosition">Dog walker</div>
                  </div>
               </div>
               <div className="futureWalksDialog__dateTimeWrapper">
                  <div className="futureWalksDialog__date">{moment.utc(walk.startTime).format("HH:mm")} - {moment.utc(walk.finishTime).format("HH:mm")}</div>
               </div>
               <div className="futureWalksDialog__colorTag" style={{ background:  `${color}` }}></div>
            </div>
         }
      </div>
   )
}



const CurrentWalkDialog = (props) => {

   const [walks, setWalks] = React.useState(props.walks);

   return (
      <div className="futureWalksDialog">
         <div className="futureWalksDialog__title">{props.walks.length > 1 ? "Current Walks" :  "Current Walk"}</div>

         {walks.map((walk, index) => {
            return ( 
               <Walk walk={walk} key={walk._id} id={walk._id} color={colors[index]}/>
            )
         })}

      </div>
   )
}

export default CurrentWalkDialog


const colors = ['#8da7f7', '#ffc054', '#76c427', '#2bc3ff' ];