import React from 'react';

// for rating
import Rating from '@mui/material/Rating';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import Box from '@mui/material/Box';

// styles 
import './feedback.scss';

//images
import walker from '../../../img/walker_img.jpg';

// icons
import closeIcon from '../../../img/ico/close.svg';

const Feedback = (props) => {

   const [rating, setRating] = React.useState();
   const [comment, setComment] = React.useState("");

   const handleComment = (e) => {
      return (
         setComment(e.target.value)
      )
   }

   return (
      <section className="feedback__wrapper">
         <div className="feedback">
            <div className="feedback__closeBtn">
               <img src={closeIcon} onClick={props.close} alt="" />
            </div>
            <div className="feedback__title">Give feedback</div>
            <div className="feedback__img">
               <img src={walker} alt="" />
            </div>
            <div className="feedback__walkerName">Stephanie Milton</div>
            <div className="feedback__titleText">How is your walk?</div>
            <div className="feedback__plainText">Your feedback will helop other users</div>

            <div className="feedback__starRate">

               <Box component="fieldset" mb={3} borderColor="transparent">
                  <Rating
                     onChange={(event, newRating) => { setRating(newRating) }}
                     defaultValue={0}
                     precision={1}
                     size="large"
                     emptyIcon={<StarBorderRoundedIcon style={{ fontSize: 30 }} />}
                  />
               </Box>

            </div>
            <textarea 
               name="" 
               id="" 
               cols="30" 
               rows="10" 
               className="feedback__comment" 
               placeholder="Type text..."
               onChange={handleComment}>
            </textarea>
            <div className="feedback__saveBtn">Save</div>
         </div>
      </section>
   )
}

export default Feedback