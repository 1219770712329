import React, { useState, useEffect } from 'react'
import Shelter from '../shelter/shelter';
import './shelters.scss';
import { getSheltersReq } from '../../service';

const Shelters = ({setSelectedShelter, selectedShelter, setIsLoading, location}) => {

    const [shelters, setShelters] = useState([]);

    useEffect(() => {
        getShelters()
    }, [location])

    const getShelters =  async () => {
        setIsLoading(true)
        try {
            const res = await getSheltersReq({lt: location?.lat, ln: location?.lng});

            setShelters(res.data.shelters);
            console.log('shelters', res.data)
        } catch(e) {
            console.log('e', e)
        }
        setIsLoading(false)
    }

  return (
    <div className='shelters'>
        { shelters && shelters.map(x => {
            return <Shelter shelter={x} setSelectedShelter={setSelectedShelter} selectedShelter={selectedShelter}/>
        })}
    </div>
  )
}

export default Shelters