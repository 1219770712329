import {
   ADD_DATE,
   DELETE_DATE,
   DELETE_ALL_DATES,
   ADD_PRICE,
} from './index';


export const addDate = (payload) => {
   const { date } = payload;
   return {
       type: ADD_DATE,
       date
   }
}

export const deleteDate = (payload) => {
   const { date } = payload;
   return {
       type: DELETE_DATE,
       date
   }
}

export const deleteAllDates = () => {
   return {
      type: DELETE_ALL_DATES
   }
}

export const addPrice = (payload) => {
   const { date, price } = payload;
   return {
       type: ADD_PRICE,
       date,
       price
   }
}