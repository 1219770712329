import React, { useEffect, useState } from 'react';
import './searchPage.scss';
import { connect } from 'react-redux';
import { getWalksByStatus, searchDogs, baseURL } from '../../service/index';
import { Link } from "react-router-dom";
import {FormControl, Select, Autocomplete, TextField, InputLabel, MenuItem} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

// loader
import { Ellipsis } from 'react-awesome-spinners'

import CurrentWalkDialog from '../currentWalkDialog/currentWalkDialog';
import ContactDialog from '../contactDialog/contactDialog';
import MapWrapper from '../map/map';

import DogSize from '../dogSize/dogSize';
import Shelters from '../shelters/shelters';
import WalkProfile from '../walkProfile/walkProfile';
import WalkShelter from '../walkShelter/walkShelter';
import 'date-fns';

// icons 
import closeIcon from '../../img/ico/closeIcon.svg';
import defaultDogAvatar from '../../img/ico/defaultDogAvatar.svg';
import noWalks from '../../img/passDog.svg';
import { setSearchDogs } from '../../actions/dogs';
import { setUpcomingWalks } from '../../actions/walks';
import { openPopup } from '../../actions/popup';


const mapStateToProps = state => ({
   ...state.walkers,
   ...state.currentUser,
   ...state.walks
});

const mapDispatchToProps = dispatch => ({
   setDogs: (payload) => {
      return dispatch(setSearchDogs(payload));
   },
   setUpcomingWalks: (payload) => {
    return dispatch(setUpcomingWalks(payload));
  },
  openPopup: (payload) => {
    return dispatch(openPopup(payload));
  }
})

const SearchPage = (props) => {

  const {setUpcomingWalks, upcomingWalks, openPopup, user} = props;

  const [dogs, setDogs] = useState();
  const [showSearch, setShowSearch] = useState(true);
  const [currentWalks, setCurrentWalks] = useState([]);
  const [walks, setWalks] = useState([]);
  const [location, setLocation] = useState('');
  const [dogSize, setDogSize] = useState('all');
  const [isDogsSection, setIsDogsSection] = useState(true);
  const [selectedShelter, setSelectedShelter] = useState();
  const [isMapMode, setIsMapMode] = useState(false);
  const [isLogin, setIsLogin] = useState(Object.keys(user).length);
  const [cityList, setCityList] = useState([{name: 'Lviv', coords: {lat: 49.842957, lng: 24.031111 }}, {name: 'Kyiv', coords: {lat: 50.450001, lng: 30.523333 }}])
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=> {

    // openPopup({ currentPopup: 'Location' });

    setWalks(upcomingWalks);
      setCurrentWalks(upcomingWalks?.filter(x => x.status === 'in progress'));
  }, [upcomingWalks])

  useEffect(()=> {
    setIsLogin(Object.keys(user).length)
  }, [user])

  useEffect(() => {
    if(isLogin) {
      if(user.organization) {
        getWalks()
      } else {
        getWalks()
        getDogs()
      }
    } else {
      getDogs()
    }
  }, []);

  useEffect(() => {
    if(selectedShelter) {
       setIsDogsSection(true)      
    }
 }, [selectedShelter])

  const getDogs = async () => {
    setIsLoading(true);
     try {
        const res = await searchDogs({shelterId: selectedShelter?._id, lt: location?.lat, ln: location?.lng, size: dogSize === 'all' ? undefined : dogSize});
        props.setDogs({ dogs: res.data.dogs });
        setDogs(res.data.dogs)
     }

     catch (error) {
        console.log(error)
     }
    setIsLoading(false);
  }

  useEffect(() => {
    getDogs();
  }, [selectedShelter, location, dogSize])


   const getDogsPhoto = (imagePath) => {
      if (imagePath) {
        if (imagePath.includes("http") || imagePath.includes("https")) {
          return imagePath;
        } else {
          return `${baseURL}uploads/dogs/${imagePath}`;
        }
      } else {
        return defaultDogAvatar;
      }
    };

  const getWalks = async () => {
     try {
        const walksInProgress = await getWalksByStatus('in progress')
        const walksBooked = await getWalksByStatus('booked')
        console.log('walks booked', walksBooked.data.walks)
        setCurrentWalks(walksInProgress.data.walks);
        setWalks([...walksInProgress.data.walks, ...walksBooked.data.walks]);

        // set upcomingWalks to redux
        setUpcomingWalks({ upcomingWalks: [...walksInProgress.data.walks, ...walksBooked.data.walks] });

     } catch (error) {
        console.log(error)
     }
  }

  const showBookBtn = (dog) => {
    if (!isLogin) {
      return (
        <div className="dogBox__orderBtn" onClick={() => openPopup({ currentPopup: 'LogIn' })}> Book the walk</div>
      );
    }
  
    return dog.workHours.length ? (
      <Link className="dogBox__orderBtn" to={{ pathname: `/dog/${dog._id}` }}>
        Book the walk
      </Link>
    ) : (
      <Tooltip title="A schedule has not been set for this dog yet." placement="top" arrow>
        <div className="dogBox__orderBtn--disabled"> Book the walk</div>
      </Tooltip>
    );
  };

  return (
    <div className="search__wrapper fadeIn">
      {!isMapMode && (
        <div>
          {walks && walks.length > 0 ? (
            <div className="search__walks">
              <div className="title">Upcoming walks</div>
              <div className={user.organization ? "search__walksShelterWrapper" : "search__walksWrapper"}>
                {walks.map((walk) => {
                  if (user.organization) {
                    return (
                      <WalkShelter key={walk._id} walk={walk} />
                    )
                  } else {
                    return (
                      <WalkProfile key={walk._id} walk={walk} />
                    )
                  }
                })}
              </div>
            </div>
          )
            :
            user.organization &&
            <div className="search__walks">
              <div className="title">Upcoming walks</div>
              <div className='search__noWalks'>
                <img src={noWalks} alt="no walks illustration" />
                <div>You don’t have booked walks.</div>
              </div>
            </div>
          }

       {!user.organization && (
         <section>
           {showSearch && (
             <div className="fadeIn" style={{position: 'relative'}}>
               <div className="search__switcherMobile">
                 <input
                   type="radio"
                   name="searchSwitcherMobile"
                   value="yin"
                   id="dogs"
                   className="search__switcherInput search__switcherInput--dogs"
                   checked={isDogsSection}
                   onChange={() => setIsDogsSection(true)}
                 />
                 <label for="dogs" className="search__switcherLabel">
                   Dogs
                 </label>

                 <input
                   type="radio"
                   name="searchSwitcherMobile"
                   value="yang"
                   id="shelters"
                   className="search__switcherInput search__switcherInput--shelters"
                   checked={!isDogsSection}
                   onChange={() => setIsDogsSection(false)}
                 />
                 <label for="shelters" className="search__switcherLabel">
                   Shelters
                 </label>

                 <span className="search__switcherToggle"></span>
               </div>

               <div className="search__filterWrapper">
                 <div className="search__filterMainInfoWrapper">
                       <Autocomplete
                         disablePortal
                         id="combo-box-demo"
                         options={cityList}
                         size="small"
                         getOptionLabel={(option) => option.name}
                         onChange={(e, value) => setLocation(value?.coords)}
                         renderInput={(params) => <TextField {...params} className="search__locationInput" label="City" />}
                       />

                       <div className="search__switcher">
                     <input
                       type="radio"
                       name="searchSwitcherDesktop"
                       id="dogs"
                       className="search__switcherInput search__switcherInput--dogs"
                       checked={isDogsSection}
                       onChange={() => setIsDogsSection(true)}
                     />
                     <label for="dogs" className="search__switcherLabel">
                       Dogs
                     </label>

                     <input
                       type="radio"
                       name="searchSwitcherDesktop"
                       id="shelters"
                       className="search__switcherInput search__switcherInput--shelters"
                       checked={!isDogsSection}
                       onChange={() => setIsDogsSection(false)}
                     />
                     <label for="shelters" className="search__switcherLabel">
                       Shelters
                     </label>

                     <span className="search__switcherToggle"></span>
                   </div>

                   {selectedShelter && (
                     <div className="search__selectedShelter">
                       {selectedShelter.organizationName}
                       <img src={closeIcon} alt="delete selected shelter" onClick={() => setSelectedShelter()} />
                     </div>
                   )}
                 </div>

                {/* {isDogsSection &&  */}
                  <div className="search__sizeFilterWrapper">
                    <FormControl sx={{m: 1, width: 150}} size="small">
                      <InputLabel id="demo-simple-select-label" className="search__label">
                        Dog size
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={dogSize}
                        label="dogSize"
                        onChange={(e) => setDogSize(e.target.value)}
                        className="search__filter"
                      >
                        <MenuItem value={"all"}>All dogs</MenuItem>
                        <MenuItem value={"s"}>Small dogs</MenuItem>
                        <MenuItem value={"m"}>Medium dogs</MenuItem>
                        <MenuItem value={"l"}>Large dogs</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                {/* } */}
               </div>

               {isLoading &&
                    <div className='loader fadeIn'>
                      <Ellipsis color="#ff9085"/>
                    </div>
                }
             </div>
           )}

           {showSearch && isDogsSection && (
             <div className="dogs fadeIn">
               {dogs &&
                 dogs.map((dog, index) => {
                   return (
                     <div key={dog._id} className="dogBox">
                       <div className="dogBox__imgWrapper">
                           <img src={getDogsPhoto(dog.imagePath)} alt="dogs default icon"/>
                       </div>
                       <div className="dogBox__info">
                         <DogSize size={dog.size} />
                         <div className="dogBox__name">{dog.name}</div>
                       </div>
                       <div className="dogBox__desc">
                         <div className="dogBox__descLabel">Comment</div>
                         <div className="dogBox__descText">
                           {dog.comment
                             ? dog.comment
                             : "Be careful with the dog, she loves to run away. You can feed yummy while you walk"}
                         </div>

                         <div className="dogBox__descLabel">Shelter</div>
                         <div className="dogBox__descText">Prytulok Myloserdya</div>
                       </div>

                       {showBookBtn(dog)}
                      
                     </div>
                   );
                 })}
             </div>
           )}

           {showSearch && !isDogsSection && (
             <Shelters
               setSelectedShelter={(shelter) => {setSelectedShelter(shelter); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });}}
               selectedShelter={selectedShelter}
               setIsLoading={setIsLoading}
               location={location}
             />
           )}
         </section>
       )}
       </div>)}

       {currentWalks.length > 0 && (
         <>
           <div className="mapCircleWrapper">
             {isMapMode ? (
               <div
                 className="search__orderBtn"
                 onClick={() => {
                   setIsMapMode(false);
                   setShowSearch(true);
                 }}
               >
                 Order new
               </div>
             ) : (
               <div className="mapCircle__walksCount">{currentWalks.length}</div>
             )}
             <div
               className={isMapMode ? "mapCircle--fullScreen" : "mapCircle"}
               onClick={() => {
                 setIsMapMode(true);
                 setShowSearch(false);
               }}
             >
               <div className="mapWrapper">
                 <MapWrapper />
               </div>
             </div>
             {isMapMode && (
               <>
                 <CurrentWalkDialog walks={currentWalks} />
                 {/* <ContactDialog walk={currentWalks} /> */}
               </>
             )}
           </div>
         </>
       )}

{/* {currentWalks.length > 0 &&
  <div style={{width: '200px'}}>
    <LightButton text="Show map" onClick={() => setIsMapMode(true)}/>
  </div>
} */}


{/* {currentWalks.length > 0 &&(
  <div style={{position: 'relative'}}>
    <div className='mapBtn'>open </div>

<div className= "mainMapShelter">
  <div className="mapWrapper">
    <MapWrapper />
  </div>
</div>
     </div>
)} */}

     </div>
   );
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(SearchPage);