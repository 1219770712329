import axios from 'axios';

axios.interceptors.request.use(config => {
    const token = localStorage.getItem('accessToken');

    config.headers = {
        'content-type': 'application/json',
        'x-accesstoken': (token) ? token : ''
    }

    return config;
});

export const baseURL            = 'https://api.dog-walking.pet/';
export const googleMapsApiKey   = 'AIzaSyCvGn6HoxNba_F_u7D-U4sB7iNuM8-qqOo';
// const baseURL       = 'http://localhost:3000/';
const api           = baseURL + 'api/v1/';
const usersAPI      = api + 'users/';
const dogsAPI       = api + 'dogs/';
const walkersAPI    = api + 'walkers/';
const walksAPI      = api + 'walks/';


// USERS API
export const createUser     = (payload) => axios.post(usersAPI + "register", payload);
export const loginUser      = (payload) => axios.post(usersAPI + "login", payload);
export const getUser        = () => axios.get(usersAPI + "me");
export const updateUser     = (payload) => axios.put(usersAPI + "me", payload);
export const checkAuth      = () => axios.get(usersAPI + "check");
export const getUserById    = (id) => axios.get(usersAPI + id);
export const addUserPhoto   = (payload) => axios.post(usersAPI + "me/image", payload);
export const deleteUserPhoto = () => axios.delete(usersAPI + "me/image");
export const getSheltersReq = (data) => {
    const lt = data.lt
    const ln = data.ln
    const params = new URLSearchParams({
        lt,
        ln,
    });
    removeNullsAndUndefined(params);
    return axios.get(usersAPI + 'search' + '?' + params.toString());
}

export const googleAuth     = (payload) => axios.post(usersAPI + "google", payload);
export const googleAuthContinue = (payload) => axios.post(usersAPI + "google/continue", payload);
export const setWorkingDaysReq = (payload) => axios.put(usersAPI + "days", payload);
export const setSpecificDateReq = (payload) => axios.put(usersAPI + "specific", payload);

// DOGS API 
export const getDogs        = () => axios.get(dogsAPI);
export const addDogReq      = (payload) => axios.post(dogsAPI, payload);
export const deleteDogReq   = (id) => axios.delete(dogsAPI + id);
export const updateDogReq   = (id, payload) => axios.put(dogsAPI + id, payload);
export const getDogById     = (id) => axios.get(dogsAPI + id);
export const addDogPhoto    = (id, payload) => axios.post(dogsAPI + id + '/image', payload);
export const setDogsWorkingDaysReq = (payload) => axios.put(dogsAPI + "days", payload);
export const setDogsSpecificDateReq = (payload) => axios.put(dogsAPI + "specific", payload);

export const searchDogs = (data) => {

    const shelterId = data.shelterId
    const size = data.size
    const lt = data.lt
    const ln = data.ln
    const page = data.page
    const limit = data.limit

    const params = new URLSearchParams({
        lt,
        ln,
        shelterId,
        size,
        page,
        limit
    });
    removeNullsAndUndefined(params);
    return axios.get(dogsAPI + 'search' + '?' + params.toString());
}


// WALKERS API
export const getWalkersReq  = () => axios.get(walkersAPI);
export const getWalkerById  = (id) => axios.get(walkersAPI + id);

//WALKS API
export const createWalk     = (payload) => axios.post(walksAPI, payload);
export const getAllWalks    = () => axios.get(walksAPI + 'all');
export const getWalkById    = (id) => axios.get(walksAPI + id);
export const getWalksByStatus = (status, page) => { 
    const params = new URLSearchParams({
        status,
        page,
      });
    removeNullsAndUndefined(params);
    return axios.get(walksAPI + 'all' + '?'+ params.toString()); 
}

export const getWalkCoordinates = (id) => axios.get(walksAPI + id + '/coordinates');


export const removeNullsAndUndefined = (params) => {
    let keysForDel = [];
    params.forEach((value, key) => {
        if (value == "null" || value == "undefined" ) {
            keysForDel.push(key);
        }
    });

    keysForDel.forEach(key => {
        params.delete(key);
    });
}