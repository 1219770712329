import {
   SET_DOGS,
   ADD_DOG,
   UPDATE_DOG,
   DELETE_DOG,
   SET_SEARCH_DOGS
} from './index';


export const setDogs = (payload) => {
   const { dogs } = payload;
   return {
       type: SET_DOGS,
       dogs
   }
}

export const addDog = (payload) => {
   const { dog } = payload;
   return {
       type: ADD_DOG,
       dog
   }
}

export const updateDog = (payload) => {
   const { dog } = payload;
   return {
       type: UPDATE_DOG,
       dog
   }
}

export const deleteDog = (payload) => {
   const { dog } = payload;
   return {
       type: DELETE_DOG,
       dog
   }
}

export const setSearchDogs = (payload) => {
   const { dogs } = payload;
   return {
       type: SET_SEARCH_DOGS,
       searchDogs: dogs
   }
}
