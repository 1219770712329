import {
  SET_WALKERS
} from '../actions';

export default ( state = {
   walkers: [],
}, action) => {

   const {
      walkers,
   } = action;

   switch (action.type) {
      case SET_WALKERS :
         {
            return {
               ...state,
               walkers: walkers
            }
      }
      
      default:
         return state;
      }
}