import React, { useState, useEffect, Suspense } from 'react';
import { connect } from 'react-redux';
import './App.css';
import './components/popup/popup.scss';
import HomePage from './components/homePage/homePage';
import Header from './components/header/header';
import Illustration from './components/illustration/illustration';
import SearchPage from './components/searchPage/searchPage';
import DogProfile from './components/dogProfile/dogProfile';
import UserProfile from './components/userProfile/userProfile';
import DogsPage from './components/dogsPage/dogsPage';
import Snackbar from './components/snackbar/snackbar';
import Popup from './components/popup/popup';
import { setToken } from './service/socket';
import { getDogs, getUser } from './service/index';
import { Switch, Route} from "react-router-dom";

import { openPopup } from './actions/popup';
import { currentUser } from './actions/currentUser';
import { setDogs } from './actions/dogs';
import { setSnackbar } from './actions/snackbar';


import ProtectedRoutes from "./ProtectedRoutes"; //Authenticated routes
import PrivateRoute from "./PrivateRoute";

const mapStateToProps = (state) => ({
  ...state.currentUser,
});

const mapDispatchToProps = dispatch => ({
  currentUser: (payload) => {
    return dispatch(currentUser(payload));
  },
  openPopup: (payload) => {
    return dispatch(openPopup(payload));
  },
  setDogs: (payload) => {
    return dispatch(setDogs(payload));
  },
  setSnackbar: (payload) => {
    return dispatch(setSnackbar(payload));
  }

})


function App(props) {
  const {
    currentUser,
    openPopup,
    setDogs,
    user
  } = props;

  const [authChecked, setAuthChecked] = useState(false);
  const [showFinishBtn, setShowFinishBtn] = useState(false);
  const token = localStorage.getItem("accessToken");

  useEffect(async () => {

    if (token)
      setToken(token);

    if (token != null) {
      const resp = await getUser().catch(console.log);

      if (!resp) {
        openPopup({ currentPopup: 'LogIn' });
        localStorage.removeItem("accessToken");
        return setAuthChecked(true);
      };

      if (!resp.data.user.finishedRegistration) {
        openPopup({ currentPopup: 'GoogleContinue' });
        setShowFinishBtn(true);
        return setAuthChecked(true);
      }

      currentUser({ user: resp.data.user });
      setAuthChecked(true);

      loadDogs();
    } else {
      setAuthChecked(true);
      setShowFinishBtn(false);
    }

  }, [token]);


  const loadDogs = async () => {
    try {
      const res = await getDogs();
      setDogs({ dogs: res.data.dogs })
    }

    catch (error) {
      console.log(error)
    }
  }

  const isAuthenticated = Object.keys(user).length > 0;

  return (
    <>
      <div className="App">

        {/* <Illustration/> */}
        {authChecked &&
          <Suspense>
            <Header showFinishBtn={showFinishBtn} />
            <Snackbar />
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route path="/search" component={SearchPage} />
              <PrivateRoute path="/" isAuthenticated={isAuthenticated}>
                <ProtectedRoutes user={user} />
              </PrivateRoute>
            </Switch>
            <Popup />
          </Suspense>
        }
      </div>
    </>
  );
}

export default connect(
  mapStateToProps,
   mapDispatchToProps
)(App);
