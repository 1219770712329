import React, { useState } from 'react';
import { connect } from 'react-redux';

// styles 
import './passDog.scss';

// images 
import dog from '../../../img/passDog.svg';

// icons 
import closeIcon from '../../../img/ico/close.svg';


import {
   closePopup,
} from '../../../actions/popup';

const mapStateToProps = state => ({
   ...state.popups
});

const mapDispatchToProps = dispatch => ({
   closePopup: () => {
      return dispatch(closePopup());
   }
})


const PassDog = (props) => {
   return (
      <div className="dogsPopup__wrapper">
         <div className="dogsPopup">
            <div className="dogsPopup__closeBtn" onClick={props.close}>
               <img src={closeIcon} alt="" />
            </div>
            <img className="dogsPopup__img" src={dog}></img>
            <div className="dogsPopup__title">Did you pass the dog?</div>
            <div className="dogsPopup__plainText">Designate if you walk has begun</div>

            <div className="dogsPopup__btnWrapper">
               <div className="dogsPopup__emptyBtn dogsPopup__btn">No, i didn't</div>
               <div className="dogsPopup__fullBtn dogsPopup__btn">Yes, i did</div>
            </div>   
         </div>
      </div>
   )
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(PassDog);