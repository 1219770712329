import io from 'socket.io-client';

import {
    walkStarted,
    walkFinished,
    setNewCoordinates,
    setLoadedCoordinates,
} from '../actions/map';
import { setSnackbar } from '../actions/snackbar';
import { setUpcomingWalks } from '../actions/walks';
import { baseURL, getWalksByStatus } from './index';
import { getWalkCoordinates } from './index';

import store from '../store';

const sockets = io.connect(baseURL);


export const setToken = token => {
    sockets.disconnect();
    sockets.io.opts.extraHeaders = { authorization: token }

    sockets.connect({
        extraHeaders: { authorization: token }
    });
};

const getWalks = async () => {
    try {
        const walksInProgress = await getWalksByStatus('in progress')
        const walksBooked = await getWalksByStatus('booked')
        store.dispatch(setUpcomingWalks({ upcomingWalks: [...walksInProgress.data.walks, ...walksBooked.data.walks] }));
    } catch(e){
        console.log(e)
    }
}

sockets.on('walk:started', data => {
    const {
        walkerId,
        walkId
    } = data;

    const payload = {
        walkerId,
        walkId
    };

    store.dispatch(walkStarted(payload));
    store.dispatch(setSnackbar({ snackbarOpen: true, snackbarType: "info", snackbarMessage: "Your walk started!" }));
    getWalks();
})
sockets.on('walk:finished', data => {
    const {
        walkerId,
        walkId
    } = data;

    const { currentWalks } = store.getState().map;

    const updatedWalks = currentWalks.filter(walk => walk.walkId != walkId);

    console.log('walk finished');

    store.dispatch(setNewCoordinates({ currentWalks: updatedWalks }));
    store.dispatch(setSnackbar({ snackbarOpen: true, snackbarType: "info", snackbarMessage: "Your walk finished!" }));
    getWalks();
})
sockets.on('map:receive', async data => {
    const {
        walkerId,
        walkId,
        payloadPoint:  [lat, lng]
    } = data;

    const { currentWalks } = store.getState().map;

    const indexWalk = currentWalks.findIndex(walk => walk.walkId === walkId);

    if(indexWalk < 0) {
        const coords = await getWalkCoordinates(walkId);
        const  points  = coords.data.walkCoordinates.coordinations;

        const oldCoords = points.map(point => ({ lat: +point.coordinates[0], lng: +point.coordinates[1]}))

        currentWalks.push({ walkerId, walkId, loaded: true, coordinates: [...oldCoords, { lat, lng }]});
    }

    if(indexWalk >= 0) {
        const walk = currentWalks[indexWalk];
        const oldCoordinates = walk.coordinates;

        currentWalks[indexWalk] = { ...walk, coordinates: [ ...oldCoordinates, { lat, lng }] }   
    }

    store.dispatch(setNewCoordinates({ currentWalks: [...currentWalks] }));
})

export default sockets;