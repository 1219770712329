import React from 'react';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import LogIn from '../popups/logIn/logIn';
import SignUp from '../popups/signUp/signUp';
import AddDog from '../popups/addDog/addDog';
import Walks from '../popups/walks/walks';
import AddCard from '../popups/addCard/addCard';
import ConfirmChoice from '../popups/confirmChoice/confirmChoice';
import Feedback from '../popups/feedback/feedback';
import WalkOver from '../popups/walkOver/wakOver';
import PassDog from '../popups/passDog/passDog';
import EditWalk from '../popups/editWalk/editWalk';
import Confirmation from '../popups/confirmation/confirmation';
import ShelterDetails from '../popups/shelterDetails/shelterDetails';
import GoogleContinue from '../popups/googleContinue/googleContinue';
import UploadImage      from '../popups/uploadImage/uploadImage';
import Location from '../popups/location/location';

import { loadStripe } from "@stripe/stripe-js";
import { Elements, useElements,useStripe } from "@stripe/react-stripe-js";


import {
   closePopup,
} from '../../actions/popup';

const mapStateToProps = state => ({
   ...state.popups
});

const mapDispatchToProps = dispatch => ({
   closePopup: () => {
      return dispatch(closePopup());
   },
})

const Popup = (props) => {
   
   const handleClose = () => {
      props.closePopup();
   }
   
   const map = {
      "LogIn": LogIn,
      "SignUp": SignUp,
      "AddDog": AddDog,
      "Walks": Walks,
      "AddCard": AddCard,
      "ConfirmChoice": ConfirmChoice,
      "Feedback" : Feedback,
      "WalkOver" : WalkOver,
      "PassDog" : PassDog,
      "EditWalk": EditWalk,
      "Confirmation": Confirmation,
      "ShelterDetails" : ShelterDetails,
      "GoogleContinue" : GoogleContinue,
      "UploadImage" : UploadImage,
      "Location": Location,
    }

   const CurrentPopup = map[props.currentPopup];
   const stripePromise = loadStripe("pk_test_51HwVIvCieGTkTh9BdrV526xnei4VUGFp5AdQdjjtZaRSecBDvXkubhkqQoeZA4Hqrm2bTQApQSc7G445tP4aoNmS00JsWcIFPn");
   
   return (
      
      <Dialog
      open={props.showPopup}
      onClose={handleClose}
      maxWidth={props.currentPopup === 'Confirmation' || props.currentPopup === 'ConfirmChoice' ||  props.currentPopup === 'GoogleContinue' || props.currentPopup === 'Location' ? "sm" :"lg"}
      className="dialog"
      >
         <DialogContent className="dialog">
            <Elements stripe={stripePromise}>
               <CurrentPopup close={handleClose} info={props.info}/>
            </Elements>
         </DialogContent>

      </Dialog>
   )
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(Popup);