import React, {useEffect} from "react";
import { connect } from 'react-redux';
import "./shelter.scss";
import {OutlinedButton, PlainButton} from "../buttons/buttons";
import {openPopup} from "../../actions/popup";
import { baseURL } from '../../service';
import shelterIcon from '../../img/ico/shelterIcon.svg'

const mapDispatchToProps = (dispatch) => ({
  openPopup: (payload) => {
    return dispatch(openPopup(payload));
  }
});

const Shelter = ({shelter, openPopup, setSelectedShelter, selectedShelter}) => {
  return (
    <div className={selectedShelter?._id === shelter._id ? "shelter shelter--active" : "shelter"}>
      <div className="shelter__infoWrapper">
        <div className="shelter__imgWrapper">
          <img
            src={shelter.image ? `${baseURL}uploads/users/${shelter.image}` : shelterIcon}
            alt="shelter photo"
            style={shelter.image ? {objectFit:'cover'} : {objectFit:'contain'}}
          />
        </div>
        <div className="shelter__info">
          <div className="shelter__nameWrapper">
            <div className="shelter__name">{shelter.organizationName}</div>
            <div className="shelter__dogsCount">{shelter.dogs.length} dogs</div>
          </div>
          <div className="shelter__label">Location</div>
          <div>{shelter.city}, {shelter.address}</div>

          <div className="shelter__buttons">
            <OutlinedButton text={selectedShelter?._id === shelter._id ? "Unselect" : "Select"} onClick={() => selectedShelter?._id === shelter._id ? setSelectedShelter() : setSelectedShelter(shelter)}/>
            <PlainButton text="More info" onClick={() => openPopup({ currentPopup: 'ShelterDetails', info:{shelter} })} />
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(Shelter);
