import React, { useEffect, useState, useRef, useCallback } from 'react'
import axios from 'axios';

import { getWalksByStatus } from '../../service/index';
import Walk from '../walk/walk';

// loader
import { Ellipsis } from 'react-awesome-spinners'

// for tabs 
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const Walks = () => {

   const [walks, setWalks] = useState([]);
   const classes = useStyles();
   const [value, setValue] = useState(1);
   const statuses = ['in progress', 'booked', 'past', 'canceled'];
   const [loading, setLoading] = useState(true);
   const [pageNumber, setPageNumber] = useState(0);
   const [hasMore, setHasMore] = useState(false);
   const [isNoWalks, setIsNoWalks] = useState(false)

   const observer = useRef();

   const lastBookElementRef = useCallback(node => {

      if (loading || !node) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(entries => {

         if (entries[0].isIntersecting && hasMore) {
           setPageNumber(prevPageNumber => prevPageNumber + 1)
         }
      })

      if (node) observer.current.observe(node)

   }, [loading, hasMore]);


   const onChangeTab =   (event, newValue) => {
      if (value === newValue) return;
      setValue(newValue)
      setWalks([]);
      setPageNumber(0)
   }
   
   
   useEffect(() => {
      
      setIsNoWalks(false);
      setLoading(true)
      console.log("PAGE NUMBER", pageNumber)
      
      const uploadNextWalks = async () => {
         try {
            const res = await getWalksByStatus(statuses[value], pageNumber)
            
            setWalks([...walks, ...res.data.walks])

            if(pageNumber === 0 && res.data.walks.length === 0) {
               setIsNoWalks(true);
            }
            setHasMore(res.data.walks.length > 0);
            setLoading(false)
            
         } catch (error) { console.log(error) }
      }
      
      uploadNextWalks()
      
   }, [pageNumber, value])

   useEffect( async() => {
      const res = await getWalksByStatus(statuses[0]);
      if( res.data.walks.length > 0) { setValue(0) }
   }, [])


   return (
      <>
         <div className="walks__walkTitle">Walks</div>

         <div className="walks__tabsWrapper">
            <AppBar position="static" color="default">
               <Tabs
                  value={value}
                  onChange={onChangeTab}
                  centered
                  className={classes.tabs}
                  classes={{ indicator: classes.indicator }}
               >
                  {statuses.map(status => <Tab label={status} />)}
               </Tabs>
            </AppBar>

            {statuses.map((status, index) => {
               return (
                  <TabPanel value={value} index={index}>
                     <div className="lastWalk">

                        {walks.length > 0 && walks.map((walk, i) => {
                           return (
                              walks.length === i + 1
                                 ? <div className="lastWalk__container" ref={lastBookElementRef}><Walk key={i} id={walk._id} /> </div>
                                 : <div className="lastWalk__container"> <Walk key={i} id={walk._id} /> </div>
                           )
                        })
                     }

                     {isNoWalks && <div className="lastWalk__noWalkMessage">No {status} walks</div>}

                     </div>
                  </TabPanel>
               )
            })}

            {loading && <Ellipsis color= '#ffdad8' size= '64' sizeUnit= 'px'/>}

         </div>
      </>
   )
}

export default Walks


const useStyles = makeStyles((theme) => ({
   tabs: {
      padding: '0rem',
      color: '#ff9085',
      backgroundColor: 'white',
      fontFamily: 'Open Sans',
   },

   indicator: {
      backgroundColor: '#ff9085',
   },
}))


function TabPanel(props) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-auto-tabpanel-${index}`}
         aria-labelledby={`scrollable-auto-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box p={3}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}