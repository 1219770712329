import { lazy } from 'react';

const routes = [
  {
    path: '/',
    component: lazy(() => import('./components/homePage/homePage')),
    exact: true
  },
  {
    path: 'profile',
    component: lazy(() => import('./components/userProfile/userProfile')),
    exact: true
  },
  {
    path: 'dog/:id/',
    component: lazy(() => import('./components/dogProfile/dogProfile')),
    exact: true
  },
  {
    path: 'dogs',
    component: lazy(() => import('./components/dogsPage/dogsPage')),
    onlyOrganization: true,
    exact: true
  }
];

export default routes;