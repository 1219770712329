import React from "react";
import "./dogSize.scss";
import smallDogIcon from "../../img/ico/smallDog.svg";
import mediumDogIcon from "../../img/ico/mediumDog.svg";
import largeDogIcon from "../../img/ico/largeDog.svg";
import sizeBg from "../../img/ico/sizeBg.svg";
import sizeBgMedium from "../../img/ico/sizeBgMedium.svg";

const DogSize = (props) => {
  const {size} = props;

  const sizes = [{label: "small", value: "s"}, {label: "medium", value: "m"}, {label: "large", value: "l"}];

  const getDogIcon = () => {
    if (size === "small" || "s") {
      return smallDogIcon;
    }
    if (size === "medium" || "m") {
      return mediumDogIcon;
    }
    if (size === "large" || "l") {
      return largeDogIcon;
    }
  };
  return (
    <div className="dogSize">
      <div className="dogSize__bg"></div>
      <div className="dogSize__bgCircle"></div>
      <div className="dogSize__bgCircle--top"></div>
      <div className="dogSize__size">{sizes.find(x => (x.value == size || x.label == size)).label}</div>
      <div className="dogSize__img">
        <img src={getDogIcon()} alt="dog size icon" />
      </div>
    </div>
  );
};

export default DogSize;
