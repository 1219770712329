import React, {useState, useRef, useEffect} from "react";
import DogSize from "../dogSize/dogSize";
import StatusBadge from "../statusBadge/statusBadge";

// icons
import settingsIcon from "../../img/ico/settingsIcon.svg";
import closeIcon from "../../img/ico/closeIcon.svg";
import rightArrow from "../../img/ico/rightArrow.svg";

const WalkProfile = ({walk}) => {
  const [moreAction, setMoreAction] = React.useState(false);
  const moreActionMenu = useRef(null);
  const status = walk.status;

  useEffect(() => {
    function handleClickOutside(event) {
      if (moreActionMenu.current && !moreActionMenu.current.contains(event.target)) {
        setMoreAction(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [moreActionMenu]);

  const openMoreAction = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setMoreAction(!moreAction);
  };

  const closeMoreAction = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setMoreAction(false);
  };

  let showEditBtn = () => {
    let statuses = ["booked"];
    return statuses.includes(status);
  };

  let showCancelBtn = () => {
    let statuses = ["booked"];
    return statuses.includes(status);
  };

  let showContactSupportBtn = () => {
    let statuses = ["booked"];
    return statuses.includes(status);
  };

  let showOrderAgainBtn = () => {
    let statuses = ["past"];
    return statuses.includes(status);
  };

  const showSettingsIcon = () => {
    if (moreAction || status === "canceled" || status === "in progress") {
      return;
    } else {
      return (
        <div className="userProfile__walkSettingsBtn" onClick={(e) => openMoreAction(e)}>
          <img src={settingsIcon} alt="walk settings" />
        </div>
      );
    }
  };

  return (
    <div className="userProfile__walk">
      <StatusBadge status={status} />
      <div className="userProfile__walkMainInfo">
        <div className="userProfile__walkSizeName">
          <DogSize size="small" />
          <div className="userProfile__dogName">Dog Sally</div>
        </div>
        {showSettingsIcon()}
      </div>

      <div className="userProfile__walkInfo">
        <div className="userProfile__walkLabel">Comment</div>
        <div className="userProfile__walkText">
          Be careful with the dog, she loves to run away. You can feed yummy while you walk
        </div>

        <div className="userProfile__walkLabel">Location</div>
        <div className="userProfile__walkText">Miami, Coral way 2055 FL-972</div>

        <div className="flexBetween">
          <div className="flexColumn">
            <div className="userProfile__walkLabel">Date</div>
            <div className="userProfile__walkText">12.05</div>
          </div>

          <div className="flexColumn">
            <div className="userProfile__walkLabel">Time</div>
            <div className="userProfile__walkText">18:00-20:00</div>
          </div>

          <div className="flexColumn">
            <div className="userProfile__walkLabel">Price</div>
            <div className="userProfile__walkText">Free</div>
          </div>
        </div>
      </div>

      {/* walk settings */}
      {moreAction && (
        <div className="userProfile__walkSettings" ref={moreActionMenu}>
          <div className="userProfile__walkSettingsBtn" onClick={(e) => closeMoreAction(e)}>
            <img src={closeIcon} alt="walk settings" />
          </div>

          <div className="userProfile__walkSettingsContent">
            {showEditBtn() && (
              <div className="userProfile__walkSettingsItem">
                <span>Edit walk</span>
                <img src={rightArrow} />
              </div>
            )}

            {showCancelBtn() && (
              <div className="userProfile__walkSettingsItem">
                <span>Cancel walk</span>
                <img src={rightArrow} />
              </div>
            )}

            {showContactSupportBtn() && (
              <div className="userProfile__walkSettingsItem">
                <span>Contact support</span>
                <img src={rightArrow} />
              </div>
            )}

            {showOrderAgainBtn() && (
              <div className="userProfile__walkSettingsItem">
                <span>Order again</span>
                <img src={rightArrow} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default WalkProfile;
