import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { loginUser } from '../../../service/index';
import { Redirect } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { setToken } from '../../../service/socket';
import GoogleAppleAuth from "../../googleAppleAuth/googleAppleAuth";
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

// styles 
import './signUpLogIn.scss';

// images 
import closeIcon from '../../../img/ico/close.svg';
import authImage from '../../../img/auth.svg';

// icons
import google from '../../../img/ico/google.svg';
import facebook from '../../../img/ico/facebook.svg';

import {
   openPopup,
   closePopup
} from '../../../actions/popup';

import {
   currentUser
} from '../../../actions/currentUser';

const mapStateToProps = state => ({
   ...state.currentUser,
   ...state.popups
});

const mapDispatchToProps = dispatch => ({
   closePopup: () => {
      return dispatch(closePopup());
   },
   currentUser: (payload) => {
      return dispatch(currentUser(payload));
   },
   openPopup: (payload) => {
      return dispatch(openPopup(payload));
   }
})


const LogIn = (props) => {

   const [userData, setUserData] = useState({
      email: '',
      password: ''
   });

   const [errorMessage, setErrorMessage] = useState(null);
   const history = useHistory();
   const [isGoogleAuthLoading, setIsGoogleAuthLoading] = useState(false);
   const [isPasswordFocus, setIsPasswordFocus] = useState(false);
   const passwordEl = useRef(null);
   const [showPassword, setShowPassword] = useState(false);

   const handleChange = (e) => {
      setUserData({
         ...userData,
         [e.target.name]: e.target.value
      })
      setErrorMessage(null);
   }

   const logIn = async () => {
      const res = await loginUser(userData).catch(err => {
         setErrorMessage("wrong email or password")
         console.log(err);
      });

      if(!res)
         return

      const {
         token,
         user
      } = res.data;

      if(token)
         setToken(token);
      
      localStorage.setItem("accessToken", token);
      props.currentUser({ user });
      props.closePopup();
      setErrorMessage(null);
      user.organization ? history.push("/dogs") : history.push("/search");
   }

   const loginOnEnter = (event) => {
      if (event.key === "Enter") {
        if (userData.email.length > 0 && userData.password.length > 0) {
          logIn();
        } else if (userData.email.length > 0 && userData.password.length === 0) {
          setIsPasswordFocus(true);
          passwordEl.current.focus();
        }
      }
    };


   const {
      email,
      password
   } = userData;

   return (
      <div className="auth__wrapper">
         <div className="auth">
            <div className="auth__formWrapper">
               <div className="auth__formClose" onClick={props.close}>
                  <img src={closeIcon} alt="" />
               </div>
               <div className="auth__formImg">
                  <img src={authImage} alt="" />
               </div>
               <form action="" className="auth__form">
                  <div className="auth__title">Welcome Back!</div>

                  <div className="auth__formField">
                     <input className={errorMessage ? "auth__formInput auth__formInput--error" : "auth__formInput" }
                        type="email"
                        id="email"
                        placeholder="Enter your e-mail"
                        name="email"
                        value={email}
                        onKeyUp={(e) => loginOnEnter(e)}
                        autoFocus={true}
                        onChange={e => handleChange(e)} />
                     <label className="auth__formLabel" for="email">Enter your e-mail</label>
                  </div>

                  <div className="auth__formField">
                     <input className={errorMessage ? "auth__formInput auth__formInput--error" : "auth__formInput" }
                        type={showPassword ? "text" : "password"}
                        id="password"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={e => handleChange(e)}
                        onKeyUp={(e) => loginOnEnter(e)}
                        autoFocus={isPasswordFocus}
                        ref={passwordEl}
                     />
                     <label className="auth__formLabel" for="password">Password</label>

                     <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        style={{width: 'min-content', position: 'absolute', right: '10px', top: '15px'}}
                     >
                        {showPassword ? <VisibilityOffIcon className="text-numberGray" /> : <VisibilityIcon className="text-numberGray" />}
                     </IconButton>
                  </div>

                  <div className="auth__error">{errorMessage}</div>

                  <div className="auth__formPassRecovery">
                     <div>Forgot your password? <span className="auth__formPassRecoveryLink">Click here</span></div>
                  </div>

                  <div className="auth__buttonsWrapper">
                     <div className="auth__buttons">
                        <div className="auth__btn auth__btn--pink" onClick={logIn}>
                           log in
                        </div>
                        <div className="auth__btn auth__btn--plain" onClick={() => props.openPopup({ currentPopup: 'SignUp' })}>
                           sign up
                        </div>
                     </div>
                     <div className="auth__socialText">Or you can join with</div>
                     <GoogleAppleAuth setIsGoogleAuthLoading={(isLoading) => setIsGoogleAuthLoading(isLoading)} />

                     {/* <div className="auth__socials">
                        <div className="auth__social">
                           <img src={google} alt="" />
                        </div>
                        <div className="auth__social">
                           <img src={facebook} alt="" />
                        </div>
                     </div> */}
                  </div>
               </form>
            </div>
         </div>
      </div>
   )
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(LogIn);