import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { closePopup } from "../../../actions/popup";
import locationIcon from "../../../img/ico/location.svg";
import "./location.scss";
import closeIcon from "../../../img/ico/close.svg";
import { PlainButton, OutlinedButton } from "../../buttons/buttons";
// import AddressField from '../../addressField/addressField';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';


const mapDispatchToProps = (dispatch) => ({
  closePopup: () => {
    return dispatch(closePopup());
  },
});

const Location = (props) => {
  const { closePopup, info } = props;
  const [cityList, setCityList] = useState([{ name: 'Lviv', coords: { lat: 49.842957, lng: 24.031111 } }, { name: 'Kyiv', coords: { lat: 50.450001, lng: 30.523333 } }])
  const [location, setLocation] = useState('');
  const [city, setCity] = useState();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(pos => {
      const { latitude, longitude } = pos.coords;
      console.log(latitude, longitude)
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
      fetch(url).then(res => res.json()).then(data => setCity(data.address))
    })
  }, [])

  useEffect(() => {
    console.log('address:', city)

    // if(!city) {
    //   return
    // }

    // cityList.find((x, i) => {
    //   if(x.name.toLowerCase().includes(city.toLowerCase())) {
    //     setLocation(cityList[i])
    //   }
    // })

  }, [city])

  const onConfirm = () => {
    closePopup();
    info.onConfirm();
  };


  const onChangeAutocomplete = (value) => {
    if (value) {
      setLocation(value.name)
    } else {
      setLocation()
    }
  }


  return (

    <section className="locationPopup">
      <div className="popup__closeBtn">
        <img src={closeIcon} onClick={props.close} alt="" />
      </div>

      <>
        <div className="locationPopup__img">
          <img src={locationIcon} className="m-auto" />
        </div>
        <p className="popupTitle">Choose your city</p>


        <div className="locationPopup__content">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={cityList}
            size="medium"
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => onChangeAutocomplete(value)}
            renderInput={(params) => <TextField variant="standard" {...params} label="City" />}
          />
          <div className="locationPopup__btn">
            <PlainButton onClick={() => onConfirm()} text="Confirm" />
          </div>
        </div>
      </>
    </section>
  );
};

export default connect(null, mapDispatchToProps)(Location);
