import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { baseURL, deleteUserPhoto } from '../../service';
import WalkProfile from '../walkProfile/walkProfile';
import { openPopup } from '../../actions/popup';
import {updateUser} from '../../service';
import AddressField from '../../components/addressField/addressField';
import { PlainButton } from '../buttons/buttons';

// styles 
import './userProfile.scss';

// icons 
import addBtn from '../../img/ico/addBtn.svg';
import defaultAvatar from '../../img/ico/userColored.svg';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LanguageIcon from '@mui/icons-material/Language';
import { currentUser } from '../../actions/currentUser';
import { setSnackbar } from '../../actions/snackbar';

const mapStateToProps = state => ({
   ...state.currentUser
});


const mapDispatchToProps = dispatch => ({
   openPopup: (payload) => {
      return dispatch(openPopup(payload));
   },
   currentUser: (payload) => {
      return dispatch(currentUser(payload));
   },
   setSnackbar: (payload) => {
      return dispatch(setSnackbar(payload));
    },
})

const UserProfile = (props) => {

   const {user, currentUser, openPopup, setSnackbar} = props;
   const [openInfo, setOpenInfo] = React.useState(true); 
   
   const Info = () => {

      const [updatedUser, setUpdatedUser] = React.useState(user);
      const [addressError, setAddressError] = useState("");

      const handleChange = (e) => {
         setUpdatedUser({
            ...updatedUser,
            [e.target.name]: e.target.value
         })
      }

      const onUserUpdate = async ()  =>  {

         if(user.organization && !updatedUser.city || !updatedUser.country || !updatedUser.lat && !user.location.coordinates || !updatedUser.lng && !user.location.coordinates ) {
            setAddressError("please select your address from suggestions");
            return
           }

         try {
            const res = await updateUser(updatedUser);

            if(res.data.ok) {
               currentUser({user: updatedUser});
               setSnackbar({ snackbarOpen: true, snackbarType: "success", snackbarMessage: "Your profile was updated !" })
            }
            
         } catch(e) {
            console.log(e);
            setSnackbar({ snackbarOpen: true, snackbarType: "error", snackbarMessage: "Something went wrong" })
         }
      }

      const onAddressSet = (isFullAddres, addressAutocomplete) => {

         if(isFullAddres) {
           const {address, city, country, lat, lng} = addressAutocomplete;
           setUpdatedUser({...updatedUser, address, city, country, lat, lng })
     
         } else {
            setUpdatedUser({...updatedUser, address: addressAutocomplete, city: '', country: '', lat: '', lng: ''})
         }
     
         if(addressError) {
                 setAddressError("");
         }
       }


       const onDeleteUserPhoto = async () => {
         if(!user.image) return;
         try {
            await deleteUserPhoto();
            setUpdatedUser({...updatedUser, image: ''})
            currentUser({ user: {...updatedUser, image: ''} });
            setSnackbar({ snackbarOpen: true, snackbarType: "success", snackbarMessage: "Avatar deleted successfully" })
         } catch(e) {
            setSnackbar({ snackbarOpen: true, snackbarType: "error", snackbarMessage: "Something went wrong" })
         }
      }

      
      return (
         <div className="userProfile__info active fadeIn">

         <div className="userProfile__avatarSection">
         <div className="userProfile__avatarWrapper">
            <img src={user.image? `${baseURL}uploads/users/${user.image}` : defaultAvatar} alt="user avatar"/>
         </div>
         <div className="userProfile__avatarButtons">
            <div className='userProfile__avatarUploadBtn'>
            <PlainButton onClick={() => openPopup({ currentPopup: 'UploadImage', info: {userId: user._id} })} text="Upload new"/>
            </div>
            <div className='userProfile__avatarDeleteBtn'>
               <DeleteOutlinedIcon  onClick={() => onDeleteUserPhoto()}/>
            </div>
         </div>


            </div>

            <div className="userProfile__userName">{user.organization ? user.organizationName : `${user.name} ${user.lastName}`}</div>
            <div className="userProfile__userPosition">{user.organization ? "Dog Owner" : "Walker"}</div>

            <div className="userProfile__form">
                  {user.organization ?
                  <>
                     <div className='userProfile__inputWrapper'>
                        <input className="userProfile__formInput" type="text" name="organizationName" defaultValue={user.organizationName} id="userName" onChange={e => handleChange(e)} />
                        <label className="userProfile__formLabel" for="userName">organization name</label>
                     </div>
                     
                     <div className='userProfile__inputWrapper'>
                        <input className="userProfile__formInput" type="email" name="email" id="userEmail" defaultValue={user.email} onChange={e => handleChange(e)}/>
                        <label className="userProfile__formLabel" for="userEmail">E-mail</label>
                     </div>

                     <div className='userProfile__inputWrapper'>
                        <AddressField value={updatedUser.address} onAddressSet={onAddressSet} setAddressError={setAddressError} addressError={addressError}/>
                        <label className="userProfile__formLabel" for="address">address</label>
                     </div>

                     <div className='userProfile__inputWrapper'>
                        <input className="userProfile__formInput" type="phone" name="phone" id="userphone" defaultValue={user.phone} onChange={e => handleChange(e)}/>
                        <label className="userProfile__formLabel" for="userphone">phone</label>
                     </div>

                     <div className='userProfile__socialsWrapper'>
                        <div className='userProfile__inputWrapper'>
                           <input className="userProfile__formSocialInput" type="website" name="website" id="userwebsite" defaultValue={user.social?.wb} onChange={e => setUpdatedUser({...updatedUser, social: { wb: e.target.value }})}/>
                           <label className="userProfile__formLabel" for="userwebsite">website</label>
                           <LanguageIcon />
                        </div>

                        <div className='userProfile__inputWrapper'>
                           <input className="userProfile__formSocialInput" type="facebook" name="facebook" id="userfacebook" defaultValue={user.social?.fb} onChange={e => setUpdatedUser({...updatedUser, social: { fb: e.target.value }})}/>
                           <label className="userProfile__formLabel" for="userfacebook">facebook</label>
                           <FacebookIcon />
                        </div>

                        <div className='userProfile__inputWrapper'>
                           <input className="userProfile__formSocialInput" type="instagram" name="instagram" id="userinstagram" defaultValue={user.social?.in} onChange={e => setUpdatedUser({...updatedUser, social: { in: e.target.value }})}/>
                           <label className="userProfile__formLabel" for="userinstagram">instagram</label>
                           <InstagramIcon />
                        </div>
                     </div>
                  
                     <div className='userProfile__inputWrapper'>
                        <textarea
                        rows="4"
                        className="userProfile__formInput"
                        id="description"
                        name="description"
                        value={updatedUser.description}
                        placeholder="About your organization"
                           onChange={(e) => setUpdatedUser({...updatedUser, description: e.target.value})}
                        />       
                        <label className="userProfile__formLabel" for="description">description</label>
                     </div>

                  </>
               :
               <>
                  {/* <div className="userProfile__formInnerWrapper"> */}
                  <div className='userProfile__inputWrapper'>
                     <input className="userProfile__formInput" type="text" name="name" defaultValue={user.name} id="userName" onChange={e => handleChange(e)} />
                     <label className="userProfile__formLabel" for="userName">name</label>
                  </div>
                  <div className='userProfile__inputWrapper'>
                     <input className="userProfile__formInput" type="text" name="lastName" id="userLastName" defaultValue={user.lastName}  onChange={e => handleChange(e)} />
                     <label className="userProfile__formLabel" for="userLastName">last name</label>
                  {/* </div> */}
                  </div>
                  <div className="userProfile__formInnerWrapper">
                     <div className='userProfile__inputWrapper'>
                        <input className="userProfile__formInput" type="email" name="email" id="userEmail" defaultValue={user.email} onChange={e => handleChange(e)}/>
                        <label className="userProfile__formLabel" for="userEmail">E-mail</label>
                     </div>
                  </div>
               </>
               }

            </div>
                  <div className="userProfile__saveBtnWrapper">
                     {/* <button type="submit" className="userProfile__saveBtn" disabled={user === updatedUser} onClick={onUserUpdate}>Save Changes</button> */}
                     <PlainButton onClick={onUserUpdate} text="Save Changes" disabled={user === updatedUser}/>
                  </div>
         </div>
      )
   }

   const Cards = (props) => {
      return (
         <div className="userProfile__infoCard">
            <div className="userProfile__cardTitle">Saved cards</div>
            <div className="userProfile__cards">

               <div className="userProfile__card">
                  <div className="userProfile__cardNumLogo">
                     <div className="userProfile__cardNum">**** **** 5687 4545</div>
                     <div><img src="img/mastercard.png" alt="" /></div>
                  </div>
                  <div className="userProfile__cardDateWrapper">
                     <div className="userProfile__cardDateLabel">Expiry date</div>
                     <div className="userProfile__cardDate">10/20</div>
                  </div>
               </div>

               <div className="userProfile__addCard" onClick={openPopup}>
                  <div className="userProfile__addCardText">
                     <img src={addBtn} alt="" />
                     <div>Add New</div>
                  </div>
               </div>
            </div>
         </div>
      )
   }

   const Walks = () => {

      const statuses = ['all','in progress', 'booked', 'past', 'canceled'];
      const [filterStatus, setFilterStatus] = useState('all'); 

      return(
         <div className='userProfile__walksSection fadeIn'>
            <div className='userProfile__title'>My walks</div>
            <div className='userProfile__walksFilter'>
               {statuses.map(x => {
                  return (
                     <div onClick={() => setFilterStatus(x)} className={filterStatus === x ? 'userProfile__walksFilterItem--active': 'userProfile__walksFilterItem'}>
                  {x}
               </div>
                  )
               })}
            </div>
            <div className='userProfile__walks'>
               <WalkProfile walk={{status: 'booked'}}/>
            </div>

         </div>
      )
   }

   const openInfoSection = () => {
      setOpenInfo(true)
   }

   const openCardsSection = () => {
      setOpenInfo(false)
   }

   const logOut = () => {
      currentUser({user: {}});
      localStorage.removeItem("accessToken");
   }
   
   return (
      <div>
         <div className="userProfile__menuMobile">
               <div className={openInfo ? "userProfile__menuMobileItem active" : "userProfile__menuMobileItem"} onClick={openInfoSection}>General info</div>
               <div className={openInfo ? "userProfile__menuMobileItem " : "userProfile__menuMobileItem active"} onClick={openCardsSection}>My Walks</div>
               </div>
         <section className="userProfile">

            <div className="userProfile__menu">
               <div className="userProfile__menuTitle">User Profile</div>
               <div className={openInfo ? "userProfile__menuItem active" : "userProfile__menuItem "} onClick={openInfoSection}>user info</div>
               <div className={openInfo ? "userProfile__menuItem " : "userProfile__menuItem active"} onClick={openCardsSection}>My walks</div>
               <div onClick={logOut}  className='userProfile__logOutBtnWrapper'>
                  <div className='userProfile__logOutBtn'>Log out</div>
               </div>
            </div>


            <div className={openInfo ? "userProfile__tabIndicator" : "userProfile__tabIndicator active"}></div>

            {openInfo
               ? <Info />
               // : <Cards openPopup = {() => openPopup({ currentPopup: 'AddCard' })}/>
               : <Walks />
            }

         </section>
         <div className='userProfile__logOutBtnWrapperMobile' onClick={logOut}>
               <ExitToAppRoundedIcon /> 
               <span>
                  Log out
               </span>
            </div>
      </div>
   )
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(UserProfile);