import {
   SET_WALKS,
   ADD_WALK,
   SET_UPCOMING_WALKS
} from './index';



export const setUpcomingWalks = (payload) => {
   const { upcomingWalks } = payload;
   return {
       type: SET_UPCOMING_WALKS,
       upcomingWalks
   }
}

export const setWalks = (payload) => {
   const { walks } = payload;
   return {
       type: SET_WALKS,
       walks
   }
}

export const addWalk = (payload) => {
   const { walk } = payload;
   return {
       type: ADD_WALK,
       walk
   }
}