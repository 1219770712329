import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import './uploadImage.scss';
import { addUserPhoto, getUser } from '../../../service';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../cropImage';

// img 
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import addPhoto from '../../../img/ico/add_photo.svg';
import closeIcon from '../../../img/ico/close.svg';

// actions 
import { currentUser } from '../../../actions/currentUser';
import { closePopup } from '../../../actions/popup';
import { setSnackbar } from '../../../actions/snackbar';


const mapDispatchToProps = dispatch => ({
   closePopup: () => {
      return dispatch(closePopup());
   },
   currentUser: (payload) => {
      return dispatch(currentUser(payload));
   },
   setSnackbar: (payload) => {
      return dispatch(setSnackbar(payload));
   },
})

const UploadImage = (props) => {

   const {
      info,
      closePopup,
      currentUser,
      setSnackbar
   } = props;

   const [image, setImage] = useState();
   const [zoom, setZoom] = useState(1)
   const [crop, setCrop] = React.useState({ x: 0, y: 0 })
   const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null)

   const onImageChange = (e) => {
      setImage(URL.createObjectURL(e.target.files[0]));
   }

   // for cropping image

   const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels)
   }, [])

   const uploadImage = async (croppedImage) => {
      try {
         const formData = new FormData();
         let blob = await fetch(croppedImage).then(r => r.blob());
         formData.append("file", blob, `${info.userId}.jpg`);
         let res = await addUserPhoto(formData);
         console.log('RES', res)

         setSnackbar({ snackbarOpen: true, snackbarType: "success", snackbarMessage: "Your photo updated successfully" })

         closePopup()

         const resp = await getUser().catch(console.log);
         currentUser({ user: resp.data.user });

      } catch (e) {
         console.error(e)
      }
   }

   const showCroppedImage = useCallback(async () => {
      try {
         const croppedImage = await getCroppedImg(
            image,
            croppedAreaPixels
         )
         uploadImage(croppedImage);
      } catch (e) {
         console.error(e)
      }

   }, [croppedAreaPixels])




   const onPhotoDelete = () => {
      setImage()
   }

   return (
      <>

         <div className="uploadImagePopup">
         <div className="popup__closeBtn">
               <img src={closeIcon} onClick={props.close} alt="" />
            </div>
            <div className="popupTitle">Upload photo</div>
            <div className="imageUpLoader">

               <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  aspect={1 / 1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
               />

               {image
                  ? null
                  : <div className="imageUpLoader__clickableArea">
                     <input
                        type="file"
                        name="file"
                        onChange={onImageChange}
                        accept="image/x-png,image/gif,image/jpeg"
                        className="imageUpLoader__input" />
                     <div className="imageUpLoader__selectBtn">
                        <div className="imageUpLoader__customBtnWrapper">
                           <img src={addPhoto} /> <span>upload photo</span>
                        </div>
                     </div>
                  </div>
               }

               <div className="imageUpLoader__deletePhoto" onClick={onPhotoDelete}><span>delete </span><DeleteForeverIcon fontSize="small" /></div>
            </div>
            <div className="imageUpLoader__buttons">
               <div className="imageUpLoader__uploadBtn" onClick={showCroppedImage}>Upload</div>
            </div>
         </div>
      </>
   )
}

export default connect(
   null,
   mapDispatchToProps
)(UploadImage);