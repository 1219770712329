import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

//images 
import logo from '../../img/logo.svg';
import userIcon from '../../img/ico/user.svg';

// styles
import './header.scss'

import {
   openPopup
} from '../../actions/popup';

import {
   currentUser
} from '../../actions/currentUser';


const mapStateToProps = state => ({
   ...state.currentUser,
   ...state.popups
});

const mapDispatchToProps = dispatch => ({
   openPopup: (payload) => {
      return dispatch(openPopup(payload));
   },
   currentUser: (payload) => {
      return dispatch(currentUser(payload));
   }
})

const Header = (props) => {

   const { t, user, showFinishBtn } = props;
   const [isLoggedIn, setIsLoggedIn] = React.useState();
   const [isHomePage, setIsHomePage] = React.useState(true);
   const history = useHistory();

   useEffect(() => {

   const currentUser = Object.keys(props.user).length;
      if (currentUser === 0) {
         setIsLoggedIn(false);
         history.push("/");

      } else {
         setIsLoggedIn(true);
      }

      history.listen( location =>  {
         if(location.pathname === '/') {
            setIsHomePage(true)
         } else {
            setIsHomePage(false)
         }
      });
        
   }, [props.user]);

   const logOut = () => {
      props.currentUser({user: {}});
      localStorage.removeItem("accessToken");
   }

   const RegularMenu = () => {
      return (
         <header className="menu__wrapper menuRegular__wrapper">
            <div className="menu__logoTablet">
                     <Link to="/">
                        <img src={logo} />
                     </Link>
                  </div>

            <div className="menu">
                  <div className="menu__logo">
                     <Link to="/">
                        <img src={logo} />
                     </Link>
                  </div>

                  {isHomePage 
                     ? 
                     <div className="menu__items">
                        <Link to="/">
                           <div className="menu__item menu__item_active">{t("mainMenu.mainPage")}</div>
                        </Link>
                        <a href="#aboutUs">
                           <div className="menu__item">{t("mainMenu.aboutUs")}</div>
                        </a>
                        <a href="#app">
                           <div className="menu__item">{t("mainMenu.app")}</div>
                        </a>
                     </div>
                  : null }
            
               <div className="menu__buttons">
                  <div
                     className="menu__btn menu__logIn"
                     onClick={() => props.openPopup({ currentPopup: 'LogIn' })}
                  >
                     {t("mainMenu.logIn")}
                  </div>
                  <div
                     className="menu__btn menu__signUp"
                     onClick={() => props.openPopup({ currentPopup: 'SignUp' })}
                  >
                     {t("mainMenu.signUp")}

                  </div>
               </div>
            </div>
         </header>
      )
   }

   const LoggedInMenu = () => {
      return (
        <header className="menuLogged__wrapper">
            <div className="menu__logoTablet">
               <Link to="/" style={{ margin: isLoggedIn ? '0 auto' : 'none' }}>
                  <img className="menu__logoTabletImg" src={logo} />
               </Link>


               {!isLoggedIn && (showFinishBtn ? (
                  <div className="menu__buttonsMobile">
                  <div className="menu__userName" onClick={() => props.openPopup({currentPopup: 'GoogleContinue'})}>
                     <span>Finish registration</span>
                  </div>
               </div>
               ) : (
                  <div className="menu__buttonsMobile">
                     <div className="menu__btn menu__logIn" onClick={() => props.openPopup({ currentPopup: "LogIn" })}>
                        {t("mainMenu.logIn")}
                     </div>
                     <div className="menu__btn menu__signUp" onClick={() => props.openPopup({ currentPopup: "SignUp" })}>
                        {t("mainMenu.signUp")}
                     </div>
                  </div>
               ))}
            </div>

          <div className="menu">
            <div className="menu__logo">
              <Link to="/">
                <img src={logo} />
              </Link>
            </div>
            <div className="menu__items">
              <NavLink to="/search" activeClassName="menu__item_active">
                <div className="menu__item">search</div>
              </NavLink>
              {(!isLoggedIn || (isLoggedIn && user.organization)) &&
                  <Tooltip title={!isLoggedIn ? "Login to access" : ""} placement="top">
                     <div>
                     <NavLink
                        to="/dogs"
                        activeClassName="menu__item_active"
                        className={!isLoggedIn || !user.organization ? "menu__item--disabled" : 'false'}
                        >
                        <div className="menu__item">my dogs & walks</div>
                     </NavLink>
                        </div>
                  </Tooltip>
               }
              <Tooltip title={!isLoggedIn && "Login to access"} placement="top">
              <div>
                <NavLink
                  to="profile"
                  activeClassName="menu__item_active"
                  className={!isLoggedIn ? "menu__item--disabled" : 'false'}
                >
                  <div className="menu__item">profile</div>
                </NavLink>
               </div>
              </Tooltip>
            </div>

            {isLoggedIn ? (
              <div className="menu__buttons">
                <NavLink to="profile" activeClassName="menu__userName--active">
                  <div className="menu__userName">
                    <img src={userIcon} alt="user icon" />
                    <span>{props.user.organization ? props.user.organizationName || props.user.email : props.user.name}</span>
                  </div>
                </NavLink>
              </div>
            ) : showFinishBtn  ?  (
               <div className="menu__buttons">
                  <div className="menu__userName" onClick={() => props.openPopup({currentPopup: 'GoogleContinue'})}>
                     <span>Finish registration</span>
                  </div>
               </div>
            )
            : (
              <div className="menu__buttons">
                <div className="menu__btn menu__logIn" onClick={() => props.openPopup({currentPopup: "LogIn"})}>
                  {t("mainMenu.logIn")}
                </div>
                <div className="menu__btn menu__signUp" onClick={() => props.openPopup({currentPopup: "SignUp"})}>
                  {t("mainMenu.signUp")}
                </div>
              </div>
            )}
          </div>
        </header>
      );
   }


   return (

      <div>
         <LoggedInMenu />
         {/* {isLoggedIn
            ? <LoggedInMenu />
            : <RegularMenu />
         } */}

      </div>
   )
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(withTranslation()(Header));