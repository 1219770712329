import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import './googleContinue.scss';
import '../logIn/signUpLogIn.scss';
import AddressField from '../../addressField/addressField';
import { PlainButton, OutlinedButton } from "../../buttons/buttons";
import { closePopup } from "../../../actions/popup";
import { currentUser } from '../../../actions/currentUser';
import { googleAuthContinue } from '../../../service';

// icons 
import closeIcon from "../../../img/ico/close.svg";

const mapDispatchToProps = (dispatch) => ({
    closePopup: () => {
        return dispatch(closePopup());
    },
    currentUser: (payload) => {
        return dispatch(currentUser(payload));
     },
});

const GoogleContinue = (props) => {
    const { closePopup, currentUser } = props;
    const [user, setUser] = useState({
        organizationName: '',
        address: '',
        country: '',
        city: '',
        lat: '',
        lng: '',
        website: '',
        organization: false,
    });

    const [organizationNameError, setOrganizationNameError] = useState("");
    const [addressError, setAddressError] = useState("");

    const handleChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'oranizationName' && organizationNameError) {
            setOrganizationNameError("");
        }
        if (e.target.name === 'address' && addressError) {
            setAddressError("");
        }
    }

    // validation
    const validate = () => {
        let organizationNameError = "";
        let addressError = "";

        if (!user.organizationName) {
            organizationNameError = "organization name can not be blank";
        }

       if(!user.city || !user.country || !user.lat || !user.lng) {
            addressError = "invalid address";
        }

        if (organizationNameError || addressError) {
            setOrganizationNameError(organizationNameError);
            setAddressError(addressError)
            return false;
        }

        return true;
    }


    const onContinue = async (e) => {
        e.preventDefault();
        const isValid = user.organization ? validate() : true;

        if (isValid) {
            setOrganizationNameError("");
            setAddressError("")

            try {
                const res = await googleAuthContinue(user.organization ? user : {organization: user.organization});
                console.log('res.data', res.data);
                currentUser({ user: res.data.user });
                closePopup();

            } catch (e) {
                console.log('e', e)
            }
        }
    }

    const onAddressSet = (isFullAddres, addressAutocomplete) => {
        if(isFullAddres) {
          const {address, city, country, lat, lng} = addressAutocomplete;
          setUser({...user, address, city, country, lat, lng })
    
        } else {
          setUser({...user, address: addressAutocomplete, city: '', country: '', lat: '', lng: ''})
        }
    
        if(addressError) {
                setAddressError("");
        }
      }

    return (
        <section className="googleContinuePopup popup__content">

            <div className="popupTitle">Complete registration</div>
            <div className="popupSubtitle">Select your role</div>

            <div className="search__switcher">
                <input
                    type="radio"
                    name="authRoleSwitcher"
                    id="walker"
                    className="search__switcherInput search__switcherInput--dogs"
                    checked={!user.organization}
                    onChange={() => setUser({ ...user, organization: false })}
                />
                <label for="walker" className="search__switcherLabel">
                    Walker
                </label>

                <input
                    type="radio"
                    name="authRoleSwitcher"
                    id="shelter"
                    className="search__switcherInput search__switcherInput--shelters"
                    checked={user.organization}
                    onChange={() => setUser({ ...user, organization: true })}
                />
                <label for="shelter" className="search__switcherLabel">
                    Shelter
                </label>

                <span className="search__switcherToggle"></span>
            </div>

            <div className="popup__closeBtn">
                <img src={closeIcon} onClick={props.close} alt="" />
            </div>


            {user.organization &&
                <>
                    <div className="auth__formField">
                        <input
                            className={organizationNameError ? "auth__formInput auth__formInput--error" : "auth__formInput"}
                            type="text"
                            id="organizationName"
                            placeholder="Organization name"
                            name="organizationName"
                            value={user["organizationName"]}
                            onChange={(e) => handleChange(e)}
                        />
                        <label className="auth__formLabel" for="organizationName">
                            Organization name
                        </label>
                    </div>

                    <div className="auth__formField">
                        <input
                            className="auth__formInput"
                            type="text"
                            id="website"
                            placeholder="Website"
                            name="website"
                            value={user["website"]}
                            onChange={(e) => handleChange(e)}
                        />
                        <label className="auth__formLabel" for="website">
                            Website
                        </label>
                    </div>

                    <AddressField value={user.address} onAddressSet={onAddressSet} setAddressError={setAddressError} addressError={addressError}/>

                </>
            }

            <div className="googleContinuePopup__btn">
                <PlainButton onClick={(e) => onContinue(e)} text="Complete registration" />
            </div>
        </section>
    );
};


export default connect(null, mapDispatchToProps)(GoogleContinue);
