import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './timeSelection.scss';
import { FormControl, Select, Autocomplete, TextField, InputLabel, MenuItem } from '@mui/material';
import moment from 'moment';
import { addDate, deleteAllDates } from '../../actions/selectedDates';

const mapDispatchToProps = dispatch => ({
    addDate: (payload) => {
        return dispatch(addDate(payload));
    },
    deleteAllDates: () => {
        return dispatch(deleteAllDates());
    }
})

const TimeSelection = (props) => {

    const { dateInfo, addDate, deleteAllDates } = props;
    const disabledRanges = dateInfo.disabledHours;
    const [selectedTime, setSelectedTime] = useState('08:00'); // Starting from 8 AM
    const [availableTimes, setAvailableTimes] = useState([]);
    const [interval, setInterval] = useState(45);
    const [isEnoughTime, setIsEnoughTime] = useState(true);


    // Define the disabled time ranges

    const isAvailableDate = () => {
        //set available hours
        const sortedDisabledHours = disabledRanges.sort((a, b) => a.from.hour - b.from.hour);

        var isEnoughTime = false;
        for (let i = 0; i < sortedDisabledHours.length - 1; i++) {
            const durationInMinutes = interval;

            const endTimeOfDisabledInterval = moment({ hour: sortedDisabledHours[i].to.hour, minute: sortedDisabledHours[i].to.minutes });
            const startTimeOfNextDisabledInterval = moment({ hour: sortedDisabledHours[i + 1].from.hour, minute: sortedDisabledHours[i + 1].from.minutes });

            const possibleEndOfWalkingTime = moment(endTimeOfDisabledInterval, 'HH:mm').add(durationInMinutes, 'minutes');
            isEnoughTime = possibleEndOfWalkingTime.isSameOrBefore(startTimeOfNextDisabledInterval);
            if (isEnoughTime) {
                break;
            }
        }

        setIsEnoughTime(isEnoughTime)
        return isEnoughTime;
    }

    const isTimeDisabled = (time) => {
        for (const range of disabledRanges) {
            const { from, to } = range;
            const { hour: startHour, minutes: startMinutes } = from;
            const { hour: endHour, minutes: endMinutes } = to;
            const [selectedHour, selectedMinutes] = time.split(':').map(Number);

            const startTime = new Date();
            startTime.setHours(startHour, startMinutes, 0);

            const endTime = new Date();
            endTime.setHours(endHour, endMinutes, 0);

            const selectedTime = new Date();
            selectedTime.setHours(selectedHour, selectedMinutes, 0);

            if (selectedTime >= startTime && selectedTime < endTime) {
                return true;
            }
        }

        return false;
    }

    const handleTimeChange = (event) => {
        const newTime = event.target.value;
        if (!isTimeDisabled(newTime)) {
            setSelectedTime(newTime);
        }
    };

    const setDates = () => {
        deleteAllDates()

        const dateFrom = new Date(dateInfo.date);
        let formattedTimeFrom = moment(selectedTime, 'h:m');
        dateFrom.setHours(formattedTimeFrom.hour(), formattedTimeFrom.minute(), 0);

        const dateTo = new Date(dateInfo.date);
        let formattedTimeTo = moment(formattedTimeFrom).add(interval, 'minutes');
        dateTo.setHours(formattedTimeTo.hour(), formattedTimeTo.minute(), 0);

        const selectedInterval = [dateFrom, dateTo]

        addDate({ date: selectedInterval });
    }

    useEffect(() => {
        isAvailableDate()
        setDates()
    }, [interval])

    useEffect(() => {
        setDates()
    }, [selectedTime, dateInfo.date])

    useEffect(() => {
        if (!isEnoughTime) {
            deleteAllDates()
        }
    }, [isEnoughTime])

    useEffect(() => {
        // Calculate available times by generating all possible times
        const times = [];
        for (let hour = 8; hour < 22; hour++) {
            for (let minute = 0; minute < 60; minute += 5) { // Adjusted to 5-minute intervals
                const timeString = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
                times.push(timeString);
            }
        }

        // Filter out times that are disabled or do not have enough time for the interval
        const filteredTimes = times.filter((time, index) => {
            if (isTimeDisabled(time)) return false;
            if (index + interval / 5 > times.length) return false;
            for (let i = 1; i < interval / 5; i++) {
                if (isTimeDisabled(times[index + i])) return false;
            }
            return true;
        });

        setAvailableTimes(filteredTimes);
        setSelectedTime(filteredTimes[0]);

    }, [interval]);

    return (
        <div className='timeSelection'>
            <div className="profile__confirmationLabel">Choose interval</div>
            <div className='timeSelection__timeBadges'>
                <div className={interval === 30 ? 'timeSelection__timeBadge--active' : 'timeSelection__timeBadge'} onClick={() => setInterval(30)}>30min</div>
                <div className={interval === 45 ? 'timeSelection__timeBadge--active' : 'timeSelection__timeBadge'} onClick={() => setInterval(45)}>45min</div>
                <div className={interval === 60 ? 'timeSelection__timeBadge--active' : 'timeSelection__timeBadge'} onClick={() => setInterval(60)}>60min</div>
                <div className={interval === 75 ? 'timeSelection__timeBadge--active' : 'timeSelection__timeBadge'} onClick={() => setInterval(75)}>1h 15m</div>
            </div>
            {isEnoughTime ?
                <>
                    <div className="profile__confirmationLabel">Select time</div>
                    <Select
                        value={selectedTime}
                        onChange={handleTimeChange}
                        className='timeSelection__input'
                    >
                        {availableTimes.map((time) => (
                            <MenuItem key={time} value={time}>{time}</MenuItem>
                        ))}
                    </Select>
                </>
                :
                <div className="profile__confirmationLabel">There is no free time for such an interval</div>
            }
        </div>

    );
}

export default connect(
    null,
    mapDispatchToProps
)(TimeSelection);
