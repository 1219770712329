import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { createWalk, baseURL } from '../../../service/index';

import { useElements, useStripe } from "@stripe/react-stripe-js";
import { CardNumberElement } from "@stripe/react-stripe-js";
import { CardExpiryElement } from "@stripe/react-stripe-js";
import { CardCvcElement } from "@stripe/react-stripe-js";
import Tooltip from '@mui/material/Tooltip';
import party from "party-js";
// styles
import './confirmChoice.scss';

// image 
import walkerImg from '../../../img/walker_img.jpg';


//icons 
import closeIcon from '../../../img/ico/close.svg';
import plus from '../../../img/ico/add_btn.svg';

import { setSnackbar } from '../../../actions/snackbar';
import { openPopup, closePopup } from '../../../actions/popup';

const mapStateToProps = state => ({
   ...state.selectedDates,
   ...state.walkers,
   ...state.dogs,
   ...state.currentUser
});

const mapDispatchToProps = dispatch => ({
   openPopup: (payload) => {
      return dispatch(openPopup(payload));
   },
   closePopup: () => {
      return dispatch(closePopup());
   },
   setSnackbar: (payload) => {
      return dispatch(setSnackbar(payload));
   }
})

const ConfirmChoice = (props) => {

   const history = useHistory();
   const elements = useElements();

   const [allDogs, setAllDogs] = React.useState(props.dogs);
   const getDogPhoto = (imagePath) => `${baseURL}uploads/dogs/${imagePath}`;

   const [totalPrice, setTotalPrice] = React.useState();
   const [cash, setCash] = React.useState(true);
   const [newCardState, setNewCardState] = React.useState(false);
   const [amountOfChange, setAmountOfChange] = React.useState("");

   const [detailsError, setDetailsError] = React.useState(false);
   const [amountError, setAmountError] = React.useState(false);
   const [dogsError, setDogsError] = React.useState(false);

   const [walk, setWalk] = React.useState({
      walkerId: props.user._id,
      dogId: props.info.dog._id,
      address: "Рудницького, 12",
      detailsLocation: "",
      startNow: true,
      timeBook: {
         date : props.selectedDates[0][0],
         time: {
            from : {
               hour: props.selectedDates[0][0].getHours(),
               minutes: props.selectedDates[0][0].getMinutes()
            },
            to: {
               hour : props.selectedDates[0][1].getHours(),
               minutes: props.selectedDates[0][1].getMinutes()
            }
         }
      }
   })


   useEffect(() => {
      console.log(props);
      
      if (allDogs.length > 0) {
         const updatedDogs = allDogs.map(x => {
            x.checked = false;
            return x;
         });
         updatedDogs[0].checked = true;
         setAllDogs(updatedDogs);
      }

      // calculate the amount of payment 
      let total = 0;
      props.selectedPrices.map(x => total += x[1]);
      setTotalPrice(parseFloat(total.toFixed(1)));


      if(props.info.walk) setWalk(props.info.walk)

   }, []);

   // const onDateFocus = (e) => {
   //    if (e.complete) {
   //       elements.getElement("cardExpiry").focus();
   //    }
   // };

   // const onCvvFocus = (e) => {
   //    if (e.complete) {
   //       elements.getElement("cardCvc").focus();
   //    }
   // };

   // const onDogSelect = (dog) => {

   //    const updatedArray = allDogs.map(x => {
   //       if (x._id === dog._id) {
   //          x.checked = true
   //       } else {  x.checked = false}
   //       return x;
   //    });

   //    setAllDogs(updatedArray);
   // }

   // const openCash = () => {
   //    return (
   //       setCash(true)
   //    )
   // }

   // const openCards = () => {
   //    return (
   //       setCash(false),
   //       setNewCardState(false)
   //    )
   // }

   // const addCard = () => {
   //    return (
   //       setNewCardState(true)
   //    )
   // }

   // const validate = () => {
   //    let detailsError = false;
   //    let amountError = false;
   //    let dogsError = false;


   //    if (walk.detailsLocation.length < 1) {
   //       detailsError = true;
   //    }

   //    if (cash && amountOfChange.length < 1) {
   //       amountError = true;
   //    }

   //    if (allDogs.length < 1 ) {
   //       dogsError = true;
   //    }

   //    if (detailsError || amountError || dogsError ) {
   //       setDetailsError(detailsError);
   //       setAmountError(amountError);
   //       setDogsError(dogsError);
   //       selectClass()
   //       return false;
   //    }
   //    return true;
   // }

   const onConfirm = async () => {
      // const isValid = validate();

      // if (isValid) {

         // const getSelectedDogsId = () => {
         //    const selectedDogs =  allDogs.filter(dog => dog.checked);
         //    selectedDogs.map(dog => walk.dogId = dog._id)
         // }

         // getSelectedDogsId()

         setDetailsError(false);
         setAmountError(false);
         setDogsError(false);

         try {
            const res = await createWalk(walk);

            props.closePopup();
            history.push("/search");
            props.setSnackbar({snackbarOpen: true, snackbarType: "info", snackbarMessage: "The walk is booked !"})

         } catch (e) {
            console.log(e)
         }
         
      // }
   }

   // const CreditCard = (props) => {
   //    return (
   //       <section>

   //          {newCardState ? null :
   //             <div>

   //                <div className="confirm__addCardGroup">
   //                   <div className="confirm__cardLabel">Saved cards</div>
   //                   <div className="confirm__addCard flex" onClick={addCard}>
   //                      <img src={plus} alt="" />
   //                      <div className="confirm__addCardText">Add New</div>
   //                   </div>
   //                </div>
   //                <div className="confirm__cards">
   //                   <div className="confirm__card  confirm__card--active">
   //                      <div className="confirm__cardNumLogo">
   //                         <div className="confirm__cardNum">**** **** 5687 4545</div>
   //                         <div><img src="img/mastercard.png" alt="" /></div>
   //                      </div>
   //                      <div className="confirm__cardDateWrapper">
   //                         <div className="confirm__cardDateLabel">Expiry date</div>
   //                         <div className="confirm__cardDate">10/20</div>
   //                      </div>
   //                   </div>

   //                   <div className="confirm__card">
   //                      <div className="confirm__cardNumLogo">
   //                         <div className="confirm__cardNum">**** **** 5687 4545</div>
   //                         <div><img src="img/mastercard.png" alt="" /></div>
   //                      </div>
   //                      <div className="confirm__cardDateWrapper">
   //                         <div className="confirm__cardDateLabel">Expiry date</div>
   //                         <div className="confirm__cardDate">10/20</div>
   //                      </div>
   //                   </div>

   //                </div>
   //             </div>
   //          }

   //          {newCardState ?
   //             <div className="confirm__addCardInputs">

   //                <CardNumberElement
   //                   className="confirm__addCardInput"
   //                   options={elementOptions}
   //                   onChange={(e) => onDateFocus(e)}
   //                />

   //                <div className="flex">
   //                   <CardExpiryElement
   //                      id="date-element"
   //                      className="confirm__addCardInput"
   //                      options={elementOptions}
   //                      onChange={(e) => onCvvFocus(e)}
   //                   />
   //                   <CardCvcElement
   //                      className="confirm__addCardInput"
   //                      options={elementOptions}
   //                   />
   //                </div>
   //                <div className="confirm__addCardBtn" onClick={openCards}>Add Card</div>
   //             </div> : null}
   //       </section>
   //    )
   // }

   // const selectClass = () => {
   //    if(!dogsError) return;
   //    if(dogsError) {
   //       document.querySelector('.confirm__addDogBtn').classList.add("confirm__addDogBtn--error");
   //       setTimeout(() => {
   //          document.querySelector('.confirm__addDogBtn').classList.remove("confirm__addDogBtn--error");
   //        }, 820);
   //    }
   // }

   // useEffect(() => {
   //    selectClass()
   // }, [dogsError])


   return (
      <section className="confirm__wrapper">
         <div className="popup__content">
            <div className="confirm__closeBtn">
               <img src={closeIcon} onClick={props.close} alt="" />
            </div>

            <div className="confirm__title">Confirm your choice</div>

            <div className="confirm__walkerInfo flex">
               <div className="flex confirm__imageName">
                  <div className="confirm__walkerImg">
                     <img src={props.info.dog.imagePath} />
                  </div>
                  <div className="flexColumn">
                     <div className="confirm__label">Dog</div>
                     <div className="confirm__walkerName">{props.info.dog.name} {props.info.dog.lastName}</div>
                  </div>
               </div>
               <div className="flexColumn">
                  <div className="confirm__label confirm__dateLabel">Date</div>

                  {props.selectedDates.map(x => {
                     return (
                        <div className="confirm__date"><span>{moment(x[0]).format('DD MMM')}</span></div>
                     )
                  })}
               </div>

               <div className="flexColumn">
                  <div className="confirm__label confirm__timeLabel">Time</div>

                  {props.selectedDates.map(x => {
                     return (
                        <div className="confirm__time">
                           <span>{x[0].toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
                           <span> - </span>
                           <span>{x[1].toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
                        </div>
                     )
                  })}
               </div>
            </div>

            <div className="flex confirm__detailsSection">
               <div className="flexColumn confirm__detailsWrapper">
                  <div className="confirm__label">Shelter Location</div>
                  <div className="">{props.info.dog.shelter.address}, {props.info.dog.shelter.city}, {props.info.dog.shelter.country}</div>

                  <div className="confirm__label" style={{marginTop: '10px'}}>Shelter Contacts</div>
                  <div className="">{props.info.dog.shelter.phone}</div>
                  <div>{props.info.dog.shelter.email}</div>

                  {/* <textarea className={detailsError ? "confirm__detailsError" : "confirm__details"} name="details" id="" cols="30" rows="5" value={walk.detailsLocation}
                     placeholder="Type text ..." onChange={(e) => setWalk({ ...walk, detailsLocation: e.target.value })}></textarea> */}
               </div>
               {/* <div className="flexColumn">
                  <div className="confirm__label confirm__chooseDogsLabel">Choose a dog ('s)</div>

                  {allDogs.length > 0
                     ? allDogs.map(x => {
                        return (
                           <div className="confirm__chooseDogsWrapper" onClick={() => onDogSelect(x)}>
                              <div className={x.checked ? "confirm__selectedDog" : "confirm__dogsName"}>
                                 <div className="confirm__dogsImg">
                                    <img src={getDogPhoto(x.imagePath)} alt={"photo of your dog " + x.name} />
                                 </div>
                                 {x.name}
                              </div>
                           </div>
                        )
                     })
                     : <div className="confirm__addDogBtnWrapper">
                        <div className="confirm__addDogBtn" onClick={() => props.openPopup({ currentPopup: 'AddDog', info: {text: "fromConfirmChoice", walker: props.info.dog, walk: walk}})}>add your first dog +</div>
                        {dogsError && <div className="textError">Please add dog</div>}
                     </div>
                  }

               </div> */}
            </div>
                  {/* {props.info.address &&
                        <div className="confirm__addressWrapper"><div className="confirm__label">Address:</div><div className="confirm__addressLabel">{props.info.address}</div></div>
                  } */}


            {/* <div className="confirm__paymentMethodsWrapper">
               <div className="confirm__label">Payment method</div>
               <div className="confirm__paymentMethods">
                  <Tooltip title="currently only cash is available" placement="bottom-start">
                     <div className="confirm__paymentMethodDisable" >Credit card</div>
                  </Tooltip>
                  <div className={cash ? "confirm__paymentMethodBtn confirm__paymentMethodActive" : "confirm__paymentMethodBtn"} onClick={openCash}>Cash</div>
               </div>
            </div> */}
{/* 
            {cash
               ? <input
                  type="text"
                  className={amountError ? "confirm__amountChangeError" : "confirm__amountChange"}
                  placeholder="Amount for change*"
                  onChange={(e) => setAmountOfChange(e.target.value)}
               />
               : <CreditCard />
            } */}

            {/*  when clicked "Add Card" - disable "Pay" button 
                  if user added new card, this card appear in Cards Section and become active, and "Pay" button become active again */}


<button className="confirm__confirmBtn" onClick={(e)=> {onConfirm(); party.sparkles(e.target)}}>Confirm</button>

            {/* {cash
               ? <button className="confirm__confirmBtn" onClick={(e)=> {onConfirm(); party.sparkles(e.target)}}>Confirm</button>
               : <div className="confirm__payWrapper">
                  <div className="flexColumn">
                     <div className="confirm__priceLabel">Price</div>
                     <div className="confirm__priceValue">${totalPrice}</div>
                  </div>
                  <div className="confirm__payBtn">Pay</div>
               </div>} */}

         </div>
      </section>
   )
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(ConfirmChoice)


const elementOptions = {
   style: {
      base: {
         fontSize: "16px",
         color: "#21263a",
         fontWeight: 400,
         iconColor: " rgb(248, 237, 237)",
         "::placeholder": {
            color: "#90929d",
            fontWeight: "300",
         }
      },
      invalid: {
         color: "#e63946",
         iconColor: "#e63946"
      },
      focus: {
         color: "green",
      }
   },
   showIcon: true,
   iconStyle: "solid"
}
