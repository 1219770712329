import React from 'react';
import { connect } from 'react-redux';
import SearchBox from './ searchBox/searchBox';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Button from '@mui/material/Button';

// images
import bannerImage from '../../img/banner.svg';
import bannerTabletImageBg from '../../img/bannerTabletImageBg.svg';
import bannerTabletImage from '../../img/bannerTabletImage.svg';
import aboutUs_1 from '../../img/about_1.svg';
import aboutUs_2 from '../../img/about_2.svg';
import aboutUs_3 from '../../img/about_3.svg';
import phone from '../../img/phone.png';
import buttonsLayer from '../../img/app_buttonsLayer.svg';
import appStore from '../../img/ico/app_store.png';
import googlePlay from '../../img/ico/google_play.png';

import {
  openPopup
} from '../../actions/popup';

// styles
import './homePage.scss'

const mapStateToProps = state => ({
  ...state.currentUser,
  ...state.popups
});

const mapDispatchToProps = dispatch => ({
  openPopup: (payload) => {
     return dispatch(openPopup(payload));
  }
})


function HomePage(props) {
  const { t, openPopup, user } = props;
  const history = useHistory();
  const isLogin = Object.keys(user).length;

   return (
     <div>
       {/* banner */}

       <section className="banner__wrapper">
         <div className="banner">
           <div className="banner__img">
             <img src={bannerImage} />
           </div>

           <div className="banner__title">{t("homePage.title")}</div>
           <div className="selection">
             {(!isLogin || (isLogin && !user.organization)) &&
               <Button variant="text" className="selection__item" onClick={() => history.push("/search")}>
                 <div className="selection__info">
                   {/* <div className='banner__selectionItemTitle'>Take the dog for a walk</div> */}
                   <div className="selection__title">Choose a dog and go for a walk</div>
                   <div className="selection__subtitle">For dog loving people</div>
                 </div>
                 <img className="selection__img" src="./images/bannerOption1.svg" />
               </Button>
             }
             {(!isLogin || (isLogin && user.organization)) &&

               <Button variant="text" className="selection__item" onClick={() => isLogin ? history.push("/dogs") : openPopup({ currentPopup: 'SignUp' })}>
                 <div className="selection__info">
                   <div className="selection__title">Add dogs and specify the time of walks</div>
                   <div className="selection__subtitle">For dog owners</div>
                 </div>
                 <img className="selection__img" src="./images/bannerOption2.svg" />
               </Button>
             }
           </div>
         </div>
       </section>

       {/* about us */}

       <section className="about__wrapper" id="aboutUs">
         <div className="about__ellipseMiddleLeft"></div>
         <div className="about__ellipseMiddleRight"></div>

         <div className="about">
           <div className="about__line"></div>
           <div className="about__title">Abous Us</div>
           <div className="about__container">
             <div className="about__item">
               <div className="about__img">
                 <img src={aboutUs_1} />
               </div>
               <div className="about__itemTitle">Happy dogs</div>
               <div className="about__itemDesc">
                 Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ullam ex laudantium quas neque quam sapiente
                 magni.
               </div>
             </div>

             <div className="about__item">
               <div className="about__img">
                 <img src={aboutUs_2} />
               </div>
               <div className="about__itemTitle">Trusted Dog walker</div>
               <div className="about__itemDesc">
                 Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ullam ex laudantium quas neque quam sapiente
                 magni.
               </div>
             </div>

             <div className="about__item">
               <div className="about__img">
                 <img src={aboutUs_3} />
               </div>
               <div className="about__itemTitle">Convenient app</div>
               <div className="about__itemDesc">
                 Lorem ipsum dolor, sit amet consectetur adipisict consectetur adipisict consectetur adipisict
                 consectetur adipisicing elit. Ullam ex laudantium quas neque quam sapiente magni.
               </div>
             </div>
           </div>
         </div>
       </section>

       {/* download app */}

       <section className="app__wrapper" id="app">
         <div className="app__background"></div>
         <div className="app">
           <div className="app__img">
             <img src={phone} />
           </div>
           <div className="app__info">
             <div className="app__infoTitle">We have a convenient Mobile App!</div>
             <div className="app__infoDesc">
               Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean interdum scelerisque ultrices. Nunc
               rhoncus, enim quis aliquam dapibus, arcu lorem finibus nibh, condimentum felis mauris in ex.
             </div>
             <div className="app__buttons">
               <a href="#">
                 <div className="app__btn app__appStore">
                   <img className="app__btnImg" src={buttonsLayer} />
                   <img src={appStore} alt="app store button" />
                   <div className="app__btnDesc">
                     <div className="app__btnTextAppStore">Available on the</div>
                     <div className="app__btnDescTitle">App Store</div>
                   </div>
                 </div>
               </a>

               <a href="https://www.colorzilla.com/gradient-editor/">
                 <div className="app__btn app__googlePlay">
                   <img className="app__btnImg" src={buttonsLayer} />
                   <img src={googlePlay} alt="app store button" />
                   <div className="app__btnDesc">
                     <div className="app__btnTextGooglePlay">Get it on</div>
                     <div className="app__btnDescTitle">Google Play</div>
                   </div>
                 </div>
               </a>
             </div>
           </div>
         </div>
       </section>
     </div>
   );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(HomePage));