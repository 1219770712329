import React, {useState, useRef, useEffect} from "react";
import DogSize from "../dogSize/dogSize";
import StatusBadge from "../statusBadge/statusBadge";
import './walkShelter.scss';
import {LightButton, PlainButton} from '../buttons/buttons';

// icons
import settingsIcon from "../../img/ico/settingsIcon.svg";
import closeIcon from "../../img/ico/closeIcon.svg";
import rightArrow from "../../img/ico/rightArrow.svg";

const WalkShelter = ({walk}) => {
  // const [moreAction, setMoreAction] = React.useState(false);
  // const moreActionMenu = useRef(null);
  const status = walk.status;


  // TODO: WALK MENU (SHELTER)
  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (moreActionMenu.current && !moreActionMenu.current.contains(event.target)) {
  //       setMoreAction(false);
  //     }
  //   }
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [moreActionMenu]);

  // const openMoreAction = (e) => {
  //   e.stopPropagation();
  //   e.preventDefault();
  //   setMoreAction(!moreAction);
  // };

  // const closeMoreAction = (e) => {
  //   e.stopPropagation();
  //   e.preventDefault();
  //   setMoreAction(false);
  // };

  // let showEditBtn = () => {
  //   let statuses = ["booked"];
  //   return statuses.includes(status);
  // };

  // let showCancelBtn = () => {
  //   let statuses = ["booked"];
  //   return statuses.includes(status);
  // };

  // let showContactSupportBtn = () => {
  //   let statuses = ["booked"];
  //   return statuses.includes(status);
  // };

  // let showOrderAgainBtn = () => {
  //   let statuses = ["past"];
  //   return statuses.includes(status);
  // };

  // const showSettingsIcon = () => {
  //   if (moreAction || status === "canceled" || status === "in progress") {
  //     return;
  //   } else {
  //     return (
  //       <div className="userProfile__walkSettingsBtn" onClick={(e) => openMoreAction(e)}>
  //         <img src={settingsIcon} alt="walk settings" />
  //       </div>
  //     );
  //   }
  // };

  return (
    <div className="walkShelter">
      <StatusBadge status={status} />

      <div className="walkShelter__walker">
        <div className="walkShelter__walkerImg"><img src="https://images.pexels.com/photos/10057618/pexels-photo-10057618.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="walker photo"/></div>
        <div className="walkShelter__walkerInfo">
          <div className="walkShelter__walkerName">Susan Miller</div>
          <div className="walkShelter__walkerTime">
            <div className="flexColumn">
              <div className="label">Date</div>
              <div className="text">12.05</div>
            </div>

            <div className="flexColumn">
              <div className="label">Time</div>
              <div className="text">18:00-20:00</div>
            </div>

            <div className="flexColumn">
              <div className="label">Phone Number</div>
              <div className="text">+380982534222</div>
            </div>
          </div>
        </div>
      </div>
        <div className="walkShelter__dog">
        <div className="walkShelter__dogImg"><img src="https://images.pexels.com/photos/3090875/pexels-photo-3090875.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="walker photo"/></div>

        <div className="walkShelter__dogInfo">
          <div className="walkShelter__dogMainInfo">
            <DogSize size="small" />
          <div className="walkShelter__dogName">Dog Andy</div>
          </div>

          <div className="walkShelter__dogId">#1234gv</div>
        </div>
          
          <div className="walkShelter__dogButtons">

            <LightButton text="Cancel"/>
            <PlainButton text="Pass the dog"/>
          </div>
        </div>
        </div>
  );
};

export default WalkShelter;
