import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';



import { getWalkById, getUserById } from '../../service/index';
import { openPopup } from '../../actions/popup';

const mapDispatchToProps = dispatch => ({
   openPopup: (payload) => {
      return dispatch(openPopup(payload));
   }
})


const Walk = (props) => {

   const [walk, setWalk] = React.useState();
   const [walker, setWalker] = React.useState();

   useEffect(() => {
      const getWalk = async () => {
         try {
            const resWalk = await getWalkById(props.id)
            setWalk(resWalk.data.walk)

            const resWalker = await getUserById(resWalk.data.walk.walkerId);
            setWalker(resWalker.data.walker)

         } catch (error) {
            console.log(error)
         }
      }

      getWalk()

   }, [])

   const history = useHistory();

   const showWalkButton = (status) => {
      switch (status) {
         case 'past':
            return <div className="lastWalk__orderBtn" onClick={() =>  history.push(`/walker_profile/${walker._id}`)}> Reorder</div>
         case 'booked':
            return <div className="lastWalk__orderBtn" onClick={() => props.openPopup({ currentPopup: 'EditWalk', info: walk })}>Edit</div>
         case 'in progress':
            return <div className="lastWalk__orderBtn" onClick={() =>  history.push('/search')}>Show on map</div>

      }
   }


   return (
      <div>

         {walker &&
            <div className="lastWalk__box">
               <div className="lastWalk__walkerImg">
                  <img src={walker.image} alt="last walker image" />
               </div>
               <div className="lastWalk__desc">
                  <div className="lastWalk__walkerName">{walker.name} <div className="lastWalk__walkStatus">{walk.status}</div></div>
                  <div className="lastWalk__info">
                     <div className="lastWalk__infoBox">
                        <div className="lastWalk__label">date</div>
                        <div className="lastWalk__date">{moment(walk.startTime).format('DD.MM')}</div>
                     </div>
                     <div className="lastWalk__infoBox">
                        <div className="lastWalk__label">time</div>
                        <div className="lastWalk__time">{moment.utc(walk.startTime).format("HH:mm")} - {moment.utc(walk.finishTime).format("HH:mm")}</div>
                     </div>
                  </div>
                  {/* <div className="lastWalk__walkDetails">{walk.detailsLocation}</div> */}
               </div>
               {showWalkButton(walk.status)}
            </div>
         }

      </div>
   )
}

export default connect(
   null,
   mapDispatchToProps
)(Walk);