import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { endOfToday, format, isDate, set } from 'date-fns'
import TimeRange from 'react-timeline-range-slider'
import moment from 'moment';

// styles 
import './timeline.scss';

import { addDate, deleteDate, addPrice } from '../../actions/selectedDates';
import { deleteAllDates } from '../../actions/selectedDates';

const mapDispatchToProps = dispatch => ({
   addDate: (payload) => {
      return dispatch(addDate(payload));
   },
   deleteDate: (payload) => {
      return dispatch(deleteDate(payload));
   },
   deleteAllDates:() => {
      return dispatch(deleteAllDates());
   },
   addPrice: (payload) => {
      return dispatch(addPrice(payload));
   }
})

const Timeline = (props) => {
   const {
      selectedDate,
      addDate,
      addPrice,
      deleteAllDates,
      deleteDate,
      rate,
      isAvailable,
      disabledHours
   } = props;
   

      const getTodayAtSpecificHour = (hour = 12, minutes = 60) => {
      return set(props.selectedDate, { hours: hour, minutes: minutes, seconds: 0, milliseconds: 0 })
   }

   const [selectedStart, setSelectedStart] = React.useState(getTodayAtSpecificHour(11, 0));
   const [selectedEnd, setSelectedEnd] = React.useState(getTodayAtSpecificHour(12, 0));
   const [selectedInterval, setSelectedInterval] = React.useState([selectedStart, selectedEnd])
   
   const [startTime, setStartTime] = React.useState(getTodayAtSpecificHour(8, 0))
   const [endTime, setEndTime] = React.useState(getTodayAtSpecificHour(22, 0))
   
   const [disabledIntervals, setDisabledIntervals] = React.useState([]);
   
   const [error, setError] = React.useState(false);
   const [minTimeError, setMinTimeError] = React.useState(false);
   const [availabilityError, setAvailabilityError] = React.useState(false);
   const [availabilityDayError, setAvailabilityDayError] = React.useState(false);
   
   //on date change
      useEffect(() => {
         //default times for each day of timeline
         setStartTime(getTodayAtSpecificHour(8, 0));
         setEndTime(getTodayAtSpecificHour(22, 0));

         const updatedDisabledHours = disabledHours.map(x => ({ start: getTodayAtSpecificHour(x.from.hour, x.from.minutes), end: getTodayAtSpecificHour(x.to.hour, x.to.minutes) }  ));
         setDisabledIntervals(updatedDisabledHours);

         if(!isAvailable) {
            setSelectedStart(getTodayAtSpecificHour(8, 0));
            setSelectedEnd(getTodayAtSpecificHour(8, 0));
            setSelectedInterval([selectedStart, selectedEnd]);
            setAvailabilityDayError(true);

            return;
         }

         //set available hours
         const sortedDisabledHours = disabledHours.sort((a, b) => a.from.hour - b.from.hour);
         for (let i = 0; i < sortedDisabledHours.length - 1; i++) { 
            const durationInMinutes = '45';
            
            const endTimeOfDisabledInterval = moment({ hour: sortedDisabledHours[i].to.hour, minute: sortedDisabledHours[i].to.minutes });
            const startTimeOfNextDisabledInterval = moment({ hour: sortedDisabledHours[i+1].from.hour, minute: sortedDisabledHours[i+1].from.minutes });
            
            const possibleEndOfWalkingTime = moment(endTimeOfDisabledInterval, 'HH:mm').add(durationInMinutes, 'minutes');
            const isEnoughTime = possibleEndOfWalkingTime.isSameOrBefore(startTimeOfNextDisabledInterval);

            if (isEnoughTime) {
               setSelectedStart(getTodayAtSpecificHour(sortedDisabledHours[i].to.hour, sortedDisabledHours[i].to.minutes));
               setSelectedEnd(getTodayAtSpecificHour(moment(possibleEndOfWalkingTime).hour(), moment(possibleEndOfWalkingTime).minutes()));
               setSelectedInterval([selectedStart, selectedEnd]);
               setAvailabilityDayError(false);

               break;
            }
         }
         
      }, [selectedDate]);

   
   useEffect(() => {
      setSelectedInterval([selectedStart, selectedEnd]);
      deleteAllDates();
      setError(false);
      setMinTimeError(false);

   }, [selectedStart]);

   const errorHandler = ({ error }) => {
      setError( error )
   }

   const calculatePrice = (difference) => {
      const quarterPrice = rate / 4;
      const quarterNumber = difference / 15;
      const price = parseFloat((quarterNumber * quarterPrice).toFixed(1));

      return price;
   }

   //on interval change
   const onChangeCallback = selectedInterval => {

      setMinTimeError(false)
      setAvailabilityError(false)

      const difference =  Math.floor((selectedInterval[1].getTime() - selectedInterval[0].getTime()) / 1000 / 60);
      var price = calculatePrice(difference);
      
      if(error) {
         deleteDate({date: selectedInterval[0]});
      }

      if(error && difference > 45) {
         setAvailabilityError(true);
      }
      
      if(difference < 45) {
         setError(true);
         setMinTimeError(true);
         deleteDate({date: selectedInterval[0]});
      } else if (!error) {
         setSelectedInterval(selectedInterval)
         addDate({date: selectedInterval});
         // addPrice({date: selectedInterval[0], price: price});
      }
   }

   const getTimelineClass = () => {
      const unavailableClass  = "timeline disabledDay";
      const availableClass  = "timeline";

      if(availabilityDayError){
         return unavailableClass;
      }  else return availableClass;
   }



   return (
      <div className={error ? "timeline--error" : getTimelineClass()}>
         {availabilityDayError && <div className="timeline__availabilityDayError">This day is unavailable</div> }
         {minTimeError && <div className="timeline__errorText">The minimum walking time is 45 minutes</div> }
         {availabilityError && <div className="timeline__errorText">This time is not available</div> }
         <TimeRange
            error={error}
            ticksNumber={36}
            selectedInterval={selectedInterval}
            timelineInterval={[startTime, endTime]}
            onUpdateCallback={errorHandler}
            onChangeCallback={onChangeCallback}
            disabledIntervals={disabledIntervals}
            step={900000}
            mode={1}
            />
      </div>
   )
}

export default connect(
   null,
   mapDispatchToProps
)(Timeline);