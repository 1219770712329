import React, { useState } from 'react';
import { connect } from 'react-redux';

// styles 
import '../passDog/passDog.scss';

// images 
import dog from '../../../img/walkOver.svg';

// icons 
import closeIcon from '../../../img/ico/close.svg';


import {
   closePopup,
   openPopup
} from '../../../actions/popup';

const mapStateToProps = state => ({
   ...state.popups
});

const mapDispatchToProps = dispatch => ({
   openPopup: (payload) => {
      return dispatch(openPopup(payload));
   },
   closePopup: () => {
      return dispatch(closePopup());
   }
})


const WalkOver = (props) => {
   return (
      <div className="dogsPopup__wrapper">
         <div className="dogsPopup">
            <div className="dogsPopup__closeBtn" onClick={props.close}>
               <img src={closeIcon} alt="" />
            </div>
            <img className="dogsPopup__img" src={dog}></img>
            <div className="dogsPopup__title">The walk is over!</div>
            <div className="dogsPopup__plainText">Leave review about the walk</div>
            <div className="dogsPopup__leaveFeedbackBtn dogsPopup__btn" onClick={() => props.openPopup({ currentPopup: 'Feedback' })}>Leave feedback</div>
         </div>
      </div>
   )
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(WalkOver);