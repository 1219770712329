import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { gapi } from "gapi-script";
import '../popups/logIn/signUpLogIn.scss';

// actions
import { currentUser } from "../../actions/currentUser";
import { setSnackbar } from '../../actions/snackbar';
import { closePopup, openPopup } from '../../actions/popup';

// api
import { googleAuth, getUser } from "../../service";

const mapDispatchToProps = (dispatch) => ({
  currentUser: (payload) => {
    return dispatch(currentUser(payload));
  },
  setSnackbar: (payload) => {
    return dispatch(setSnackbar(payload));
  },
  openPopup: (payload) => {
    return dispatch(openPopup(payload));
  },
  closePopup: () => {
    return dispatch(closePopup());
  }
});

const GoogleAppleAuth = (props) => {
  const { openNotification, currentUser, setIsGoogleAuthLoading, openPopup, closePopup } = props;

  const history = useHistory();
  const clientId = "785565087615-of3kdrqkr8c558o3kk4u1bd1ncqttsqr.apps.googleusercontent.com";

  useEffect(() => {
    gapi.load("client: auth2", () => {
      gapi.auth2.init({ clientId: clientId });
    });
  }, []);

  const responseGoogle = async (tokenId) => {
    console.log('from response google', tokenId)
    if (tokenId) {
      setIsGoogleAuthLoading(true);
      try {
        const res = await googleAuth({ token: tokenId });

        if (res.data.ok) {
          console.log("res", res.data);
          localStorage.setItem("accessToken", res.data.token);

          if (!res.data.user?.finishedRegistration) {

            console.log("res", res.data);
            console.log('open new popup with shelter info')
            openPopup({ currentPopup: 'GoogleContinue' });

          } else {
            try {
              const resUser = await getUser();
              if (resUser.data.user) {
                currentUser({ user: resUser.data.user });
                history.push("/search");
                props.closePopup();
              }
            } catch (e) {
              console.log("e", e);
            }
          }
        }
      } catch (error) {
        console.log(error);
        props.setSnackbar({ snackbarOpen: true, snackbarType: "error", snackbarMessage: "Something went wrong" })

        if (error && error.response?.data) {
          props.setSnackbar({ snackbarOpen: true, snackbarType: "error", snackbarMessage: error.response.data.message })
        }
      }
      setIsGoogleAuthLoading(false);
    }
  };


  const login = useGoogleLogin({
    clientId: clientId,
    flow: 'auth-code',
    scope: 'email',
    onSuccess: async (codeResponse) => {
      console.log('codeResponse', codeResponse);
      responseGoogle(codeResponse.code);
    },
    onError: errorResponse => console.log(errorResponse),
  });


  return (
    <div className="auth__googleBtn" onClick={() => login()}>
      <img src="./images/ico/google.svg" />
      Login with Google
    </div>
  );
};

export default connect(null, mapDispatchToProps)(GoogleAppleAuth);
