import { combineReducers } from 'redux';
import popups from './popup';
import currentUser from './currentUser';
import selectedDates from './selectedDates';
import walkers from './walkers';
import walks from './walks';
import dogs from './dogs';
import snackbar from './snackbar';
import map from './map';

export default combineReducers({
    popups,
    currentUser,
    selectedDates,
    walkers,
    walks,
    dogs,
    snackbar,
    map
});