import {
   SET_WALKS,
   ADD_WALK,
   SET_UPCOMING_WALKS
} from '../actions';

export default ( state = {
   walks: [],
   upcomingWalks: []
}, action) => {

   const {
      walks,
      walk,
      upcomingWalks
   } = action;

   switch (action.type) {

      case SET_UPCOMING_WALKS: {
         return {
            ...state,
            upcomingWalks: upcomingWalks
         }
      }

      case SET_WALKS: {
         return {
            ...state,
            walks: walks
         }
      }

      case ADD_WALK: {
         return {
            ...state,
            walks: [...state.walks, walk]
         }
      }

      default:
            return state;
   }
}