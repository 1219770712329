import { SET_SNACKBAR } from '../actions';

export default ( state = {
   snackbarOpen: false,
   snackbarType: "info",
   snackbarMessage: '',
}, action) => {

   const {
      snackbarOpen,
      snackbarType,
      snackbarMessage
   } = action;

   switch (action.type) {
      case SET_SNACKBAR:
         {
         return {
            ...state,
            snackbarOpen: snackbarOpen,
            snackbarType: snackbarType,
            snackbarMessage: snackbarMessage
         }
      }

      default:
            return state;
   }
}