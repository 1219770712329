import React, { useEffect, useCallback } from 'react';
import { addDogReq, addDogPhoto, getDogById, updateDogReq, baseURL } from '../../../service/index';
import { connect } from 'react-redux';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../cropImage';

// for date picker
import 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import red from '@mui/material/colors/red';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// styles 
import './addDog.scss';

//icons 
import addPhoto from '../../../img/ico/add_photo.svg';
import closeIcon from '../../../img/ico/close.svg';
import calendarPink from '../../../img/ico/calendar_pink.svg';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { closePopup, openPopup } from '../../../actions/popup';
import { addDog, updateDog } from '../../../actions/dogs';
import { setSnackbar } from '../../../actions/snackbar';

const mapDispatchToProps = dispatch => ({
   closePopup: (payload) => {
      return dispatch(closePopup(payload));
   },
   openPopup: (payload) => {
      return dispatch(openPopup(payload));
   },
   addDog: (payload) => {
      return dispatch(addDog(payload));
   },
   updateDog: (payload) => {
      return dispatch(updateDog(payload));
   },
   setSnackbar: (payload) => {
      return dispatch(setSnackbar(payload));
   }
})

const AddDog = (props) => {

   const [isImagePopupOpen, setIsImagePopupOpen] = React.useState(false);
   const [image, setImage] = React.useState();
   const [nameError, setNameError] = React.useState(false);
   const [commentError, setCommentError] = React.useState(false);
   const [photoError, setPhotoError] = React.useState(false);
   const today = new Date();
   const twoMonthAgoDate = today.setMonth(today.getMonth() - 2)

   const [dog, setDog] = React.useState({
      name: "",
      size: "m",
      dateB: twoMonthAgoDate,
      sex: "female",
      comment: ""
   });

   const [crop, setCrop] = React.useState({ x: 0, y: 0 })
   const [zoom, setZoom] = React.useState(1)
   const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null)
   const [croppedImage, setCroppedImage] = React.useState(null)
   const [isUpdate, setIsUpdate] = React.useState(false);
   const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);

   useEffect(() => {
      if (props.info && props.info.dog) {
         // setDog(props.info.dog)

         const updatedDog = {
            ...props.info.dog,
            dateB: new Date(props.info.dog.dateB)
         };
         setDog(updatedDog)
         setCroppedImage(`${baseURL}uploads/dogs/${props.info.dog.imagePath}`);
         setIsUpdate(true)
      }
   }, [])


   
   const onPopupOpen = () => setIsImagePopupOpen(!isImagePopupOpen);
   const handleDateChange = (date) => setDog({ ...dog, dateB: date });
   
   const onImageChange = (e) => {
      setImage(URL.createObjectURL(e.target.files[0]));
      setPhotoError(false)
   }

   const onPhotoDelete = () => {
      setImage()
      setCroppedImage()
   }

   // for cropping image
             
   const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels)
   }, [])

   const showCroppedImage = useCallback(async () => {
      try {
         const croppedImage = await getCroppedImg(
            image,
            croppedAreaPixels
         )
         setCroppedImage(croppedImage)
         setIsImagePopupOpen(!isImagePopupOpen);
      } catch (e) {
         console.error(e)
      }
   }, [croppedAreaPixels])

   // validation

   const validate = () => {
      let nameError = false;
      let commentError = false;
      let photoError = false;

      if (dog.name.length < 1) {
         nameError = true;
      }

      if (dog.comment.length < 1) {
         commentError = true;
      }

      if (!croppedImage) {
         photoError = true;
      }

      if (nameError || commentError || photoError) {
         setNameError(nameError);
         setCommentError(commentError);
         setPhotoError(photoError);
         return false;
      }
      return true;
   }

   // on dog submit

   const onDogSubmit = async () => {
      const isValid = validate();

      if (isFormSubmitted) return;
      if (!isValid) return;

      setIsFormSubmitted(true);
      setNameError(false);
      setCommentError(false);

      let id = dog._id;

      if (isUpdate) {
         await onUpdateDog()
         await addDogsPhoto(id)
         const updatedDog = await getDogById(id);
         props.updateDog({ dog: updatedDog.data.dog });
         props.info.onDogUpdate(updatedDog.data.dog);
         props.setSnackbar({ snackbarOpen: true, snackbarType: "success", snackbarMessage: "Information has been updated !" })
      }
      else {
         let res = await onDogAdd()
         id = res.data.dog._id

         await addDogsPhoto(id)

         const addedDog = await getDogById(res.data.dog._id);
         props.addDog({ dog: addedDog.data.dog });
         props.info.onDogAdd(addedDog.data.dog);

         props.setSnackbar({ snackbarOpen: true, snackbarType: "success", snackbarMessage: "Your dog was added !" })
      }

      props.closePopup();

      if (props.info && props.info.text) {
         props.openPopup({ currentPopup: 'ConfirmChoice', info: { walker: props.info.walker, walk: props.info.walk } })
      }
   }

   const addDogsPhoto = async (id) => {
      const formData = new FormData();
      let blob = await fetch(croppedImage).then(r => r.blob());
      formData.append("file", blob, `${id}.jpg`);
      await addDogPhoto(id, formData)
   }

   const onDogAdd = async () => {
      const res = await addDogReq(dog);
      return res;
   }

   const onUpdateDog = async () => {
      await updateDogReq(dog._id, dog)
   }

   const shouldDisableDate = (date) => {
      return date > twoMonthAgoDate;
    };

   return (
      <section className="addDog__wrapper">
         {dog &&
            <div className="popup__content">
               <div className="addDog__closeBtn">
                  <img src={closeIcon} onClick={props.close} alt="" />
               </div>

               {isImagePopupOpen
                  ?
                  <div className="imageUpLoader__wrapper">
                     <div className="imageUpLoader">

                        <Cropper
                           image={image}
                           crop={crop}
                           zoom={zoom}
                           aspect={4 / 3}
                           onCropChange={setCrop}
                           onCropComplete={onCropComplete}
                           onZoomChange={setZoom}
                        />

                        {image
                           ? null
                           : <div className="imageUpLoader__clickableArea">
                              <input
                                 type="file"
                                 name="file"
                                 onChange={onImageChange}
                                 accept="image/x-png,image/gif,image/jpeg"
                                 className="imageUpLoader__input" />
                              <div className="imageUpLoader__selectBtn">
                                 <div className="imageUpLoader__customBtnWrapper">
                                    <img src={addPhoto} /> <span>upload photo</span>
                                 </div>
                              </div>
                           </div>
                        }

                        <div className="imageUpLoader__deletePhoto" onClick={onPhotoDelete}><span>delete </span><DeleteForeverIcon fontSize="small" /></div>
                     </div>
                     <div className="imageUpLoader__buttons">
                        <div className="imageUpLoader__uploadBtn" onClick={showCroppedImage}>Upload</div>
                        <div className="imageUpLoader__cancelBtn" onClick={onPopupOpen}>Cancel</div>
                     </div>
                  </div>
                  : null
               }
               <div className="addDog__img" onClick={onPopupOpen}>
                  {croppedImage
                     ? <img src={croppedImage} className='addDog__photo' />
                     : <img src={addPhoto} className="addDog__icon" alt="add photo icon" />
                  }
               </div>

               {croppedImage ? <div className="addDog__addAnotherBtn" onClick={onPopupOpen}>Change photo</div> : null}
               {photoError && <div className="textError">Add a photo of your dog</div>}

               <input className={nameError ? "addDog__nameError" : "addDog__name"} type="text" id="dogName" placeholder="Dog's name" value={dog.name} onChange={(e) => setDog({ ...dog, name: e.target.value })} />

               <div className="addDog__label">Weight</div>
               <div className="addDog__weightButtons">

                  <input type='radio' value='s' name='age' id='small' checked={dog.size === 's' ? true : false} onChange={(e) => setDog({ ...dog, size: e.target.value })} />
                  <label for='small' className="addDog__weightBtn addDog__btn">Small</label>

                  <input type='radio' value='m' name='age' id='medium' checked={dog.size === 'm' ? true : false} onChange={(e) => setDog({ ...dog, size: e.target.value })} />
                  <label for='medium' className="addDog__weightBtn addDog__btn">Medium</label>

                  <input type='radio' value='l' name='age' id='large' checked={dog.size === 'l' ? true : false} onChange={(e) => setDog({ ...dog, size: e.target.value })} />
                  <label for='large' className="addDog__weightBtn addDog__btn">Large</label>
               </div>

               <div className="flex">
                  <div className="addDog__info">
                     <div className="addDog__label"> Dog's birthday</div>
                     <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <ThemeProvider theme={defaultMaterialTheme}>
                           <DatePicker
                              inputFormat="dd/MM/yyyy"
                              format="dd/MM/yyyy"
                              value={dog.dateB}
                              className="addDog__btn addDog__ageBtn"
                              slots={{
                                 openPickerIcon: () => <img src={calendarPink} />,
                              }}
                              shouldDisableDate={shouldDisableDate}
                              onChange={(newValue) => {
                                 handleDateChange(newValue);
                              }}
                           />
                        </ThemeProvider>
                     </LocalizationProvider>
                  </div>

                  <div className="addDog__info">
                     <div className="addDog__label">Sex</div>

                     <div className="addDog__sexButtons">
                        <input type='radio' value='female' name='sex' id='female' checked={dog.sex === 'female' ? true : false} onChange={(e) => setDog({ ...dog, sex: e.target.value })} />
                        <label for='female' className="addDog__sexBtn addDog__btn">Female</label>

                        <input type='radio' value='male' name='sex' id='male' checked={dog.sex === 'male' ? true : false} onChange={(e) => setDog({ ...dog, sex: e.target.value })} />
                        <label for='male' className="addDog__sexBtn addDog__btn">Male</label>
                     </div>
                  </div>
               </div>

               <div className="addDog__label">Comment</div>
               <textarea className={commentError ? "addDog__commentError" : "addDog__comment"} value={dog.comment} placeholder="Describe the dog, this will help future walkers to learn more about the dog (E.g.: how it likes to play, etc.)" rows="6" cols="50" id="addDogComment" name="comment" onChange={(e) => setDog({ ...dog, comment: e.target.value })}></textarea>
               <div className={isFormSubmitted ? "addDog__saveBtnDisable" : "addDog__saveBtn"} onClick={() => onDogSubmit()}>{isUpdate ? 'Update' : 'Save'}</div>

            </div>
         }
      </section>
   )
}

export default connect(
   null,
   mapDispatchToProps
)(AddDog);



const defaultMaterialTheme = createTheme({
   palette: {
      primary: {
         main: red.A100,
      }
   }
});