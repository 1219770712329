import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./timeSettings.scss";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { PlainButton } from "../buttons/buttons";
import { setWorkingDaysReq, setSpecificDateReq, getUser, setDogsWorkingDaysReq, setDogsSpecificDateReq } from '../../service';
import { setSnackbar } from '../../actions/snackbar';
import { openPopup } from '../../actions/popup';
import { Ellipsis } from 'react-awesome-spinners'
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// icons
import addDog from "../../img/ico/add_btn.svg";
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';

const mapDispatchToProps = (dispatch) => ({
  setSnackbar: (payload) => {
    return dispatch(setSnackbar(payload));
  },
  openPopup: (payload) => {
    return dispatch(openPopup(payload));
  },
});

const TimeSettings = ({ dogs, getDogPhoto, user, setSnackbar, openPopup, onDogUpdate }) => {
  const [selectedDog, setSelectedDog] = useState();
  const [weekDays, setWeekDays] = useState();
  const [specificDates, setSpecificDates] = useState(user.specificDates);
  const [isLoading, setIsLoading] = useState(false);
  const [isTouchable, setIsTouchable] = useState(false);
  const [specificDate, setSpecificDate] = useState({
    dayOff: false,
    date: dayjs().add(1, 'day'),
    time: {
      from: {
        hour: 8,
        minutes: 0
      },
      to: {
        hour: 22,
        minutes: 0
      }
    },
    breakTime: [],
  });
  const days = [
    { dayNumber: 1, dayName: 'Monday', dayAbbr: 'mo' },
    { dayNumber: 2, dayName: 'Tuesday', dayAbbr: 'tu' },
    { dayNumber: 3, dayName: 'Wednesday', dayAbbr: 'wd' },
    { dayNumber: 4, dayName: 'Thursday', dayAbbr: 'th' },
    { dayNumber: 5, dayName: 'Friday', dayAbbr: 'fr' },
    { dayNumber: 6, dayName: 'Saturday', dayAbbr: 'st' },
    { dayNumber: 7, dayName: 'Sunday', dayAbbr: 'sn' },
  ]
  const onChangeSpecificTime = (currentTime, timeFrom) => {
    let updatedTime;

    if (timeFrom) {
      updatedTime =
      {
        ...specificDate.time,
        from: {
          hour: dayjs(currentTime).get('hour'),
          minutes: dayjs(currentTime).get('minute')
        }
      }
    } else {
      updatedTime =
      {
        ...specificDate.time,
        to: {
          hour: dayjs(currentTime).get('hour'),
          minutes: dayjs(currentTime).get('minute')
        }
      }
    }
    setSpecificDate({ ...specificDate, time: updatedTime })
  }

  useEffect(() => {
    console.log('user', user)
  }, [user]);

  useEffect(() => {
    setIsTouchable(false);
    if (selectedDog) {
      setWeekDays(Object.keys(selectedDog.workingDays).map((key) => selectedDog.workingDays[key]).sort(function (a, b) {  return a.d - b.d;  }))
      setSpecificDates(selectedDog.specificDates)
    } else {
      setWeekDays(Object.keys(user.workingDays).map((key) => user.workingDays[key]).sort(function (a, b) {  return a.d - b.d;  }))
      setSpecificDates(user.specificDates)
    }
  }, [selectedDog])

  const addSpecificBreak = (x) => {
    let breaks = specificDate.breakTime;

    let updatedBreaks = [
      ...breaks,
      {
        from: {
          hour: 13,
          minutes: 0
        },
        to: {
          hour: 14,
          minutes: 0
        }
      },
    ];

    setSpecificDate({ ...specificDate, breakTime: updatedBreaks })
  };

  const onChangeSpecificBreakTime = (breakIndex, currentTime, timeFrom) => {
    let updatedSpecificDate = specificDate;
    let updatedBreaks;

    let breakTime = specificDate.breakTime[breakIndex];
    let breaks = specificDate.breakTime;

    if (timeFrom) {
      updatedBreaks =
      {
        ...breakTime,
        from: {
          hour: dayjs(currentTime).get('hour'),
          minutes: dayjs(currentTime).get('minute')
        }
      }
    } else {
      updatedBreaks =
      {
        ...breakTime,
        to: {
          hour: dayjs(currentTime).get('hour'),
          minutes: dayjs(currentTime).get('minute')
        }
      }
    }

    breaks[breakIndex] = updatedBreaks
    updatedSpecificDate.breakTime = breaks;
    setSpecificDate({ ...updatedSpecificDate })
  }

  const deleteSpecificBreak = (breakIndex) => {
    let breaks = specificDate.breakTime;
    breaks.splice(breakIndex, 1);
    let updatedSpecificDate = specificDate;
    updatedSpecificDate.breakTime = breaks;
    setSpecificDate({ ...updatedSpecificDate });
  }




  const onChangeTime = (x, currentTime, timeFrom) => {
    setIsTouchable(true);
    let time = x.time;
    let updatedTime;

    if (timeFrom) {
      updatedTime =
      {
        ...time,
        from: {
          hour: dayjs(currentTime).get('hour'),
          minutes: dayjs(currentTime).get('minute')
        }
      }
    } else {
      updatedTime =
      {
        ...time,
        to: {
          hour: dayjs(currentTime).get('hour'),
          minutes: dayjs(currentTime).get('minute')
        }
      }
    }

    let index = weekDays.findIndex((obj) => obj.d == x.d);
    let updatedWeek = weekDays;
    updatedWeek[index].time = updatedTime;
    setWeekDays([...updatedWeek]);
  }

  const addBreak = (x) => {
    setIsTouchable(true);
    let breaks = x.breakTime;
    let updatedBreaks = [
      ...breaks,
      {
        from: {
          hour: 13,
          minutes: 0
        },
        to: {
          hour: 14,
          minutes: 0
        }
      },
    ];

    let index = weekDays.findIndex((obj) => obj.d == x.d);
    let updatedWeek = weekDays;
    updatedWeek[index].breakTime = updatedBreaks;
    setWeekDays([...updatedWeek]);
  };


  const deleteBreak = (day, breakIndex) => {
    setIsTouchable(true);
    let dayIndex = weekDays.findIndex((obj) => obj.d == day.d);
    let breaks = weekDays[dayIndex].breakTime;
    breaks.splice(breakIndex, 1);
    let updatedWeek = weekDays;
    updatedWeek[dayIndex].breakTime = breaks;
    setWeekDays([...updatedWeek]);
  }

  const onChangeBreakTime = (day, breakIndex, currentTime, timeFrom) => {
    setIsTouchable(true);
    let dayIndex = weekDays.findIndex((obj) => obj.d == day.d);
    let updatedWeek = weekDays;
    let updatedBreaks;
    let breakTime = weekDays[dayIndex].breakTime[breakIndex];
    let breaks = weekDays[dayIndex].breakTime;

    if (timeFrom) {
      updatedBreaks =
      {
        ...breakTime,
        from: {
          hour: dayjs(currentTime).get('hour'),
          minutes: dayjs(currentTime).get('minute')
        }
      }
    } else {
      updatedBreaks =
      {
        ...breakTime,
        to: {
          hour: dayjs(currentTime).get('hour'),
          minutes: dayjs(currentTime).get('minute')
        }
      }
    }

    breaks[breakIndex] = updatedBreaks
    updatedWeek[dayIndex].breakTime = breaks;
    setWeekDays([...updatedWeek]);
  }

  const setIsDayOff = (x) => {
    setIsTouchable(true);
    let index = weekDays.findIndex((obj) => obj.d == x.d);
    let updatedWeek = weekDays;
    updatedWeek[index].disabled = !updatedWeek[index].disabled;
    setWeekDays([...updatedWeek]);
  };

  const onSaveGeneralTime = async () => {
    setIsTouchable(false);
    let formattedDays = {};
    weekDays.forEach(x => {
      let propName = days.find(y => y.dayNumber == x.d).dayAbbr;
      formattedDays[propName] = x;
    });

    if(selectedDog) {
      try {
        const res = await setDogsWorkingDaysReq( {dogIds: [selectedDog._id], workingDays: { ...formattedDays }})
        setSnackbar({ snackbarOpen: true, snackbarType: "success", snackbarMessage: "Saved" })
        setSelectedDog({...selectedDog,  workingDays: { ...formattedDays }})
        onDogUpdate({...selectedDog,  workingDays: { ...formattedDays }})
      } catch (e) {
        console.log('e', e)
        setSnackbar({ snackbarOpen: true, snackbarType: "error", snackbarMessage: e.response.data.message })
      }
    } else {
      try {
        const res = await setWorkingDaysReq({ workingDays: { ...formattedDays } })
        setSnackbar({ snackbarOpen: true, snackbarType: "success", snackbarMessage: "Saved" })
      } catch (e) {
        console.log('e', e)
        setSnackbar({ snackbarOpen: true, snackbarType: "error", snackbarMessage: e.response.data.message })
      }
    }
  }

  const onSaveSpecificDate = async () => {
    if(selectedDog) {
      try {
        const res = await setDogsSpecificDateReq( {dogIds: [selectedDog._id], specificDates: [...specificDates, { ...specificDate }]})
        console.log('res', res)
        setSnackbar({ snackbarOpen: true, snackbarType: "success", snackbarMessage: "Saved" })
        setSpecificDates([...specificDates, { ...specificDate }])
        onDogUpdate({...selectedDog,  specificDates: [...specificDates, { ...specificDate }]})

      } catch (e) {
        console.log('e', e)
        setSnackbar({ snackbarOpen: true, snackbarType: "error", snackbarMessage: e.response.data.message })
      }
    } else {
      try {
        const res = await setSpecificDateReq({ specificDates: [...specificDates, { ...specificDate }] })
        console.log('res', res)
        setSnackbar({ snackbarOpen: true, snackbarType: "success", snackbarMessage: "Saved" })
        setSpecificDates([...specificDates, { ...specificDate }])
      } catch (e) {
        console.log('e', e)
        setSnackbar({ snackbarOpen: true, snackbarType: "error", snackbarMessage: e.response.data.message })
      }
    }
  }

  const onDeleteSpecificDate = async (date) => {
    const updatedDates = specificDates.filter((t) => t !== date);
    if(selectedDog) {
      try {
        const res = await setDogsSpecificDateReq( {dogIds: [selectedDog._id], specificDates: updatedDates})
        console.log('res', res)
        setSpecificDates(updatedDates);
        setSnackbar({ snackbarOpen: true, snackbarType: "success", snackbarMessage: "Deleted" })
        onDogUpdate({...selectedDog,  specificDates: updatedDates})
       } catch (e) {
         console.log('e', e)
         setSnackbar({ snackbarOpen: true, snackbarType: "error", snackbarMessage: e.response.data.message })
       }
 } else {

   try {
     const res = await setSpecificDateReq({ specificDates: updatedDates })
     console.log('res', res)
     setSpecificDates(updatedDates);
     setSnackbar({ snackbarOpen: true, snackbarType: "success", snackbarMessage: "Deleted" })
    } catch (e) {
      console.log('e', e)
      setSnackbar({ snackbarOpen: true, snackbarType: "error", snackbarMessage: e.response.data.message })
    }
  }
}

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="manageTimeSection">
        <div className="manageTimeSection__title">Manage Time</div>

        <div className="manageTimeSection__dogs">
          <div
            className={
              !selectedDog
                ? "manageTimeSection__dog--active manageTimeSection__dog manageTimeSection__dog--allDogs"
                : "manageTimeSection__dog manageTimeSection__dog--allDogs"
            }
            onClick={() => setSelectedDog()}
          >
            <div>{/* <img src={DogTime} alt="General time" /> */}</div>
            <div className="manageTimeSection__allDogsTitle">General Time</div>
          </div>

          <div className="manageTimeSection__dogs--scrollable">
            {dogs && dogs.map((x) => {
              return (
                <div
                  className={
                    selectedDog && selectedDog._id === x._id
                      ? "manageTimeSection__dog--active manageTimeSection__dog"
                      : "manageTimeSection__dog"
                  }
                  onClick={() => isTouchable ? 
                    openPopup({
                      currentPopup: "Confirmation",
                      info: {
                        onConfirm: () => setSelectedDog(x),
                        title: "",
                        confirmationButtonText: 'Move to another settings',
                        text: `You have unsaved time settings for ${selectedDog ? "dog " + selectedDog.name : "shelter"}`
                      },
                    }) 
                    : setSelectedDog(x)

                  }
                >
                  <img src={getDogPhoto(x.imagePath)} alt="dogs photo" />
                  <div className="manageTimeSection__dogName">{x.name}</div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="manageTimeSection__scheduleWrapper">
          <div className="manageTimeSection__schedule">
            <div className="manageTimeSection__scheduleTitle">
              {selectedDog ? `Schedule for ${selectedDog.name}` : "General Schedule"}
            </div>

            {weekDays && weekDays.map((x, index) => {
              return (
                <div className="manageTimeSection__day">
                  <div className="manageTimeSection__dayNameWrapper">
                    <div className={index === 5 || index === 6 ? "manageTimeSection__dayName--weekend" : "manageTimeSection__dayName"}>{days.find(y => y.dayNumber === x.d).dayName}</div>
                    <FormControlLabel
                      label="Mark as a day off"
                      control={<Checkbox checked={x.disabled} onChange={() => setIsDayOff(x)} />}
                    />
                  </div>

                  <div className="manageTimeSection__timeWrapper">
                    <div className={x.disabled ? "manageTimeSection__time--disabled" : "manageTimeSection__time"}>
                      <div style={{ marginBottom: "20px" }}>
                        <TimePicker
                          label="From"
                          value={dayjs().set("hour", x.time.from.hour).set("minute", x.time.from.minutes)}
                          onChange={(time) => onChangeTime(x, time, true)}
                          minutesStep={5}
                          ampm={false}
                          skipDisabled={true}
                          minTime={dayjs().set("hour", 6)}
                          maxTime={dayjs().set("hour", 23)}
                          className="manageTimeSection__timeInput"
                          sx={{
                            width: 115,
                          }}
                        />

                        <TimePicker
                          label="To"
                          value={dayjs().set("hour", x.time.to.hour).set("minute", x.time.to.minutes)}
                          onChange={(time) => onChangeTime(x, time, false)}
                          minutesStep={5}
                          ampm={false}
                          skipDisabled={true}
                          minTime={dayjs().set("hour", 6)}
                          maxTime={dayjs().set("hour", 23)}
                          className="manageTimeSection__timeInput"
                          sx={{
                            width: 115,
                          }}
                        />
                      </div>

                      <div className="manageTimeSection__addBreakSection">
                        <div className="manageTimeSection__breaksWrapper">
                          {x.breakTime &&
                            x.breakTime.map((y, i) => {
                              return (
                                <div className="manageTimeSection__breakWrapper">
                                  <div className="manageTimeSection__breakDeleteBtn" onClick={() => deleteBreak(x, i)}>
                                    <HighlightOffRoundedIcon className="manageTimeSection__breakDeleteIcon" />
                                  </div>
                                  <TimePicker
                                    label="Break from"
                                    value={dayjs().set("hour", y.from.hour).set("minute", y.from.minutes)}
                                    onChange={(time) => onChangeBreakTime(x, i, time, true)}
                                    minutesStep={5}
                                    ampm={false}
                                    skipDisabled={true}
                                    minTime={dayjs().set("hour", 6)}
                                    maxTime={dayjs().set("hour", 23)}
                                    className="manageTimeSection__timeInput"
                                    sx={{
                                      width: 115,
                                    }}
                                  />
                                  <div className="divider">-</div>

                                  <TimePicker
                                    label="Break to"
                                    value={dayjs().set("hour", y.to.hour).set("minute", y.to.minutes)}
                                    onChange={(time) => onChangeBreakTime(x, i, time, false)}
                                    minutesStep={5}
                                    ampm={false}
                                    skipDisabled={true}
                                    minTime={dayjs().set("hour", 6)}
                                    maxTime={dayjs().set("hour", 23)}
                                    className="manageTimeSection__timeInput"
                                    sx={{
                                      width: 115,
                                    }}
                                  />
                                </div>
                              );
                            })}
                        </div>

                        <div className="manageTimeSection__addBreakBtn" onClick={() => addBreak(x)}>
                          <img src={addDog} alt="add break" />
                          <div> Add break</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="manageTimeSection__saveBtn">
              <PlainButton onClick={() => onSaveGeneralTime()} text="Save" />
            </div>
          </div>
          <div className="manageTimeSection__specificSettings">
            <div className="manageTimeSection__scheduleTitle">
              {selectedDog ? `Specific time for ${selectedDog.name}` : "Specific time"}
            </div>

            <div className="manageTimeSection__scheduleDay">
              <DatePicker
                label="Select date"
                value={specificDate.date}
                format="DD/MM/YYYY"
                onChange={(newValue) => setSpecificDate({ ...specificDate, date: newValue })}
                disablePast={true}
              />

              <FormControlLabel label="Mark as a day off" control={<Checkbox checked={specificDate.dayOff} onChange={() => setSpecificDate({ ...specificDate, dayOff: !specificDate.dayOff })} />} />
            </div>

            <div
              className={specificDate.dayOff ? "manageTimeSection__time--disabled" : "manageTimeSection__time"}
              style={{ marginTop: 30 }}
            >
              <div style={{ marginBottom: "20px" }}>
                <TimePicker
                  label="From"
                  value={dayjs().set("hour", specificDate.time.from.hour).set("minute", specificDate.time.from.minutes)}
                  onChange={(time) => onChangeSpecificTime(time, true)}
                  minutesStep={5}
                  ampm={false}
                  skipDisabled={true}
                  minTime={dayjs().set("hour", 6)}
                  maxTime={dayjs().set("hour", 23)}
                  className="manageTimeSection__timeInput"
                  sx={{
                    width: 100,
                  }}
                />

                <TimePicker
                  label="To"
                  value={dayjs().set("hour", specificDate.time.to.hour).set("minute", specificDate.time.to.minutes)}
                  onChange={(time) => onChangeSpecificTime(time, false)}
                  minutesStep={5}
                  ampm={false}
                  skipDisabled={true}
                  minTime={dayjs().set("hour", 6)}
                  maxTime={dayjs().set("hour", 23)}
                  className="manageTimeSection__timeInput"
                  sx={{
                    width: 115,
                  }}
                />
              </div>
              <div className="manageTimeSection__addBreakSection">
                <div className="manageTimeSection__breaksWrapper">

                  {specificDate.breakTime &&
                    specificDate.breakTime.map((y, i) => {
                      return (

                        <div className="manageTimeSection__breakWrapper">
                          <div className="manageTimeSection__breakDeleteBtn" onClick={() => deleteSpecificBreak(i)}>
                            <HighlightOffRoundedIcon className="manageTimeSection__breakDeleteIcon" />
                          </div>
                          <TimePicker
                            label="Break from"
                            value={dayjs().set("hour", y.from.hour).set("minute", y.from.minutes)}
                            onChange={(time) => onChangeSpecificBreakTime(i, time, true)}
                            minutesStep={5}
                            ampm={false}
                            skipDisabled={true}
                            minTime={dayjs().set("hour", 6)}
                            maxTime={dayjs().set("hour", 23)}
                            className="manageTimeSection__timeInput"
                            sx={{
                              width: 115,
                            }}
                          />
                          <div className="divider">-</div>

                          <TimePicker
                            label="Break to"
                            value={dayjs().set("hour", y.to.hour).set("minute", y.to.minutes)}
                            onChange={(time) => onChangeSpecificBreakTime(i, time, false)}
                            minutesStep={5}
                            ampm={false}
                            skipDisabled={true}
                            minTime={dayjs().set("hour", 6)}
                            maxTime={dayjs().set("hour", 23)}
                            className="manageTimeSection__timeInput"
                            sx={{
                              width: 115,
                            }}
                          />
                        </div>
                      )
                    })}
                </div>

                <div className="manageTimeSection__addBreakBtn" onClick={() => addSpecificBreak()}>
                  <img src={addDog} alt="add break" />
                  <div> Add break</div>
                </div>
              </div>
            </div>

            <div className="manageTimeSection__saveBtn">
              <PlainButton onClick={() => onSaveSpecificDate()} text="Add" />
            </div>



            <div className="manageTimeSection__specificDatesWrapper">
              <div className="flexBetween">
                <div className="manageTimeSection__scheduleTitle">
                  {selectedDog ? `Specific dates for ${selectedDog.name}` : "Specific dates"}
                </div>
                {isLoading &&
                  <div className='manageTimeSection__loader fadeIn'>
                    <Ellipsis color="#ff9085" />
                  </div>
                }
              </div>

              {specificDates && specificDates.map((x) => (
                <div className="manageTimeSection__specificDate">
                  <div className="flexBetween">
                    <div className="flexColumn">
                      <div className="userProfile__walkLabel">Date</div>
                      <div className="userProfile__walkText">{dayjs(x.date).format('DD/MM/YYYY')}</div>
                    </div>

                    {x.dayOff ? <div className="manageTimeSection__dayOffBadge">DAY OFF</div>
                      :
                      <div className="flexColumn">
                        <div className="userProfile__walkLabel">Time</div>
                        <div className="userProfile__walkText">{`${x.time.from.hour}:${x.time.from.minutes} - ${x.time.to.hour}:${x.time.to.minutes}`}</div>
                      </div>
                    }
                  </div>

                  {x.breakTime.length > 0 &&
                    <div className="flexColumn">
                      <div className="userProfile__walkLabel">{x.breakTime.length > 0 ? 'Breaks' : 'Break'}</div>
                      <div className="flexColumn">
                        {x.breakTime.map(y => (
                          <div className="userProfile__walkText">{`${y.from.hour}:${y.from.minutes} - ${y.to.hour}:${y.to.minutes}`}</div>
                        ))}
                      </div>
                    </div>
                  }
                  <div className='manageTimeSection__specificDateDeleteBtn'
                    onClick={() =>
                      openPopup({
                        currentPopup: "Confirmation",
                        info: {
                          onConfirm: () => onDeleteSpecificDate(x),
                          title: "",
                          type: 'remove',
                          text: `remove settings for this date`,
                        },
                      })
                    }>
                    <DeleteOutlineRoundedIcon />
                  </div>
                </div>
              ))}

            </div>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default connect(null, mapDispatchToProps)(TimeSettings);