import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getDogById, googleMapsApiKey, baseURL } from '../../service/index';
import { useParams } from 'react-router-dom'
import MapWithMarker from '../mapWithMarker/mapWithMarker';
import moment from 'moment';
import TimelineWrapper from '../timelineWrapper/timelineWrapper';
import defaultDogAvatar from '../../img/ico/userColored.svg';
import TimeSelection from '../timeSelection/timeSelection';
import red from '@mui/material/colors/red';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import './dogProfile.scss'
import { TransparentBlueBtn } from '../buttons/buttons';
import { Link } from "react-router-dom";

// icons 
import calendarPink from '../../img/ico/calendar_pink.svg';
import pawIcon from '../../img/ico/catsPaw.svg';
import dashedLine from '../../img/ico/dashedLine.svg';

import {
   openPopup
} from '../../actions/popup';


const mapStateToProps = state => ({
   ...state.currentUser,
   ...state.popups,
   ...state.selectedDates,
   ...state.selectedPrices,
   ...state.walkers
});

const mapDispatchToProps = dispatch => ({
   openPopup: (payload) => {
      return dispatch(openPopup(payload));
   }
})

const WalkerProfile = (props) => {

   const { id } = useParams();
   // const [selectedDate, setSelectedDate] =  React.useState(new Date());
   // const [selectedDate, setSelectedDate] = React.useState(dayjs(new Date()));
   const [selectedDate, setSelectedDate] =  React.useState(new Date());
   // const handleDateChange = (date) => setSelectedDate(date);
   const [timelines, setTimelines] = React.useState([]);
   const [isLoggedIn, setIsLoggedIn] = React.useState();
   const [dog, setDog] = React.useState();
   const [totalPrice, setTotalPrice] = React.useState();
   const [windowWidth, setWindowWidth] = React.useState(
      window.innerWidth
    );

   useEffect(() => {
      const loadWalker = async () => {
         const resp = await getDogById(id)
         setDog(resp.data.dog);
      }
      loadWalker()

   }, []);

   useEffect(() => {
      const handleWindowResize = () => {
         setWindowWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleWindowResize);
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);

   useEffect(() => {

      if(dog == null || !dog.workHours.length) {
         return;
      }
      setTimelines([]);

      let dates = [];
      for (let i = 0; i < 4; i++) {
         let nextDay = new Date(selectedDate);
         nextDay.setDate(selectedDate.getDate() + i);

         var workHours = getWorkHours(nextDay);
         const disabledStartEnd = [{from: {hour: 8, minutes: 0}, to: {hour: workHours.from.hour, minutes: workHours.from.minutes}}, 
             {from: {hour: workHours.to.hour, minutes: workHours.to.minutes}, to: {hour: 22, minutes: 0}}]

         const disabledHours = [ ...disabledStartEnd, ...getBookedWorkHours(nextDay), ...getBreaks(nextDay)  ];
         var timeline = {date: nextDay, isAvailable : isAvailableDate(disabledHours), disabledHours, isActive: false };
         dates.push(timeline);
      }

      let indexOfFirstAvailable = dates.findIndex(x=> x.isAvailable);
      if(indexOfFirstAvailable != -1){
         dates[indexOfFirstAvailable].isActive = true;
      } 
      setTimelines(dates);
   }, [dog, selectedDate]);


   useEffect(() => {
      let total = 0;
      props.selectedPrices.map(x => total += x[1])
      setTotalPrice(parseFloat(total.toFixed(1)));

   }, [props.selectedPrices]);

   useEffect(() => {
      const currentUser = Object.keys(props.user).length;
      if (currentUser === 0) {
         setIsLoggedIn(false);

      } else {
         setIsLoggedIn(true);
      }
   }, [props.user]);



    const isAvailableDate = (disabledHours) => {
      //set available hours
      const sortedDisabledHours = disabledHours.sort((a, b) => a.from.hour - b.from.hour);
      
      var isEnoughTime = false;
      for (let i = 0; i < sortedDisabledHours.length - 1; i++) { 
         const durationInMinutes = '45';
         
         const endTimeOfDisabledInterval = moment({ hour: sortedDisabledHours[i].to.hour, minute: sortedDisabledHours[i].to.minutes });
         const startTimeOfNextDisabledInterval = moment({ hour: sortedDisabledHours[i+1].from.hour, minute: sortedDisabledHours[i+1].from.minutes });
         
         const possibleEndOfWalkingTime = moment(endTimeOfDisabledInterval, 'HH:mm').add(durationInMinutes, 'minutes');
         isEnoughTime = possibleEndOfWalkingTime.isSameOrBefore(startTimeOfNextDisabledInterval);
      }

      return isEnoughTime;
   }

   const isSpecialHours = (date) => {
      if(dog.workHours){
         const specialWorkHours = dog.workHours.map(x => (moment(x.date).format('DD-MM-YYYY')))
         const isSpecialHours = specialWorkHours.find(x => x === date);
         return isSpecialHours;
      } else {
         return false
      }
   }


   const getWorkHours = (date) => {
      const currentDate = moment(date).format('DD-MM-YYYY');

      if (isSpecialHours(currentDate)) {
         return dog.workHours.filter(x => moment(x.date).format('DD-MM-YYYY') === currentDate)[0].time;
      } else {
         return dog.usualWorkhours.time;
      }
   }

   const getBreaks = (date) => {
      const currentDate = moment(date).format('DD-MM-YYYY');
      
      if (isSpecialHours(currentDate)) {
         return dog.workHours.filter(x => moment(x.date).format('DD-MM-YYYY') === currentDate)[0].breakTime;
      } else {
         return dog.usualWorkhours.breakTime;
      }
   }
   
   const getBookedWorkHours = (date) => {
      const arr = [];
      const currentDate = moment(date).format('DD-MM-YYYY');
      const bookedWorkHours = dog.bookedWorkHours.map(x => (moment(x.date).format('DD-MM-YYYY')))
      const isBookedWorkHours = bookedWorkHours.find(x => x === currentDate);

      if (isBookedWorkHours) {
         return dog.bookedWorkHours.filter(x => moment(x.date).format('DD-MM-YYYY') === currentDate)[0].time;
      } else {
         return arr;
      }
   }

   const calculateDogsAge = (dogDateB) => {
      const currentDate = new Date(dogDateB);
      const today = new Date();
      const diffInMonths = (today.getFullYear() - currentDate.getFullYear()) * 12 +
         (today.getMonth() - currentDate.getMonth());
      const years = Math.floor(diffInMonths / 12);
      const remainingMonths = Math.floor(diffInMonths % 12);
      const showRemainingMonths = remainingMonths >= 6;

      let timeInfo;

      if (years > 0 && showRemainingMonths) {
         timeInfo = `${years}.5 ${years === 1 ? 'year' : 'years'}`;
      } else if (years > 0) {
         timeInfo = `${years} ${years === 1 ? 'year' : 'years'}`;
      } else {
         timeInfo = `${remainingMonths} ${remainingMonths === 1 ? 'month' : 'months'}`;
      }
      return timeInfo;
   }

   return (
      <div>
         {dog ?
            <section className="profile__wrapper">
               <div className="profile">
                  <div className="profile__imgWrapper">
                     {/* <img src={dog.imagePath} alt="" /> */}
                     <img src={dog.imagePath? `${baseURL}uploads/dogs/${dog.imagePath}` : defaultDogAvatar} alt="dogs avatar"/>
                  </div>
                  <div className="profile__infoWrapper">
                     <div className="profile__info">
                        <div className="profile__imgInfoWrapper">
                           <div className="profile__imgTabletWrapper">
                              {/* <img src={dog.imagePath} alt="" /> */}
                           <img src={dog.imagePath? `${baseURL}uploads/dogs/${dog.imagePath}` : defaultDogAvatar} alt="dogs avatar"/>
                           </div>
                           <div className="profile__mainInfo">

                              <div className="profile__mainInfoWrapper">
                                 <div className="profile__infoText">Age</div>
                                 <div className="profile__infoValue">{calculateDogsAge(dog.dateB)}</div>
                              </div>

                              <div className="profile__infoLine"></div>

                              <div className="profile__mainInfoWrapper">
                                 <div className="profile__infoText">Size</div>
                                 <div className="profile__infoValue">{dog.size === "s" ? "Small" : dog.size === "m" ? "Medium" : dog.size === 'l' ? "Large" : dog.size}</div>
                              </div>

                              <div className="profile__infoLine"></div>

                               <div className="profile__mainInfoWrapper">
                                 <div className="profile__infoText">Sex</div>
                                 <div className="profile__infoValue">{dog.sex ?? "male"}</div>
                              </div>
                           </div>
                        </div>

                        <div className="profile__aboutWrapper">
                           <div className="profile__name">{dog.name}</div>

                             {dog.breed &&
                              <>
                                 <div className="profile__aboutLabel">Breed</div>
                                 <div className="profile__about">{dog.breed}</div>
                              </>
                           }
                           {dog.comment &&
                              <>
                                 <div className="profile__aboutLabel">About Me</div>
                                 <div className="profile__about">{dog.comment}</div>
                              </>
                           }
                        </div>
                     </div>

                     <div className="profile__shelter">
                        <img className='profile__dashedLine' src={dashedLine} />
                        <div className="profile__location">
                           <div className="profile__aboutLabel">Shelter</div>

                           <div className='profile__shelterInfo'>
                              <div className="profile__shelterImageWrapper">
                                 <img src={dog.shelter.image} />
                              </div>
                              <div>
                                 <div className='profile__shelterName'>{dog.shelter.name}</div>
                                 <div className="profile__locationInfo">
                                    <img src={pawIcon} />
                                    <div>{dog.shelter.address}, {dog.shelter.city}, {dog.shelter.country}</div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className='profile__mapWrapper'>
                           <MapWithMarker
                              googleMapURL={'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=' + googleMapsApiKey}
                              loadingElement={<div style={{ height: `100%` }} />}
                              containerElement={<div style={{height: `300px`}} />}
                              mapElement={<div style={{height: `100%`}} />}
                              coords={{lat: dog.shelter.location.coordinates[0], lng: dog.shelter.location.coordinates[1]}}
                              />
                           </div>
                     </div>
                  </div>

                  <div className="profile__availability">
                     {!dog.workHours.length && <div className='profile__availabilityInfoText'>
                        A schedule has not been set for this dog yet.
                        <Link
                           to={{ pathname: '/search' }}
                        >
                           <TransparentBlueBtn text="Go to search page" />
                        </Link>
                     </div>}
                  <div className={!dog.workHours.length && "profile__availability--disabled"}>
                     <div className="profile__datePickerWrapper">
                     <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <ThemeProvider theme={defaultMaterialTheme}>
                              <DatePicker
                                 className="profile__datePicker"
                                 inputFormat="dd.MM.yyyy"
                                 disablePast={true}
                                 format="dd/MM/yyyy"
                                 value={selectedDate}
                                 slots={{
                                    openPickerIcon: () =><img src={calendarPink} className="searchBox__datePickerIcon" />,
                                  }}
                                  onChange={(newValue) => {
                                    setSelectedDate(newValue);
                                 }}
                              />
                              </ThemeProvider>
                        </LocalizationProvider>
                     </div>

                     <div className="profile__confirmation">
                        <div className="profile__confirmationGroup">
                           <div className="profile__confirmationLabel">Date</div>

                           {props.selectedDates.map((x, i) => {
                              return (
                                 <div key={i} className="profile__confirmationValue"><span>{moment(x[0]).format('DD MMM')}</span></div>
                              )
                           })}

                        </div>
                        <div className="profile__confirmationGroup profile__confirmationGroupTime">
                           <div className="profile__confirmationLabel">Time interval</div>
                           {props.selectedDates.map((x, i) => {
                              return (
                                 <div key={i}>
                                    <span className="profile__confirmationValue">{x[0].toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
                                    <span> - </span>
                                    <span className="profile__confirmationValue">{x[1].toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
                                 </div>
                              )
                           })}
                        </div>
                     </div>

                     <div className="profile__timelineWrapper">

                        {windowWidth > 600 &&
                           <div className='profile__desktopTimelines'>
                              <div className="profile__timeLineLabel">Select interval inside the timeline</div>

                              {timelines.map((timeline, i) => {
                                 return (
                                    <div className="profile__timelineInnerWrapper" key={i}>
                                       <div className="profile__timelineDate">{moment(timeline.date).format('DD  MMM')}</div>
                                       <TimelineWrapper
                                          selectedDate={timeline.date}
                                          disabledHours={timeline.disabledHours}
                                          isAvailable={timeline.isAvailable}
                                          isActive={timeline.isActive}
                                          rate={dog.price} />
                                    </div>
                                 )
                              })}
                           </div>
                        }

                        <div className='profile__mobileTimeSelection'>
                           {timelines.length > 0 &&
                              <TimeSelection dateInfo={timelines[0]} />
                           }
                        </div>


                        {isLoggedIn
                           ? <div className={props.selectedDates.length > 0 ? "profile__btnConfirm" : "profile__btnConfirmDisable"}
                              onClick={() => props.openPopup({ currentPopup: 'ConfirmChoice', info: { dog: dog } })}>Confirm</div>
                           : <div className="profile__btnConfirm" onClick={() => props.openPopup({ currentPopup: 'LogIn' })}>Log In to Confirm</div>}
                     </div>
                  </div>
               </div>
               </div>

            </section>

            : null}

         {/* <div className="mapWrapper" >
            <MapWrapper coordinates={coordinates}/>
         </div> */}
      </div>
   )
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(WalkerProfile)



// for picker
const defaultMaterialTheme = createTheme({
   palette: {
      primary: {
         main: red.A100,
      }
   }
});