// Popup
export const OPEN_POPUP = "OPEN_POPUP";
export const CLOSE_POPUP = "CLOSE_POPUP";

// Current User
export const CURRENT_USER = "CURRENT_USER";

// Selected Dates
export const ADD_DATE = "ADD_DATE";
export const DELETE_DATE = "DELETE_DATE";
export const DELETE_ALL_DATES = "DELETE_ALL_DATES";
export const ADD_PRICE = "ADD_PRICE";

// Walkers
export const SET_WALKERS = "SET_WALKERS";

// Walks
export const SET_WALKS = "SET_WALK";
export const ADD_WALK = "ADD_WALK";
export const SET_UPCOMING_WALKS = "SET_UPCOMING_WALKS";

// Dogs
export const SET_DOGS = "SET_DOGS";
export const SET_SEARCH_DOGS = "SET_SEARCH_DOGS";
export const ADD_DOG = "ADD_DOG";
export const UPDATE_DOG = "UPDATE_DOG";
export const DELETE_DOG = "DELETE_DOG";

// Snackbar
export const SET_SNACKBAR = "SET_SNACKBAR";

// MAP
export const MAP = {
    WALK_STARTED: 'WALK_STARTED',
    WALK_FINISHED: 'WALK_FINISHED',
    NEW_COORDINATES: 'NEW_COORDINATES',
    LOADED_COORDINATES: 'LOADED_COORDINATES'
};