import {
   ADD_DATE,
   DELETE_DATE,
   DELETE_ALL_DATES,
   ADD_PRICE
} from '../actions';

export default ( state = {
   selectedDates: [],
   selectedPrices: []
}, action) => {

   const {
      date,
      price
   } = action;

   switch (action.type) {
      case ADD_DATE: {

         let item = []; 
         if (state.selectedDates.length != 0) {
             item = state.selectedDates.find(x => x[0].getDate() === date[0].getDate());
         }
         return {
            ...state,
            selectedDates: [...state.selectedDates.filter(x => x != item), date]
         }
      }

      case DELETE_DATE: {

         let deletedDate = state.selectedDates.find(x => x[0].getDate() === date.getDate());
         let deletedPrice = state.selectedPrices.find(x => x[0].getDate() === date.getDate());
         return {
            ...state,
            selectedDates: [...state.selectedDates.filter(x => x !== deletedDate)],
            selectedPrices: [...state.selectedPrices.filter(x => x !== deletedPrice)]
         }
      }

      case DELETE_ALL_DATES: {
         return {
            ...state,
            selectedDates: [],
            selectedPrices: []
         }
      }

      case ADD_PRICE: {

         let item = []; 
         if (state.selectedPrices.length != 0) {
             item = state.selectedPrices.find(x => x[0].getDate() === date.getDate());
         }

         return {
            ...state,
            selectedPrices: [...state.selectedPrices.filter(x => x != item), [date, price]]
         }
      }

      default:
            return state;
   }
}