import { MAP } from './../actions';

const {
    WALK_STARTED,
    WALK_FINISHED,
    NEW_COORDINATES,
    LOADED_COORDINATES
} = MAP;

const defaultState = {
    currentWalks: []
}

// {
//     coordinates,
//     walkerId,
//     walkId,
//     loaded
// }


export default ( state = defaultState, action ) => {
    const {
        type,
        walkId,
        walkerId,
        coordinates,
        currentWalks
    } = action;

    switch(type) {
        case WALK_STARTED: {
            const { currentWalks } = state;

            const payload = {
                walkId,
                walkerId,
                coordinates: []
            }

            currentWalks.push(payload);

            return {
                ...state,
                currentWalks
            }
        } 

        case WALK_FINISHED: {}
        case NEW_COORDINATES: {
            return {
                ...state,
                currentWalks
            }
        }

        case LOADED_COORDINATES: {
            return {
                ...state,
                currentWalks
            }
        }

        default:
            return state;
    }
}

 