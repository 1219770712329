import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';

import { setSnackbar } from '../../actions/snackbar';

const mapStateToProps = state => ({
  ...state.snackbar,
});

const mapDispatchToProps = dispatch => ({
  setSnackbar: (payload) => {
    return dispatch(setSnackbar(payload));
  }
})

const CustomizedSnackbar = (props) => {

  const useStyles = makeStyles((theme) => ({

    myAlert: {
      backgroundColor: "#ff9085",
      color: "#fff",
      "& .MuiAlertTitle-root": {
        color: "#555",
      },
      "& .MuiAlert-message": {
        fontWeight: 500,
      },
      "& .MuiAlert-icon": {
        color: '#fff'
      },
    }

  }));

  const snackbarOpen = props.snackbarOpen;
  const snackbarType = props.snackbarType;
  const snackbarMessage = props.snackbarMessage;
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    props.setSnackbar({snackbarOpen: false, snackbarType: snackbarType, snackbarMessage: snackbarMessage})

  };


  return (
    <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ horizontal: "right", vertical: "top" }}>
      <Alert className={snackbarType === 'success' && classes.myAlert} elevation={0} severity={snackbarType}  onClose={handleClose}>{snackbarMessage} </Alert>
    </Snackbar>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomizedSnackbar);
